import { useQuery } from '@tanstack/react-query'
import * as api from '@parafin/medici-api'
import { get } from '@parafin/utils'
import { useAuth } from '@/src/providers/auth'
import { paragon } from '@useparagon/connect'
import { BASE_URL, PARAGON_PROJECT_ID } from '@/parafin.config'
import { CubejsApi, Query } from '@cubejs-client/core'
import { useCore } from '@/src/providers/core'
import { useQueryOptions, getQueryOptions, useHeaders } from './queryOptions'

export const useGetPartners = (sandboxMode: boolean, partnerId?: string) => {
  const { authHeader } = useAuth()
  const query = api.useGetPartners(
    {},
    getQueryOptions(
      authHeader,
      sandboxMode,
      [...api.getGetPartnersQueryKey(), sandboxMode ? 'sandbox' : 'prod'],
      partnerId
    )
  )
  return get<api.Partner[]>({ type: 'many', query })
}

export const useGetPartnerConfigs = (
  sandboxMode: boolean,
  params: api.GetPartnerConfigsParams
) => {
  const { authHeader } = useAuth()
  const query = api.useGetPartnerConfigs(
    params,
    getQueryOptions(authHeader, sandboxMode, [
      ...api.getGetPartnerConfigsQueryKey(params),
      sandboxMode ? 'sandbox' : 'prod',
    ])
  )
  return get<api.PartnerConfigSerializer[]>({ type: 'many', query })
}

export const useListOrganizations = (sandbox: boolean, enabled: boolean) => {
  const { authHeader } = useAuth()
  const query = api.useListOrganizations({
    axios: getQueryOptions(authHeader, sandbox, [
      ...api.getListOrganizationsQueryKey(),
      sandbox ? 'sandbox' : 'prod',
    ]).axios,
    query: { enabled },
  })
  return get<api.Organization>({ type: 'one', query })
}

export const useFetchSlackChannels = () => {
  const query = api.useGetSlackChannels(
    useQueryOptions([...api.getGetSlackChannelsQueryKey()])
  )
  return get<api.SlackChannelResponseSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchUsersFromProduction = () => {
  const query = api.useGetPartnerUsers(
    useQueryOptions([...api.getGetPartnerUsersQueryKey()])
  )
  return get<api.PartnerUser[]>({
    type: 'many',
    query,
  })
}

export const useFetchApiLogs = (params?: api.ListApiRequestsParams) => {
  const query = api.useListApiRequests(
    params,
    useQueryOptions([...api.getListApiRequestsQueryKey(params)])
  )
  return get<api.ListApiRequestResponse>({
    type: 'flat',
    query,
  })
}

export const useFetchApiKeys = () => {
  const query = api.useGetPartnerApiKey(
    {},
    useQueryOptions([...api.getGetPartnerApiKeyQueryKey()])
  )
  return get<api.ApiKeyResponseSerializer[]>({
    type: 'flat',
    query,
  })
}

export const useGetPartnerSyncWebhooks = () => {
  const query = api.useGetPartnerSyncWebhooks(
    useQueryOptions([...api.getGetPartnerSyncWebhooksQueryKey()])
  )
  return get<api.PdWebhookEndpoint>({ type: 'one', query })
}

export const useGetPartnerWebhooks = () => {
  const query = api.useListPdWebhookEndpoints(
    {},
    useQueryOptions([...api.getListPdWebhookEndpointsQueryKey()])
  )
  return get<api.PdWebhookEndpoint>({ type: 'one', query })
}

export const useGetPartnerWebhookEndpoints = () => {
  const query = api.useListPdWebhookEndpoints(
    {},
    useQueryOptions([...api.getListPdWebhookEndpointsQueryKey()])
  )
  return get<api.PdWebhookEndpoint[]>({ type: 'many', query })
}

export const useGetPartnerDashboardCsvs = (
  params: api.ListS3PartnerDashboardTableCsvsParams,
  refetch?: {
    enabled: boolean
    refetchInterval: number
  }
) => {
  const queryOptions = useQueryOptions([
    ...api.getListS3PartnerDashboardTableCsvsQueryKey(params),
  ])
  const query = api.useListS3PartnerDashboardTableCsvs(params, {
    ...queryOptions,
    query: { ...refetch, ...queryOptions.query },
  })
  return get<api.ListS3PartnerDashboardTableCsvsResponse>({
    type: 'flat',
    query,
  })
}

export const useFetchOneSchemaJwtToken = () => {
  const { partner, sandbox } = useCore()
  const headers = useHeaders()
  return useQuery({
    queryKey: ['one-schema-jwt', partner?.id, sandbox ? 'sandbox' : 'prod'],
    queryFn: () =>
      api.postDataingestionOneschemaGenerateJwt({ headers, baseURL: BASE_URL }),
  })
}

export const useFetchDataShareUploads = (
  params: api.ListDatasetUploadsParams
) => {
  const query = api.useListDatasetUploads(
    params,
    useQueryOptions([...api.getListDatasetUploadsQueryKey(params)])
  )
  return get<api.DatasetUploadSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchS3DataIntegration = () => {
  const query = api.useGetDataingestionS3DatasetIntegrations(
    {},
    useQueryOptions([...api.getGetDataingestionS3DatasetIntegrationsQueryKey()])
  )
  return get<api.S3DatasetIntegrationSerializer[]>({ type: 'many', query })
}

export const useFetchS3DataIngestionConfig = (
  params?: api.GetDataingestionS3DatasetIngestionConfigsParams
) => {
  const query = api.useGetDataingestionS3DatasetIngestionConfigs(
    params,
    useQueryOptions([
      ...api.getGetDataingestionS3DatasetIngestionConfigsQueryKey(params),
    ])
  )
  return get<api.S3DatasetIngestionConfigSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchS3DataUploadDetails = (
  params?: api.GetDataingestionS3DatasetUploadsParams
) => {
  const query = api.useGetDataingestionS3DatasetUploads(params, {
    ...useQueryOptions([
      ...api.getGetDataingestionS3DatasetUploadsQueryKey(params),
    ]),
    query: { cacheTime: 0 },
  })
  return get<api.S3DatasetUploadSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchS3DataUploadValidationResults = (
  params: api.GetDataingestionS3DatasetUploadValidationResultsParams
) => {
  const query = api.useGetDataingestionS3DatasetUploadValidationResults(
    params,
    useQueryOptions([
      ...api.getGetDataingestionS3DatasetUploadValidationResultsQueryKey(
        params
      ),
    ])
  )
  return get<api.S3DatasetUploadValidationResultSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchManualUploadDetails = (
  params?: api.ListDatasetUploadsParams
) => {
  const query = api.useListDatasetUploads(
    params,
    useQueryOptions([...api.getListDatasetUploadsQueryKey(params)])
  )
  return get<api.DatasetUploadSerializer[]>({
    type: 'many',
    query,
  })
}

export const useFetchManualUploadValidationResults = (
  params: api.ListManualDatasetUploadValidationResultsParams
) => {
  const query = api.useListManualDatasetUploadValidationResults(
    params,
    useQueryOptions([
      ...api.getListManualDatasetUploadValidationResultsQueryKey(params),
    ])
  )
  return get<api.CreateManualDatasetUploadValidationResultRequest[]>({
    type: 'many',
    query,
  })
}

export const useGTMToolkit = () => {
  const query = api.useGetMarketingGtmToolkit(
    useQueryOptions([...api.getGetMarketingGtmToolkitQueryKey()])
  )
  return get<api.GTMToolkitResponse>({ type: 'flat', query })
}

export const useGetRecentOfferCsvs = (enableRefetch: Boolean) => {
  const options = useQueryOptions([...api.getListS3OfferCsvsQueryKey()])
  const query = api.useListS3OfferCsvs({
    axios: options.axios,
    query: {
      ...options.query,
      ...(enableRefetch ? { refetchInterval: 4000 } : {}),
    },
  })
  return get<api.S3OfferCsv[]>({ type: 'flat', query })
}

export const useGetRecentCrmSync = (enableRefetch: Boolean) => {
  const options = useQueryOptions([...api.getGetSyncQueryKey()])
  const query = api.useGetSync({
    axios: options.axios,
    query: {
      ...options.query,
      ...(enableRefetch ? { refetchInterval: 4000 } : {}),
    },
  })
  return get<api.GetResponse>({ type: 'flat', query })
}

export const useGetCrmIntegrations = () => {
  const options = useQueryOptions([...api.getListPdCrmIntegrationsQueryKey()])
  const query = api.useListPdCrmIntegrations({
    axios: options.axios,
    query: {
      ...options.query,
    },
  })
  return get<api.CrmIntegration[]>({ type: 'flat', query })
}

export const useGetParagonTokenAndAuthenticate = () => {
  const { partner, sandbox } = useCore()
  const options = useQueryOptions()
  return useQuery({
    queryKey: [
      ...api.getGetMarketingParagonTokenQueryKey(),
      partner?.id,
      sandbox ? 'sandbox' : 'prod',
    ],
    queryFn: async () => {
      const res = await api.getMarketingParagonToken(options.axios)
      const token = res.data.token
      await paragon.authenticate(PARAGON_PROJECT_ID, token)
      return res
    },
    refetchInterval: 23 * 60 * 60 * 1000, // 23 hours in ms
  })
}

export const useFetchWidgetConfig = () => {
  const query = api.useGetPartnerWidgetConfigs(
    useQueryOptions([...api.getGetPartnerWidgetConfigsQueryKey()])
  )
  return get<api.PartnerWidgetConfigSerializer>({ type: 'flat', query })
}

export const useFetchBusinessSalesRecords = (
  params: api.GetBusinessesDailySalesRecordsParams
) => {
  const query = api.useGetBusinessesDailySalesRecords(
    { ...params, page_size: 25 },
    useQueryOptions([...api.getGetBusinessesDailySalesRecordsQueryKey(params)])
  )
  return get<api.PaginatedResponseDailySalesRecord>({
    type: 'paginated',
    query,
  })
}

export const useListVersionedSchemas = (
  params: api.ListVersionedSchemasParams
) => {
  const query = api.useListVersionedSchemas(
    params,
    useQueryOptions([...api.getListVersionedSchemasQueryKey(params)])
  )
  return get<api.ListResponse>({ type: 'flat', query })
}

export const useGetCubeToken = () => {
  const query = api.useGetAnalyticsCubeToken(
    useQueryOptions([...api.getGetAnalyticsCubeTokenQueryKey()])
  )
  return get<api.CubeJwtResponse>({
    type: 'flat',
    query,
  })
}

export const useGetCubeQuery = (cubejsApi: CubejsApi, cubeQuery: Query) => {
  const { partner, sandbox } = useCore()
  return useQuery({
    queryKey: [
      'cube-query',
      cubeQuery,
      partner?.id,
      sandbox ? 'sandbox' : 'prod',
    ],
    queryFn: () => cubejsApi.load(cubeQuery),
  })
}

export const useGetBusinessDetailsApplications = (business_id: string) => {
  const query = api.useListPdBusinessDetailsApplications(
    business_id,
    { date_started_asc: true },
    useQueryOptions([
      ...api.getListPdBusinessDetailsApplicationsQueryKey(business_id, {
        date_started_asc: true,
      }),
    ])
  )
  return get<api.CapitalProductApplicationRow[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessDetailsBankInfo = (business_id: string) => {
  const query = api.useListPdBusinessDetailsBankInfo(
    business_id,
    useQueryOptions([
      ...api.getListPdBusinessDetailsBankInfoQueryKey(business_id),
    ])
  )
  return get<api.BankInfo>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessDetailsBusinessInfo = (business_id: string) => {
  const query = api.useListPdBusinessDetailsBusinessInfo(
    business_id,
    useQueryOptions([
      ...api.getListPdBusinessDetailsBusinessInfoQueryKey(business_id),
    ])
  )
  return get<api.BusinessInfo>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessDetailsFundedProducts = (business_id: string) => {
  const query = api.useListPdBusinessDetailsFundedProducts(
    business_id,
    useQueryOptions([
      ...api.getListPdBusinessDetailsFundedProductsQueryKey(business_id),
    ])
  )
  return get<api.FundedProduct[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessDetailsFundedProductPaymentProgress = (
  business_id: string,
  capital_product_id: string
) => {
  const query = api.useListPdBusinessDetailsFundedProductPaymentProgress(
    business_id,
    { capital_product_id },
    useQueryOptions([
      ...api.getListPdBusinessDetailsFundedProductPaymentProgressQueryKey(
        business_id,
        { capital_product_id }
      ),
    ])
  )
  return get<api.PaymentProgressInfo>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessDetailsOffers = (business_id: string) => {
  const query = api.useListPdBusinessDetailsOffers(
    business_id,
    undefined,
    useQueryOptions([
      ...api.getListPdBusinessDetailsOffersQueryKey(business_id),
    ])
  )
  return get<api.CapitalProductOfferRow[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessDetailsOwnerInfo = (business_id: string) => {
  const query = api.useListPdBusinessDetailsOwnerInfo(
    business_id,
    useQueryOptions([
      ...api.getListPdBusinessDetailsOwnerInfoQueryKey(business_id),
    ])
  )
  return get<api.OwnerInfo>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessDetailsFundedProductDetails = (
  business_id: string,
  capital_product_id: string
) => {
  const query = api.useListPdBusinessDetailsFundedProductDetails(
    business_id,
    { capital_product_id },
    useQueryOptions([
      ...api.getListPdBusinessDetailsFundedProductDetailsQueryKey(business_id, {
        capital_product_id,
      }),
    ])
  )
  return get<api.FundedProductDetails>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessTableRows = (
  params: api.ListPdBusinessTableRowsParams
) => {
  const query = api.useListPdBusinessTableRows(
    params,
    useQueryOptions([...api.getListPdBusinessTableRowsQueryKey(params)])
  )
  return get<api.BusinessTableRow[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessTableRowsCount = (
  params: api.CountPdBusinessTableRowsParams
) => {
  const query = api.useCountPdBusinessTableRows(
    params,
    useQueryOptions([...api.getCountPdBusinessTableRowsQueryKey(params)])
  )
  return get<api.GetBusinessCountResponse>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessCapitalApplicationRows = (
  params: api.ListPdCapitalProductApplicationRowsParams
) => {
  const query = api.useListPdCapitalProductApplicationRows(
    params,
    useQueryOptions([
      ...api.getListPdCapitalProductApplicationRowsQueryKey(params),
    ])
  )
  return get<api.CapitalProductApplicationRow1[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessCapitalApplicationRowsCount = (
  params: api.CountPdCapitalProductApplicationRowParams
) => {
  const query = api.useCountPdCapitalProductApplicationRow(
    params,
    useQueryOptions([
      ...api.getCountPdCapitalProductApplicationRowQueryKey(params),
    ])
  )
  return get<api.GetApplicationCountResponse>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessCapitalOfferRows = (
  params: api.ListPdCapitalProductOfferRowsParams
) => {
  const query = api.useListPdCapitalProductOfferRows(
    params,
    useQueryOptions([...api.getListPdCapitalProductOfferRowsQueryKey(params)])
  )
  return get<api.CapitalProductOfferRow1[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessCapitalOfferRowsCount = (
  params: api.CountPdCapitalProductOfferRowParams
) => {
  const query = api.useCountPdCapitalProductOfferRow(
    params,
    useQueryOptions([api.getCountPdCapitalProductOfferRowQueryKey(params)])
  )
  return get<api.GetOfferCountResponse>({
    type: 'flat',
    query,
  })
}

export const useGetBusinessCapitalProductsRows = (
  params: api.ListPdCapitalProductRowsParams
) => {
  const query = api.useListPdCapitalProductRows(
    params,
    useQueryOptions([...api.getListPdCapitalProductRowsQueryKey(params)])
  )
  return get<api.CapitalProductRow[]>({
    type: 'many',
    query,
  })
}

export const useGetBusinessCapitalProductsRowsCount = (
  params: api.CountPdCapitalProductRowParams
) => {
  const query = api.useCountPdCapitalProductRow(
    params,
    useQueryOptions([...api.getCountPdCapitalProductRowQueryKey(params)])
  )
  return get<api.GetProductCountResponse>({
    type: 'flat',
    query,
  })
}

export const useListPdWebhookLogsEvents = (
  params: api.ListPdWebhookLogsEventsParams
) => {
  const query = api.useListPdWebhookLogsEvents(
    params,
    useQueryOptions([...api.getListPdWebhookLogsEventsQueryKey(params)])
  )
  return get<api.ListWebhookEventsResponse>({
    type: 'flat',
    query,
  })
}

export const useListPdCrmEvents = (params: api.ListPdCrmEventsParams) => {
  const query = api.useListPdCrmEvents(
    params,
    useQueryOptions([...api.getListPdCrmEventsQueryKey(params)])
  )
  return get<api.ListCrmEventsResponse>({
    type: 'flat',
    query,
  })
}

export const useListPdWebhookLogsEventInfo = (id: string) => {
  const query = api.useListPdWebhookLogsEventInfo(
    id,
    useQueryOptions([...api.getListPdWebhookLogsEventInfoQueryKey(id)])
  )
  return get<api.WebhookEventInfo>({
    type: 'flat',
    query,
  })
}

export const useListPdCrmEventInfo = (id: string) => {
  const query = api.useListPdCrmEventInfo(
    id,
    useQueryOptions([...api.getListPdCrmEventInfoQueryKey(id)])
  )
  return get<api.CrmEventInfo>({
    type: 'flat',
    query,
  })
}

export const useListPdWebhookLogsDeliveryAttempts = (
  id: string,
  params: api.ListPdWebhookLogsDeliveryAttemptsParams,
  refetch?: {
    enabled: boolean
    refetchInterval: number
  }
) => {
  const queryKey = [
    ...api.getListPdWebhookLogsDeliveryAttemptsQueryKey(id, params),
  ]
  const query = api.useListPdWebhookLogsDeliveryAttempts(id, params, {
    ...useQueryOptions(),
    query: { ...refetch, queryKey },
  })
  return get<api.WebhookDeliveryAttemptRow[]>({
    type: 'many',
    query,
  })
}

export const useMultiWebhookPdWebhookLogsDeliveryAttempts = (
  id: string,
  refetch?: {
    enabled: boolean
    refetchInterval: number
  }
) => {
  const queryKey = [
    ...api.getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey(id),
  ]
  const query = api.useMultiWebhookPdWebhookLogsDeliveryAttempts(id, {
    ...useQueryOptions(),
    query: { ...refetch, queryKey },
  })
  return get<api.WebhookEventDeliveryAttempts[]>({
    type: 'many',
    query,
  })
}

export const useListPdCrmEventDeliveryAttempts = (
  id: string,
  params: api.ListPdCrmDeliveryAttemptsParams,
  refetch?: {
    enabled: boolean
    refetchInterval: number
  }
) => {
  const queryKey = [...api.getListPdCrmDeliveryAttemptsQueryKey(id, params)]
  const query = api.useListPdCrmDeliveryAttempts(id, params, {
    ...useQueryOptions(),
    query: { ...refetch, queryKey },
  })
  return get<api.CrmDeliveryAttemptRow[]>({
    type: 'many',
    query,
  })
}

export const useGetPdUtilityInfo = () => {
  const query = api.useGetPdUtilityInfo(
    useQueryOptions([...api.getGetPdUtilityInfoQueryKey()])
  )
  return get<api.ValidObjectIds>({
    type: 'flat',
    query,
  })
}

export const useGetMarketingIntegrations = () => {
  const query = api.useGetMarketingIntegrations(
    useQueryOptions([...api.getGetMarketingIntegrationsQueryKey()])
  )
  return get<api.CrmIntegrationResponse>({ type: 'flat', query })
}

export const useGetPartnerSupportInfo = () => {
  const query = api.useListPdSupportInfo(
    useQueryOptions([...api.getListPdSupportInfoQueryKey()])
  )
  return get<api.PartnerSupportInfo>({
    type: 'flat',
    query,
  })
}
