import React from 'react'
import {
  Link as RLink,
  LinkProps as RLinkProps,
  useSearchParams,
} from 'react-router-dom'
import { getLinkWithParams } from '@/src/routes/Navigate'
import styled, { css } from 'styled-components'
import useTheme from '@/src/ui/theme'
import { useSegment } from '@parafin/logging'

type LinkVariant = 'green' | 'gray'

const useColorsForVariant = (variant: LinkVariant = 'green') => {
  const { theme } = useTheme()
  switch (variant) {
    case 'green':
      return {
        color: theme.colors.accent100,
        hoverColor: theme.colors.accent300,
      }
    case 'gray':
      return {
        color: theme.colors.mono700,
        hoverColor: theme.colors.mono800,
      }
    default:
      return {
        color: theme.colors.accent100,
        hoverColor: theme.colors.accent300,
      }
  }
}

export const linkComponentCss = css`
  border: 0;
  padding: 0;
  font-family: inherit;
  background: inherit;
`

export const linkCss = css<{ variant?: LinkVariant }>`
  cursor: pointer;
  transition: color 0.3s ease;
  color: ${({ variant }) => useColorsForVariant(variant).color};

  &:not(:disabled):hover {
    color: ${({ variant }) => useColorsForVariant(variant).hoverColor};

    svg {
      ${({ variant }) =>
        variant && `stroke: ${useColorsForVariant(variant).hoverColor}`};
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const baseCss = css<{ block?: boolean }>`
  display: ${({ block }) => (block ? 'block' : 'inline')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
`

export const LinkButton = styled.button<{
  variant?: LinkVariant
}>`
  ${baseCss}
  ${linkCss}
  ${linkComponentCss}
`

type BaseLinkType = {
  id: string
  unstyled?: boolean
  hovered?: boolean
  to?: string
  href?: string
  ref?: React.Ref<any>
  children?: React.ReactNode
  persistParams?: boolean
}

type LinkType = BaseLinkType &
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
  Omit<RLinkProps, 'to'>

export const Link = ({
  id,
  unstyled,
  to,
  href,
  ref,
  children,
  hovered,
  persistParams,
  ...rest
}: LinkType) => {
  const { logInteraction } = useSegment()
  const [search] = useSearchParams()
  const logClick = () => {
    logInteraction(`link-${id}`, 'click')
  }

  if (href || !to) {
    if (unstyled) {
      return (
        <a onClick={logClick} href={href} {...rest}>
          {children}
        </a>
      )
    }

    return (
      <StyledHrefLink onClick={logClick} href={href} {...rest}>
        {children}
      </StyledHrefLink>
    )
  }

  const linkTo = getLinkWithParams(to, search, persistParams)
  const props = {
    to: linkTo,
    ref,
    ...rest,
  }

  if (unstyled) {
    return (
      <RLink onClick={logClick} {...props}>
        {children}
      </RLink>
    )
  }

  return (
    <StyledLink onClick={logClick} {...props}>
      {children}
    </StyledLink>
  )
}

const StyledHrefLink = styled.a`
  ${linkCss}
  ${baseCss}
`

const StyledLink = styled(RLink)`
  ${linkCss}
  ${baseCss}
`
