import * as g from "react";
import { useState as _, useEffect as y, useCallback as Se, useRef as Ee } from "react";
const Me = Object.prototype.toString;
function $e(e, t) {
  return Me.call(e) === `[object ${t}]`;
}
function Re(e) {
  return $e(e, "Object");
}
function Oe(e) {
  return !!(e && e.then && typeof e.then == "function");
}
const P = "8.28.0", E = globalThis;
function xe(e, t, n) {
  const r = n || E, a = r.__SENTRY__ = r.__SENTRY__ || {}, s = a[P] = a[P] || {};
  return s[e] || (s[e] = t());
}
const De = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, Ue = "Sentry Logger ", oe = [
  "debug",
  "info",
  "warn",
  "error",
  "log",
  "assert",
  "trace"
], ie = {};
function Fe(e) {
  if (!("console" in E))
    return e();
  const t = E.console, n = {}, r = Object.keys(ie);
  r.forEach((a) => {
    const s = ie[a];
    n[a] = t[a], t[a] = s;
  });
  try {
    return e();
  } finally {
    r.forEach((a) => {
      t[a] = n[a];
    });
  }
}
function Be() {
  let e = !1;
  const t = {
    enable: () => {
      e = !0;
    },
    disable: () => {
      e = !1;
    },
    isEnabled: () => e
  };
  return De ? oe.forEach((n) => {
    t[n] = (...r) => {
      e && Fe(() => {
        E.console[n](`${Ue}[${n}]:`, ...r);
      });
    };
  }) : oe.forEach((n) => {
    t[n] = () => {
    };
  }), t;
}
const R = Be();
function We(e, t, n) {
  try {
    Object.defineProperty(e, t, {
      // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
      value: n,
      writable: !0,
      configurable: !0
    });
  } catch {
    De && R.log(`Failed to add non-enumerable property "${t}" to object`, e);
  }
}
const Ce = 1e3;
function Te() {
  return Date.now() / Ce;
}
function Ye() {
  const { performance: e } = E;
  if (!e || !e.now)
    return Te;
  const t = Date.now() - e.now(), n = e.timeOrigin == null ? t : e.timeOrigin;
  return () => (n + e.now()) / Ce;
}
const je = Ye();
(() => {
  const { performance: e } = E;
  if (!e || !e.now)
    return;
  const t = 3600 * 1e3, n = e.now(), r = Date.now(), a = e.timeOrigin ? Math.abs(e.timeOrigin + n - r) : t, s = a < t, o = e.timing && e.timing.navigationStart, c = typeof o == "number" ? Math.abs(o + n - r) : t, u = c < t;
  return s || u ? a <= c ? e.timeOrigin : o : r;
})();
function k() {
  const e = E, t = e.crypto || e.msCrypto;
  let n = () => Math.random() * 16;
  try {
    if (t && t.randomUUID)
      return t.randomUUID().replace(/-/g, "");
    t && t.getRandomValues && (n = () => {
      const r = new Uint8Array(1);
      return t.getRandomValues(r), r[0];
    });
  } catch {
  }
  return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(
    /[018]/g,
    (r) => (
      // eslint-disable-next-line no-bitwise
      (r ^ (n() & 15) >> r / 4).toString(16)
    )
  );
}
function ce() {
  return {
    traceId: k(),
    spanId: k().substring(16)
  };
}
function ne() {
  return re(E), E;
}
function re(e) {
  const t = e.__SENTRY__ = e.__SENTRY__ || {};
  return t.version = t.version || P, t[P] = t[P] || {};
}
function Ge(e, t = {}) {
  if (t.user && (!e.ipAddress && t.user.ip_address && (e.ipAddress = t.user.ip_address), !e.did && !t.did && (e.did = t.user.id || t.user.email || t.user.username)), e.timestamp = t.timestamp || je(), t.abnormal_mechanism && (e.abnormal_mechanism = t.abnormal_mechanism), t.ignoreDuration && (e.ignoreDuration = t.ignoreDuration), t.sid && (e.sid = t.sid.length === 32 ? t.sid : k()), t.init !== void 0 && (e.init = t.init), !e.did && t.did && (e.did = `${t.did}`), typeof t.started == "number" && (e.started = t.started), e.ignoreDuration)
    e.duration = void 0;
  else if (typeof t.duration == "number")
    e.duration = t.duration;
  else {
    const n = e.timestamp - e.started;
    e.duration = n >= 0 ? n : 0;
  }
  t.release && (e.release = t.release), t.environment && (e.environment = t.environment), !e.ipAddress && t.ipAddress && (e.ipAddress = t.ipAddress), !e.userAgent && t.userAgent && (e.userAgent = t.userAgent), typeof t.errors == "number" && (e.errors = t.errors), t.status && (e.status = t.status);
}
const X = "_sentrySpan";
function ue(e, t) {
  t ? We(e, X, t) : delete e[X];
}
function de(e) {
  return e[X];
}
const Ve = 100;
class ae {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called during event processing. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /** Propagation Context for distributed tracing */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  /**
   * Transaction Name
   *
   * IMPORTANT: The transaction name on the scope has nothing to do with root spans/transaction objects.
   * It's purpose is to assign a transaction to the scope that's added to non-transaction events.
   */
  /** Session */
  /** Request Mode Session Status */
  /** The client on this scope */
  /** Contains the last event id of a captured event.  */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = !1, this._scopeListeners = [], this._eventProcessors = [], this._breadcrumbs = [], this._attachments = [], this._user = {}, this._tags = {}, this._extra = {}, this._contexts = {}, this._sdkProcessingMetadata = {}, this._propagationContext = ce();
  }
  /**
   * @inheritDoc
   */
  clone() {
    const t = new ae();
    return t._breadcrumbs = [...this._breadcrumbs], t._tags = { ...this._tags }, t._extra = { ...this._extra }, t._contexts = { ...this._contexts }, t._user = this._user, t._level = this._level, t._session = this._session, t._transactionName = this._transactionName, t._fingerprint = this._fingerprint, t._eventProcessors = [...this._eventProcessors], t._requestSession = this._requestSession, t._attachments = [...this._attachments], t._sdkProcessingMetadata = { ...this._sdkProcessingMetadata }, t._propagationContext = { ...this._propagationContext }, t._client = this._client, t._lastEventId = this._lastEventId, ue(t, de(this)), t;
  }
  /**
   * @inheritDoc
   */
  setClient(t) {
    this._client = t;
  }
  /**
   * @inheritDoc
   */
  setLastEventId(t) {
    this._lastEventId = t;
  }
  /**
   * @inheritDoc
   */
  getClient() {
    return this._client;
  }
  /**
   * @inheritDoc
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   */
  addScopeListener(t) {
    this._scopeListeners.push(t);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(t) {
    return this._eventProcessors.push(t), this;
  }
  /**
   * @inheritDoc
   */
  setUser(t) {
    return this._user = t || {
      email: void 0,
      id: void 0,
      ip_address: void 0,
      username: void 0
    }, this._session && Ge(this._session, { user: t }), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(t) {
    return this._requestSession = t, this;
  }
  /**
   * @inheritDoc
   */
  setTags(t) {
    return this._tags = {
      ...this._tags,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTag(t, n) {
    return this._tags = { ...this._tags, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtras(t) {
    return this._extra = {
      ...this._extra,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtra(t, n) {
    return this._extra = { ...this._extra, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(t) {
    return this._fingerprint = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setLevel(t) {
    return this._level = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTransactionName(t) {
    return this._transactionName = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setContext(t, n) {
    return n === null ? delete this._contexts[t] : this._contexts[t] = n, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setSession(t) {
    return t ? this._session = t : delete this._session, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(t) {
    if (!t)
      return this;
    const n = typeof t == "function" ? t(this) : t, [r, a] = n instanceof N ? [n.getScopeData(), n.getRequestSession()] : Re(n) ? [t, t.requestSession] : [], { tags: s, extra: o, user: i, contexts: c, level: u, fingerprint: d = [], propagationContext: l } = r || {};
    return this._tags = { ...this._tags, ...s }, this._extra = { ...this._extra, ...o }, this._contexts = { ...this._contexts, ...c }, i && Object.keys(i).length && (this._user = i), u && (this._level = u), d.length && (this._fingerprint = d), l && (this._propagationContext = l), a && (this._requestSession = a), this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    return this._breadcrumbs = [], this._tags = {}, this._extra = {}, this._user = {}, this._contexts = {}, this._level = void 0, this._transactionName = void 0, this._fingerprint = void 0, this._requestSession = void 0, this._session = void 0, ue(this, void 0), this._attachments = [], this._propagationContext = ce(), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(t, n) {
    const r = typeof n == "number" ? n : Ve;
    if (r <= 0)
      return this;
    const a = {
      timestamp: Te(),
      ...t
    }, s = this._breadcrumbs;
    return s.push(a), this._breadcrumbs = s.length > r ? s.slice(-r) : s, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    return this._breadcrumbs = [], this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(t) {
    return this._attachments.push(t), this;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    return this._attachments = [], this;
  }
  /** @inheritDoc */
  getScopeData() {
    return {
      breadcrumbs: this._breadcrumbs,
      attachments: this._attachments,
      contexts: this._contexts,
      tags: this._tags,
      extra: this._extra,
      user: this._user,
      level: this._level,
      fingerprint: this._fingerprint || [],
      eventProcessors: this._eventProcessors,
      propagationContext: this._propagationContext,
      sdkProcessingMetadata: this._sdkProcessingMetadata,
      transactionName: this._transactionName,
      span: de(this)
    };
  }
  /**
   * @inheritDoc
   */
  setSDKProcessingMetadata(t) {
    return this._sdkProcessingMetadata = { ...this._sdkProcessingMetadata, ...t }, this;
  }
  /**
   * @inheritDoc
   */
  setPropagationContext(t) {
    return this._propagationContext = t, this;
  }
  /**
   * @inheritDoc
   */
  getPropagationContext() {
    return this._propagationContext;
  }
  /**
   * @inheritDoc
   */
  captureException(t, n) {
    const r = n && n.event_id ? n.event_id : k();
    if (!this._client)
      return R.warn("No client configured on scope - will not capture exception!"), r;
    const a = new Error("Sentry syntheticException");
    return this._client.captureException(
      t,
      {
        originalException: t,
        syntheticException: a,
        ...n,
        event_id: r
      },
      this
    ), r;
  }
  /**
   * @inheritDoc
   */
  captureMessage(t, n, r) {
    const a = r && r.event_id ? r.event_id : k();
    if (!this._client)
      return R.warn("No client configured on scope - will not capture message!"), a;
    const s = new Error(t);
    return this._client.captureMessage(
      t,
      n,
      {
        originalException: t,
        syntheticException: s,
        ...r,
        event_id: a
      },
      this
    ), a;
  }
  /**
   * @inheritDoc
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : k();
    return this._client ? (this._client.captureEvent(t, { ...n, event_id: r }, this), r) : (R.warn("No client configured on scope - will not capture event!"), r);
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    this._notifyingListeners || (this._notifyingListeners = !0, this._scopeListeners.forEach((t) => {
      t(this);
    }), this._notifyingListeners = !1);
  }
}
const N = ae;
function ze() {
  return xe("defaultCurrentScope", () => new N());
}
function Ke() {
  return xe("defaultIsolationScope", () => new N());
}
class qe {
  constructor(t, n) {
    let r;
    t ? r = t : r = new N();
    let a;
    n ? a = n : a = new N(), this._stack = [{ scope: r }], this._isolationScope = a;
  }
  /**
   * Fork a scope for the stack.
   */
  withScope(t) {
    const n = this._pushScope();
    let r;
    try {
      r = t(n);
    } catch (a) {
      throw this._popScope(), a;
    }
    return Oe(r) ? r.then(
      (a) => (this._popScope(), a),
      (a) => {
        throw this._popScope(), a;
      }
    ) : (this._popScope(), r);
  }
  /**
   * Get the client of the stack.
   */
  getClient() {
    return this.getStackTop().client;
  }
  /**
   * Returns the scope of the top stack.
   */
  getScope() {
    return this.getStackTop().scope;
  }
  /**
   * Get the isolation scope for the stack.
   */
  getIsolationScope() {
    return this._isolationScope;
  }
  /**
   * Returns the topmost scope layer in the order domain > local > process.
   */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * Push a scope to the stack.
   */
  _pushScope() {
    const t = this.getScope().clone();
    return this._stack.push({
      client: this.getClient(),
      scope: t
    }), t;
  }
  /**
   * Pop a scope from the stack.
   */
  _popScope() {
    return this._stack.length <= 1 ? !1 : !!this._stack.pop();
  }
}
function A() {
  const e = ne(), t = re(e);
  return t.stack = t.stack || new qe(ze(), Ke());
}
function Xe(e) {
  return A().withScope(e);
}
function He(e, t) {
  const n = A();
  return n.withScope(() => (n.getStackTop().scope = e, t(e)));
}
function le(e) {
  return A().withScope(() => e(A().getIsolationScope()));
}
function Je() {
  return {
    withIsolationScope: le,
    withScope: Xe,
    withSetScope: He,
    withSetIsolationScope: (e, t) => le(t),
    getCurrentScope: () => A().getScope(),
    getIsolationScope: () => A().getIsolationScope()
  };
}
function Ie(e) {
  const t = re(e);
  return t.acs ? t.acs : Je();
}
function Ze() {
  const e = ne();
  return Ie(e).getCurrentScope();
}
function Qe(...e) {
  const t = ne(), n = Ie(t);
  if (e.length === 2) {
    const [r, a] = e;
    return r ? n.withSetScope(r, a) : n.withScope(a);
  }
  return n.withScope(e[0]);
}
function et(e) {
  if (e)
    return tt(e) ? { captureContext: e } : rt(e) ? {
      captureContext: e
    } : e;
}
function tt(e) {
  return e instanceof N || typeof e == "function";
}
const nt = [
  "user",
  "level",
  "extra",
  "contexts",
  "tags",
  "fingerprint",
  "requestSession",
  "propagationContext"
];
function rt(e) {
  return Object.keys(e).some((t) => nt.includes(t));
}
function ke(e, t) {
  return Ze().captureException(e, et(t));
}
let $;
const at = new Uint8Array(16);
function st() {
  if (!$ && ($ = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !$))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return $(at);
}
const m = [];
for (let e = 0; e < 256; ++e)
  m.push((e + 256).toString(16).slice(1));
function ot(e, t = 0) {
  return (m[e[t + 0]] + m[e[t + 1]] + m[e[t + 2]] + m[e[t + 3]] + "-" + m[e[t + 4]] + m[e[t + 5]] + "-" + m[e[t + 6]] + m[e[t + 7]] + "-" + m[e[t + 8]] + m[e[t + 9]] + "-" + m[e[t + 10]] + m[e[t + 11]] + m[e[t + 12]] + m[e[t + 13]] + m[e[t + 14]] + m[e[t + 15]]).toLowerCase();
}
const it = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto), pe = {
  randomUUID: it
};
function ct(e, t, n) {
  if (pe.randomUUID && !t && !e)
    return pe.randomUUID();
  e = e || {};
  const r = e.random || (e.rng || st)();
  if (r[6] = r[6] & 15 | 64, r[8] = r[8] & 63 | 128, t) {
    n = n || 0;
    for (let a = 0; a < 16; ++a)
      t[n + a] = r[a];
    return t;
  }
  return ot(r);
}
let ut = { data: "" }, dt = (e) => typeof window == "object" ? ((e ? e.querySelector("#_goober") : window._goober) || Object.assign((e || document.head).appendChild(document.createElement("style")), { innerHTML: " ", id: "_goober" })).firstChild : e || ut, lt = /(?:([\u0080-\uFFFF\w-%@]+) *:? *([^{;]+?);|([^;}{]*?) *{)|(}\s*)/g, pt = /\/\*[^]*?\*\/|  +/g, me = /\n+/g, D = (e, t) => {
  let n = "", r = "", a = "";
  for (let s in e) {
    let o = e[s];
    s[0] == "@" ? s[1] == "i" ? n = s + " " + o + ";" : r += s[1] == "f" ? D(o, s) : s + "{" + D(o, s[1] == "k" ? "" : t) + "}" : typeof o == "object" ? r += D(o, t ? t.replace(/([^,])+/g, (i) => s.replace(/(^:.*)|([^,])+/g, (c) => /&/.test(c) ? c.replace(/&/g, i) : i ? i + " " + c : c)) : s) : o != null && (s = /^--/.test(s) ? s : s.replace(/[A-Z]/g, "-$&").toLowerCase(), a += D.p ? D.p(s, o) : s + ":" + o + ";");
  }
  return n + (t && a ? t + "{" + a + "}" : a) + r;
}, S = {}, Ne = (e) => {
  if (typeof e == "object") {
    let t = "";
    for (let n in e)
      t += n + Ne(e[n]);
    return t;
  }
  return e;
}, mt = (e, t, n, r, a) => {
  let s = Ne(e), o = S[s] || (S[s] = ((c) => {
    let u = 0, d = 11;
    for (; u < c.length; )
      d = 101 * d + c.charCodeAt(u++) >>> 0;
    return "go" + d;
  })(s));
  if (!S[o]) {
    let c = s !== e ? e : ((u) => {
      let d, l, f = [{}];
      for (; d = lt.exec(u.replace(pt, "")); )
        d[4] ? f.shift() : d[3] ? (l = d[3].replace(me, " ").trim(), f.unshift(f[0][l] = f[0][l] || {})) : f[0][d[1]] = d[2].replace(me, " ").trim();
      return f[0];
    })(e);
    S[o] = D(a ? { ["@keyframes " + o]: c } : c, n ? "" : "." + o);
  }
  let i = n && S.g ? S.g : null;
  return n && (S.g = S[o]), ((c, u, d, l) => {
    l ? u.data = u.data.replace(l, c) : u.data.indexOf(c) === -1 && (u.data = d ? c + u.data : u.data + c);
  })(S[o], t, r, i), o;
}, ht = (e, t, n) => e.reduce((r, a, s) => {
  let o = t[s];
  if (o && o.call) {
    let i = o(n), c = i && i.props && i.props.className || /^go/.test(i) && i;
    o = c ? "." + c : i && typeof i == "object" ? i.props ? "" : D(i, "") : i === !1 ? "" : i;
  }
  return r + a + (o ?? "");
}, "");
function F(e) {
  let t = this || {}, n = e.call ? e(t.p) : e;
  return mt(n.unshift ? n.raw ? ht(n, [].slice.call(arguments, 1), t.p) : n.reduce((r, a) => Object.assign(r, a && a.call ? a(t.p) : a), {}) : n, dt(t.target), t.g, t.o, t.k);
}
let Ae, H, J;
F.bind({ g: 1 });
let x = F.bind({ k: 1 });
function _t(e, t, n, r) {
  D.p = t, Ae = e, H = n, J = r;
}
function C(e, t) {
  let n = this || {};
  return function() {
    let r = arguments;
    function a(s, o) {
      let i = Object.assign({}, s), c = i.className || a.className;
      n.p = Object.assign({ theme: H && H() }, i), n.o = / *go\d+/.test(c), i.className = F.apply(n, r) + (c ? " " + c : ""), t && (i.ref = o);
      let u = e;
      return e[0] && (u = i.as || e, delete i.as), J && u[0] && J(i), Ae(u, i);
    }
    return t ? t(a) : a;
  };
}
var ft = (e) => typeof e == "function", Z = (e, t) => ft(e) ? e(t) : e, gt = (() => {
  let e = 0;
  return () => (++e).toString();
})(), yt = (() => {
  let e;
  return () => {
    if (e === void 0 && typeof window < "u") {
      let t = matchMedia("(prefers-reduced-motion: reduce)");
      e = !t || t.matches;
    }
    return e;
  };
})(), wt = 20, O = /* @__PURE__ */ new Map(), bt = 1e3, he = (e) => {
  if (O.has(e))
    return;
  let t = setTimeout(() => {
    O.delete(e), B({ type: 4, toastId: e });
  }, bt);
  O.set(e, t);
}, vt = (e) => {
  let t = O.get(e);
  t && clearTimeout(t);
}, Q = (e, t) => {
  switch (t.type) {
    case 0:
      return { ...e, toasts: [t.toast, ...e.toasts].slice(0, wt) };
    case 1:
      return t.toast.id && vt(t.toast.id), { ...e, toasts: e.toasts.map((s) => s.id === t.toast.id ? { ...s, ...t.toast } : s) };
    case 2:
      let { toast: n } = t;
      return e.toasts.find((s) => s.id === n.id) ? Q(e, { type: 1, toast: n }) : Q(e, { type: 0, toast: n });
    case 3:
      let { toastId: r } = t;
      return r ? he(r) : e.toasts.forEach((s) => {
        he(s.id);
      }), { ...e, toasts: e.toasts.map((s) => s.id === r || r === void 0 ? { ...s, visible: !1 } : s) };
    case 4:
      return t.toastId === void 0 ? { ...e, toasts: [] } : { ...e, toasts: e.toasts.filter((s) => s.id !== t.toastId) };
    case 5:
      return { ...e, pausedAt: t.time };
    case 6:
      let a = t.time - (e.pausedAt || 0);
      return { ...e, pausedAt: void 0, toasts: e.toasts.map((s) => ({ ...s, pauseDuration: s.pauseDuration + a })) };
  }
}, St = [], W = { toasts: [], pausedAt: void 0 }, B = (e) => {
  W = Q(W, e), St.forEach((t) => {
    t(W);
  });
}, Et = (e, t = "blank", n) => ({ createdAt: Date.now(), visible: !0, type: t, ariaProps: { role: "status", "aria-live": "polite" }, message: e, pauseDuration: 0, ...n, id: (n == null ? void 0 : n.id) || gt() }), M = (e) => (t, n) => {
  let r = Et(t, e, n);
  return B({ type: 2, toast: r }), r.id;
}, b = (e, t) => M("blank")(e, t);
b.error = M("error");
b.success = M("success");
b.loading = M("loading");
b.custom = M("custom");
b.dismiss = (e) => {
  B({ type: 3, toastId: e });
};
b.remove = (e) => B({ type: 4, toastId: e });
b.promise = (e, t, n) => {
  let r = b.loading(t.loading, { ...n, ...n == null ? void 0 : n.loading });
  return e.then((a) => (b.success(Z(t.success, a), { id: r, ...n, ...n == null ? void 0 : n.success }), a)).catch((a) => {
    b.error(Z(t.error, a), { id: r, ...n, ...n == null ? void 0 : n.error });
  }), e;
};
var xt = x`
from {
  transform: scale(0) rotate(45deg);
	opacity: 0;
}
to {
 transform: scale(1) rotate(45deg);
  opacity: 1;
}`, Dt = x`
from {
  transform: scale(0);
  opacity: 0;
}
to {
  transform: scale(1);
  opacity: 1;
}`, Ct = x`
from {
  transform: scale(0) rotate(90deg);
	opacity: 0;
}
to {
  transform: scale(1) rotate(90deg);
	opacity: 1;
}`, Tt = C("div")`
  width: 20px;
  opacity: 0;
  height: 20px;
  border-radius: 10px;
  background: ${(e) => e.primary || "#ff4b4b"};
  position: relative;
  transform: rotate(45deg);

  animation: ${xt} 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation-delay: 100ms;

  &:after,
  &:before {
    content: '';
    animation: ${Dt} 0.15s ease-out forwards;
    animation-delay: 150ms;
    position: absolute;
    border-radius: 3px;
    opacity: 0;
    background: ${(e) => e.secondary || "#fff"};
    bottom: 9px;
    left: 4px;
    height: 2px;
    width: 12px;
  }

  &:before {
    animation: ${Ct} 0.15s ease-out forwards;
    animation-delay: 180ms;
    transform: rotate(90deg);
  }
`, It = x`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`, kt = C("div")`
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: ${(e) => e.secondary || "#e0e0e0"};
  border-right-color: ${(e) => e.primary || "#616161"};
  animation: ${It} 1s linear infinite;
`, Nt = x`
from {
  transform: scale(0) rotate(45deg);
	opacity: 0;
}
to {
  transform: scale(1) rotate(45deg);
	opacity: 1;
}`, At = x`
0% {
	height: 0;
	width: 0;
	opacity: 0;
}
40% {
  height: 0;
	width: 6px;
	opacity: 1;
}
100% {
  opacity: 1;
  height: 10px;
}`, Lt = C("div")`
  width: 20px;
  opacity: 0;
  height: 20px;
  border-radius: 10px;
  background: ${(e) => e.primary || "#61d345"};
  position: relative;
  transform: rotate(45deg);

  animation: ${Nt} 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
  animation-delay: 100ms;
  &:after {
    content: '';
    box-sizing: border-box;
    animation: ${At} 0.2s ease-out forwards;
    opacity: 0;
    animation-delay: 200ms;
    position: absolute;
    border-right: 2px solid;
    border-bottom: 2px solid;
    border-color: ${(e) => e.secondary || "#fff"};
    bottom: 6px;
    left: 6px;
    height: 10px;
    width: 6px;
  }
`, Pt = C("div")`
  position: absolute;
`, Mt = C("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
`, $t = x`
from {
  transform: scale(0.6);
  opacity: 0.4;
}
to {
  transform: scale(1);
  opacity: 1;
}`, Rt = C("div")`
  position: relative;
  transform: scale(0.6);
  opacity: 0.4;
  min-width: 20px;
  animation: ${$t} 0.3s 0.12s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
`, Ot = ({ toast: e }) => {
  let { icon: t, type: n, iconTheme: r } = e;
  return t !== void 0 ? typeof t == "string" ? g.createElement(Rt, null, t) : t : n === "blank" ? null : g.createElement(Mt, null, g.createElement(kt, { ...r }), n !== "loading" && g.createElement(Pt, null, n === "error" ? g.createElement(Tt, { ...r }) : g.createElement(Lt, { ...r })));
}, Ut = (e) => `
0% {transform: translate3d(0,${e * -200}%,0) scale(.6); opacity:.5;}
100% {transform: translate3d(0,0,0) scale(1); opacity:1;}
`, Ft = (e) => `
0% {transform: translate3d(0,0,-1px) scale(1); opacity:1;}
100% {transform: translate3d(0,${e * -150}%,-1px) scale(.6); opacity:0;}
`, Bt = "0%{opacity:0;} 100%{opacity:1;}", Wt = "0%{opacity:1;} 100%{opacity:0;}", Yt = C("div")`
  display: flex;
  align-items: center;
  background: #fff;
  color: #363636;
  line-height: 1.3;
  will-change: transform;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  max-width: 350px;
  pointer-events: auto;
  padding: 8px 10px;
  border-radius: 8px;
`, jt = C("div")`
  display: flex;
  justify-content: center;
  margin: 4px 10px;
  color: inherit;
  flex: 1 1 auto;
  white-space: pre-line;
`, Gt = (e, t) => {
  let n = e.includes("top") ? 1 : -1, [r, a] = yt() ? [Bt, Wt] : [Ut(n), Ft(n)];
  return { animation: t ? `${x(r)} 0.35s cubic-bezier(.21,1.02,.73,1) forwards` : `${x(a)} 0.4s forwards cubic-bezier(.06,.71,.55,1)` };
};
g.memo(({ toast: e, position: t, style: n, children: r }) => {
  let a = e.height ? Gt(e.position || t || "top-center", e.visible) : { opacity: 0 }, s = g.createElement(Ot, { toast: e }), o = g.createElement(jt, { ...e.ariaProps }, Z(e.message, e));
  return g.createElement(Yt, { className: e.className, style: { ...a, ...n, ...e.style } }, typeof r == "function" ? r({ icon: s, message: o }) : g.createElement(g.Fragment, null, s, o));
});
_t(g.createElement);
F`
  z-index: 9999;
  > * {
    pointer-events: auto;
  }
`;
const Vt = (e, t = 2) => `${(e * 100).toFixed(t)}%`, w = (e, t = 2, n = "standard") => {
  try {
    return new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: "usd",
      currencyDisplay: "symbol",
      minimumFractionDigits: t,
      maximumFractionDigits: t,
      notation: n
    }).format(Number(e));
  } catch {
    return `$${Number(e)}`;
  }
}, bn = (e, t = "standard") => {
  try {
    return new Intl.NumberFormat("en", {
      notation: t
    }).format(+e);
  } catch {
    return e.toString();
  }
}, vn = (e) => {
  const n = ("" + e).replace(/\D/g, "").match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  return n ? [n[1] ? "+1 " : "", "(", n[2], ") ", n[3], "-", n[4]].join("") : null;
}, Sn = (e) => {
  const n = ("" + e).replace(/\D/g, "").match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (n)
    return [n[1] ? "+1 " : "", "••• ••• ", n[4]].join("");
}, En = (e, t = 1) => {
  const n = e.indexOf("@");
  if (n <= 0 || t < 0)
    return e;
  const r = e.slice(0, Math.min(n, t)), a = "*".repeat(
    Math.max(n - t, 0)
  ), s = e.slice(n);
  return `${r}${a}${s}`;
}, _e = (e) => e.toISOString().split("T")[0], xn = (e) => {
  const [t, n, r] = e.split("-");
  return new Date(Date.UTC(parseInt(t), parseInt(n) - 1, parseInt(r)));
}, zt = (e, t) => {
  const n = [];
  for (let r = e.getUTCFullYear(); r <= t.getUTCFullYear(); r++) {
    const a = r === e.getUTCFullYear() ? e.getUTCMonth() : 0, s = r === t.getUTCFullYear() ? t.getUTCMonth() : 11;
    for (let o = a; o <= s; o++) {
      const i = o === e.getUTCMonth() && r === e.getUTCFullYear() ? e : new Date(Date.UTC(r, o, 1, 0, 0, 0)), c = new Date(r, o + 1, 0).getDate(), u = o === t.getUTCMonth() && r === t.getUTCFullYear() ? t : new Date(Date.UTC(r, o, c, 0, 0, 0));
      n.push([i, u]);
    }
  }
  return n;
}, Dn = (e, t) => {
  const n = new Date(e);
  return ((/* @__PURE__ */ new Date()).getTime() - n.getTime()) / (1e3 * 3600 * 24) <= t;
}, Cn = (e) => {
  const t = new Date(e), r = (/* @__PURE__ */ new Date()).getFullYear(), a = new Date(r, 0, 1);
  return t >= a;
}, Tn = (e, t) => {
  const n = /* @__PURE__ */ new Date(), r = new Date(e);
  return n.getFullYear() - r.getFullYear() <= t;
}, In = (e, {
  days: t = 0,
  months: n = 0,
  years: r = 0,
  maintainOffset: a = !0
}) => {
  const s = e.getTimezoneOffset(), o = new Date(e);
  t !== 0 && o.setDate(o.getDate() + t), n !== 0 && o.setMonth(o.getMonth() + n), r !== 0 && o.setFullYear(o.getFullYear() + r);
  const i = o.getTimezoneOffset(), c = s - i;
  return a && o.setMinutes(o.getMinutes() + c), o;
}, p = {
  local: {
    get: (e) => {
      try {
        return window.localStorage.getItem(e);
      } catch {
      }
    },
    set: (e, t) => {
      try {
        return window.localStorage.setItem(e, t.toString());
      } catch {
      }
    },
    remove: (e) => {
      try {
        return window.localStorage.removeItem(e);
      } catch {
      }
    },
    clear: () => {
      try {
        return window.localStorage.clear();
      } catch {
      }
    }
  },
  session: {
    get: (e) => {
      try {
        return window.sessionStorage.getItem(e);
      } catch {
        return;
      }
    },
    set: (e, t) => {
      try {
        return window.sessionStorage.setItem(e, t.toString());
      } catch {
      }
    },
    remove: (e) => {
      try {
        return window.sessionStorage.removeItem(e);
      } catch {
      }
    },
    clear: () => {
      try {
        return window.sessionStorage.clear();
      } catch {
      }
    }
  }
}, kn = () => {
  const e = p.session.get("session_id"), t = e ?? ct();
  return e || p.session.set("session_id", t), t;
}, Nn = (e) => {
  const t = e.split("-");
  return `${t[1]}/${t[2]}/${t[0]}`;
}, Le = (e) => ({
  month: (e == null ? void 0 : e.month) ?? "long",
  day: (e == null ? void 0 : e.day) ?? "numeric",
  year: (e == null ? void 0 : e.year) ?? "numeric",
  hour: (e == null ? void 0 : e.hour) ?? void 0,
  minute: (e == null ? void 0 : e.minute) ?? void 0,
  hour12: e != null && e.hour ? !0 : void 0,
  weekday: (e == null ? void 0 : e.weekday) ?? void 0,
  timeZone: e != null && e.local ? void 0 : "UTC"
}), An = (e) => new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  hour12: !0,
  timeZoneName: "short"
}).format(new Date(e)), U = (e, t) => new Intl.DateTimeFormat(
  "en-US",
  Le(t)
).format(new Date(e)), Kt = (e, t, n) => {
  const r = new Intl.DateTimeFormat(
    "en-US",
    Le(n)
  );
  return r.formatRange ? r.formatRange(e, t).replace(/ – /, "–") : e.getTime() === t.getTime() ? U(e, n) : `${U(e, n)} – ${U(t, n)}`;
}, Ln = (e) => e % 10 == 1 && e % 100 != 11 ? "st" : e % 10 == 2 && e % 100 != 12 ? "nd" : e % 10 == 3 && e % 100 != 13 ? "rd" : "th", qt = (e) => {
  const t = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  e = e.replace(t, (r, a, s, o) => a + a + s + s + o + o);
  const n = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
  return n ? {
    r: parseInt(n[1], 16),
    g: parseInt(n[2], 16),
    b: parseInt(n[3], 16)
  } : {
    r: parseInt("#000", 16),
    g: parseInt("#000", 16),
    b: parseInt("#000", 16)
  };
}, Pn = (e, t) => {
  const n = qt(e);
  return `rgba(${n.r}, ${n.g}, ${n.b}, ${t})`;
}, Mn = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map(
    (r) => {
      var a;
      return r.chunks ? (a = r.chunks) == null ? void 0 : a.map((s) => {
        var o;
        return s && Number((o = s.amount_range) == null ? void 0 : o[1]);
      }) : 0;
    }
  ).flat();
  return t ? Math.max(...t) : 0;
}, $n = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map(
    (r) => {
      var a;
      return r.chunks ? (a = r.chunks) == null ? void 0 : a.map((s) => {
        var o;
        return s && Number((o = s.amount_range) == null ? void 0 : o[0]);
      }) : 0;
    }
  ).flat();
  return t ? Math.min(...t) : 0;
}, Rn = (e, t, n) => {
  var s;
  const r = e.offers, a = r == null ? void 0 : r.find((o) => o.label === n);
  return (s = a == null ? void 0 : a.chunks) == null ? void 0 : s.find((o) => {
    if (o.amount_range)
      return t > +o.amount_range[0] && t <= +o.amount_range[1];
  });
}, On = (e) => {
  const t = se();
  return t.setDate(t.getDate() + e), t;
}, se = () => {
  const e = /* @__PURE__ */ new Date();
  return new Date(
    e.getTime() - e.getTimezoneOffset() * 60 * 1e3
  );
}, Un = () => {
  try {
    return window.self !== window.top;
  } catch {
    return !0;
  }
}, Xt = (e) => {
  try {
    return new URL(e).pathname;
  } catch {
    return e.split("?")[0];
  }
}, Fn = (e) => {
  var s, o;
  const t = e.request.status, n = (o = (s = e.config) == null ? void 0 : s.method) == null ? void 0 : o.toUpperCase(), r = Xt(e.request.responseURL), a = e.request.responseText;
  Qe((i) => {
    i.setExtra("route", r), i.setExtra("method", n), i.setExtra("message", a), i.setExtra("stack", e.stack);
    const c = new Error(
      `(${t}) ${n} ${r}: ${a}`
    );
    ke(c);
  });
}, Ht = (e) => e.map((r) => {
  const [a, s, o] = r.split("-");
  return new Date(Date.UTC(Number(a), Number(s) - 1, Number(o)));
}).reduce((r, a) => a < r ? a : r), Bn = (e) => {
  const t = e.map((o) => o.date);
  if (t.length === 0)
    return [];
  const n = Ht(t), r = se();
  return zt(n, r).map((o) => ({
    startDate: _e(o[0]).slice(0, 8) + "01",
    endDate: _e(o[1])
  })).reverse();
}, Wn = (e) => e.offers.map((t) => t.label).sort((t, n) => Number(t.slice(0, -5)) - Number(n.slice(0, -5))), Yn = (e, t) => e.offers.map((n) => {
  const r = n.chunks.flatMap((a) => a.amount_range.flat()).sort((a, s) => +a - +s);
  if (t > +r[0] && t <= +r[r.length - 1])
    return n.label;
}).filter((n) => n !== void 0), jn = (e, t) => `${e}${t > 1 ? "s" : ""}`, Jt = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine"
], Gn = (e, t = !1) => {
  if (e >= 0 && e <= 9 && Number.isInteger(e)) {
    const n = Jt[e];
    return t ? n.charAt(0).toUpperCase() + n.slice(1) : n;
  }
  return e;
}, Vn = (e) => {
  if (!e)
    return "Unknown";
  const t = U(e.sales_period_start, {
    month: "short"
  });
  return e.schedule === "daily" ? t : Kt(
    new Date(e.sales_period_start),
    new Date(e.sales_period_end)
  ).split("–").join(" – ");
}, zn = (e) => {
  const t = se(), n = new Date(e), r = Math.abs(t.getTime() - n.getTime());
  return Math.floor(r / (1e3 * 3600 * 24));
}, Kn = (e) => e * 1.1 - e * 0.2 * Math.random(), qn = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map((r) => r.chunks).flat();
  return t == null ? void 0 : t.reduce((r, a) => {
    var s, o;
    return Number((s = a == null ? void 0 : a.amount_range) == null ? void 0 : s[1]) > Number((o = r == null ? void 0 : r.amount_range) == null ? void 0 : o[1]) ? a : r;
  }, t[0]);
}, Xn = (e, t) => {
  const n = e.toString(), r = t.toString();
  return n.startsWith(r);
}, Hn = (e) => async () => {
  var n;
  const t = window.open("https://app.parafin.com/loading", "_blank");
  try {
    const { data: r } = await e();
    r && t && ("results" in r && ((n = r.results) != null && n[0]) ? t.location = r.results[0].url : "url" in r && r.url && (t.location = r.url));
  } catch (r) {
    t == null || t.close(), b.error("Error generating PDF"), ke(r);
  }
}, Jn = (e) => e && /iPhone/.test(e) ? "iPhone" : e && /Android/.test(e) ? "Android" : "other", Zt = (e) => e[0].toUpperCase() + e.slice(1), Zn = (e) => {
  switch (e) {
    case "cash_advance":
      return "merchant_cash_advance";
    case "card":
    case "merchant_bank":
      return "merchant_bank";
    case "flex_loan":
    case "term_loan":
      return "loan";
  }
}, Qt = (e) => Math.round(+e * 100), Qn = (e, t) => `${Qt(e)}% of ${t === "net_payouts" ? "payouts" : "sales"}`, en = (e) => e === "biweekly" ? "every two weeks" : e, er = (e, t) => `${w(e)} ${en(t)}`, tn = (e, t) => Math.floor(+t.fee_multiplier * e), tr = (e, t, n) => Number(((e + tn(e, t)) / n).toFixed(2)), nr = (e) => {
  const t = [];
  return e.forEach((n) => {
    const r = "date" in n ? n.date : n.timestamp, a = new Date(r).getFullYear(), s = t.findIndex(
      (o) => o.year === a
    );
    s === -1 ? t.push({ year: a, activities: [n] }) : t[s].activities.push(n);
  }), t;
}, rr = (e) => {
  const t = [];
  return e.forEach((n) => {
    const r = new Date(n.effective_at), a = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
      timeZone: "UTC"
    }).format(r), s = t.findIndex(
      (o) => o.monthYear === a
    );
    s === -1 ? t.push({ monthYear: a, activities: [n] }) : t[s].activities.push(n);
  }), t;
}, ar = (e) => {
  switch (e) {
    case "cash_advance":
    case "flex_loan":
    case "term_loan":
      return !0;
    case "card":
    case "merchant_bank":
      return !1;
  }
}, sr = (e) => e.repayment_disbursement_type === "net_payouts" ? "net payouts" : "gross sales", or = (e) => {
  const t = e.filter(
    (n) => n.state === "outstanding"
  );
  return t.length > 0 ? t.reduce((n, r) => n.number > r.number ? n : r) : null;
}, ir = ({
  slug: e,
  desiredSlug: t,
  allowSandbox: n = !0
}) => {
  const r = e === t, a = e === `sandbox-${t}`;
  return r || n && a;
}, cr = (e) => {
  const t = e.replaceAll("_", " ");
  return t.charAt(0).toUpperCase() + t.slice(1).toLowerCase();
}, ur = (e) => e.replaceAll(" ", "_").toLowerCase(), dr = (e, t) => {
  var r;
  const n = (r = e.offers) == null ? void 0 : r.flatMap((a) => a.chunks);
  return n == null ? void 0 : n.find((a) => a && a.id === t);
}, lr = (e) => {
  switch (e) {
    case "flex_loan":
    case "term_loan":
      return "loan";
    case "merchant_cash_advance":
      return "advance";
    default:
      throw new Error("Unhandled product type");
  }
}, pr = (e, t) => {
  var n, r;
  return (r = (n = e.offers) == null ? void 0 : n.find(
    (a) => {
      var s;
      return (s = a.chunks) == null ? void 0 : s.find((o) => o.id === t);
    }
  )) == null ? void 0 : r.label;
}, mr = (e) => `${e == null ? void 0 : e.first_name} ${e == null ? void 0 : e.last_name}`, hr = (e) => `${e.slice(0, -5)} months`, _r = (e) => e === "available" || e === "pending", Y = {
  sm: 480,
  md: 768,
  lg: 1080,
  xl: 1440
}, fr = {
  llc: "LLC",
  corporation: "Corporation",
  sole_proprietorship: "Sole proprietorship",
  partnership: "Partnership",
  cooperative: "Cooperative",
  joint_venture: "Joint venture",
  business_trust: "Business trust",
  other: "Other"
}, gr = () => {
  const [e, t] = _(0);
  return y(() => {
    const n = () => {
      t(window.scrollY);
    };
    return window.addEventListener("scroll", n), () => window.removeEventListener("scroll", n);
  }, []), { scrollPosition: e };
}, yr = (e) => {
  const [t, n] = _("unknown"), r = Se(() => {
    var s;
    const a = {
      publicKey: e,
      userConsent: !0,
      context: "profile"
    };
    (s = window.devicer) == null || s.run(a, function(o) {
      n(o.sessionId);
    });
  }, []);
  return y(() => {
    const a = document.createElement("script");
    a.src = "https://js.dvnfo.com/devicer.min.js", a.async = !1, a.onload = () => r(), document.body.appendChild(a);
  }, [r]), t;
}, wr = (e) => {
  const [t, n] = _(0);
  return y(() => {
    const r = () => {
      e.current && n(e.current.offsetWidth);
    };
    return r(), window.addEventListener("resize", r), () => window.removeEventListener("resize", r);
  }, [e]), t;
}, br = () => {
  const [e, t] = _(!1);
  return y(() => {
    function n() {
      t(!0);
    }
    return window.addEventListener("resize", n), () => {
      window.removeEventListener("resize", n);
    };
  }, []), y(() => {
    if (e) {
      const n = setTimeout(() => t(!1), 500);
      return () => clearTimeout(n);
    }
  }, [e]), e;
};
var nn = /* @__PURE__ */ ((e) => (e[e.SMALL = 0] = "SMALL", e[e.MEDIUM = 1] = "MEDIUM", e[e.LARGE = 2] = "LARGE", e[e.EXTRA_LARGE = 3] = "EXTRA_LARGE", e))(nn || {});
const vr = (e) => {
  const [t, n] = _(window.innerWidth);
  return y(() => {
    const r = () => n(window.innerWidth);
    return window.addEventListener("resize", r), () => window.removeEventListener("resize", r);
  }, []), e ? t : t <= Y.sm ? 0 : t <= Y.md ? 1 : t <= Y.lg ? 2 : 3;
}, Sr = (e, t, n, r) => {
  switch (e) {
    case 0:
      return t;
    case 1:
      return n;
    case 2:
      return r ?? n;
    default:
      return r ?? n;
  }
}, Er = (e, t) => {
  y(() => {
    const n = (a) => {
      e.current && !e.current.contains(a.target) && t(a);
    }, r = (a) => {
      a.key === "Escape" && t(a);
    };
    return document.addEventListener("mousedown", n), document.addEventListener("keydown", r), () => {
      document.removeEventListener("mousedown", n), document.removeEventListener("keydown", r);
    };
  }, [e, t]);
};
function xr(e, t) {
  const n = Ee();
  y(() => {
    n.current = e;
  }, [e]), y(() => {
    function r() {
      n.current !== void 0 && n.current();
    }
    if (t !== null) {
      const a = setInterval(r, t);
      return () => clearInterval(a);
    }
  }, [t]);
}
function Dr(e, t = 1e3) {
  const n = Ee();
  return y(() => () => {
    n.current && clearTimeout(n.current);
  }, []), (...a) => {
    const s = setTimeout(() => {
      e(...a);
    }, t);
    clearTimeout(n.current), n.current = s;
  };
}
const Cr = (e) => {
  const [t] = _(e), [n, r] = _(null);
  y(() => {
    const s = n ? setTimeout(() => r(null), n.duration) : null;
    return () => {
      s && clearTimeout(s);
    };
  }, [n]);
  const a = Se((s, o) => {
    r({ value: s, duration: o });
  }, []);
  return [(n == null ? void 0 : n.value) ?? t, a];
};
class L extends Error {
}
L.prototype.name = "InvalidTokenError";
function rn(e) {
  return decodeURIComponent(atob(e).replace(/(.)/g, (t, n) => {
    let r = n.charCodeAt(0).toString(16).toUpperCase();
    return r.length < 2 && (r = "0" + r), "%" + r;
  }));
}
function an(e) {
  let t = e.replace(/-/g, "+").replace(/_/g, "/");
  switch (t.length % 4) {
    case 0:
      break;
    case 2:
      t += "==";
      break;
    case 3:
      t += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }
  try {
    return rn(t);
  } catch {
    return atob(t);
  }
}
function sn(e, t) {
  if (typeof e != "string")
    throw new L("Invalid token specified: must be a string");
  t || (t = {});
  const n = t.header === !0 ? 0 : 1, r = e.split(".")[n];
  if (typeof r != "string")
    throw new L(`Invalid token specified: missing part #${n + 1}`);
  let a;
  try {
    a = an(r);
  } catch (s) {
    throw new L(`Invalid token specified: invalid base64 for part #${n + 1} (${s.message})`);
  }
  try {
    return JSON.parse(a);
  } catch (s) {
    throw new L(`Invalid token specified: invalid json for part #${n + 1} (${s.message})`);
  }
}
const fe = 36e6, j = "parafin_token", ee = "parafin_token_expiration", Tr = (e, t) => {
  const n = new URLSearchParams(e.split("?")[1]).get("token") ?? void 0, r = p.local.get(j), a = n ?? r, s = on(n, r), o = s - Date.now(), [i, c] = _(!0), [u, d] = _(!1), [l, f] = _(void 0);
  y(() => {
    const I = setTimeout(h, o);
    return document.addEventListener("visibilitychange", T), a && v(s) ? (a !== r && (t == null || t()), p.local.set(j, a), p.local.set(ee, s), f(`${te(a) ? "Token" : "Bearer"} ${a}`)) : h(), c(!1), () => {
      clearTimeout(I), document.removeEventListener("visibilitychange", T);
    };
  }, []);
  const v = (I) => Date.now() < I, T = () => {
    v(s) || h();
  }, h = () => {
    t == null || t(), p.local.remove(j), p.local.remove(ee), d(!0);
  };
  return { isLoading: i, isExpired: u, data: l };
}, on = (e, t) => {
  if (e)
    return te(e) ? Date.now() + fe : ge(e);
  if (t)
    if (te(t)) {
      const n = p.local.get(ee);
      return n ? +n : Date.now() + fe;
    } else
      return ge(t);
  else
    return 0;
}, ge = (e) => {
  try {
    const { exp: t } = sn(e);
    return t * 1e3;
  } catch {
    return 0;
  }
}, te = (e) => {
  const t = e.replaceAll("-", "");
  return t.length === 32 && Array.from(t).every(
    (n) => "0123456789abcdef".includes(n.toLowerCase())
  );
}, cn = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/wallets/balance",
    method: "post",
    name: "Add Business Wallet Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/wallet_applications/{id}",
    method: "get",
    name: "Get Wallet Application"
  },
  {
    path: "/v1/wallet_applications",
    method: "get",
    name: "List Wallet Applications"
  },
  {
    path: "/v1/wallet_accounts/{id}",
    method: "get",
    name: "Get Wallet Account"
  },
  {
    path: "/v1/wallet_accounts",
    method: "get",
    name: "List Wallet Accounts"
  },
  {
    path: "/v1/wallet_balances/{id}",
    method: "get",
    name: "Get Wallet Balance"
  },
  {
    path: "/v1/wallet_balances",
    method: "get",
    name: "List Wallet Balances"
  },
  {
    path: "/v1/wallet_transactions",
    method: "get",
    name: "List Wallet Transactions"
  },
  {
    path: "/v1/sandbox/wallet_transactions",
    method: "post",
    name: "Create Wallet Transaction"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  }
], ye = (e, t) => {
  const n = Date.now(), r = new Date(n - 1e3 * 60 * 60 * 24 * e);
  return [t < r ? r : t, /* @__PURE__ */ new Date()];
}, G = (e, t) => {
  const n = /* @__PURE__ */ new Date(), r = new Date(
    n.getFullYear(),
    n.getMonth() - e,
    n.getDate()
  );
  return [t < r ? r : t, n];
}, Ir = (e, t) => {
  switch (e) {
    case "Today":
      return ye(0, t);
    case "All time":
      return [new Date(t), /* @__PURE__ */ new Date()];
    case "Past week":
      return ye(7, t);
    case "1 month":
      return G(1, t);
    case "3 months":
      return G(3, t);
    case "1 year":
      return G(12, t);
    case "Custom":
      return [new Date(t), /* @__PURE__ */ new Date()];
  }
}, kr = (e) => new Date(e).toLocaleString("default", { month: "short" }), Nr = (e, t, n) => new Date(e).toLocaleString("default", {
  month: t || "long",
  year: "numeric",
  ...n && { day: "2-digit" }
}), Ar = (e) => {
  const t = e.split("-");
  return new Date(Number(t[0]), Number(t[1]) - 1, 1).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}, Lr = (e) => {
  const t = e.split("-");
  return new Date(Number(t[0]), Number(t[1]), 0).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}, Pr = (e = "", t = "") => {
  const n = cn.find((r) => r.path === t && r.method.toUpperCase() === e.toUpperCase());
  return n ? n.name : "Unknown API";
}, Mr = (e) => e >= 200 && e < 400 ? "accent200" : e >= 400 && e < 500 ? "accent400" : e >= 500 ? "accent500" : "mono400", $r = ["GET", "POST", "PATCH"], Rr = ["2XX", "4XX", "5XX"], Or = [
  "Has failed attempt",
  "No failed attempt"
], Ur = (e) => {
  switch (e) {
    case "normal":
      return "Push";
    case "sync":
      return "Pull";
  }
}, Fr = (e, t) => {
  if (e) {
    const r = new Date(e), a = {
      hour: "numeric",
      minute: "2-digit"
    }, s = new Intl.DateTimeFormat("en-US", a).format(r), o = (/* @__PURE__ */ new Date()).getDate() - r.getDate(), i = o == 0 ? "today" : o == 1 ? "yesterday" : "two days ago";
    var n = (/* @__PURE__ */ new Date()).toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
    return `Last ${t} ${i} at ${s} ${n}.
    `;
  }
  return "Error: Could not determine generated date.";
}, un = (e) => {
  const t = Math.floor(e / 1e3), n = Math.floor(t / 60), r = Math.floor(n / 60), a = n % 60, s = t % 60;
  return dn(r, a, s);
}, dn = (e, t, n) => e > 0 ? `${e}h ${t}m ${n}s` : t > 0 ? `${t}m ${n}s` : n > 0 ? `${n}s` : "", Br = (e, t, n, r) => {
  if (e)
    return r;
  const a = un(n), s = Math.ceil(n / 6e4);
  s == t ? t = Math.ceil(t * 1.25) : s > t && (t += s * 2);
  const o = t - s;
  return o < 5 ? `Elapsed Time: ${a}. A few minutes remaining.` : o < 60 ? `Elapsed Time: ${a}. About ${o} minutes remaining.` : o <= 90 ? `Elapsed Time: ${a}. About 1 hour remaining.` : `Elapsed Time: ${a}. Several hours remaining.`;
}, Wr = (e) => e.length > 48 ? "..." + e.slice(-48) : e, Yr = (e) => e === "Error" ? "red" : e === "Warning" ? "yellow" : "gray", jr = (e) => e.replace(/\./g, "@"), Gr = (e) => e.replace(/@/g, "."), Vr = (e) => {
  switch (e) {
    case "rtp":
      return "Instant";
    case "same_day_ach":
      return "Same business day";
    case "ach":
      return "1–3 business days";
    case "wire":
      return "Wire";
  }
}, zr = (e, t) => {
  switch (e) {
    case "rtp":
      return "May take up to one hour to process";
    case "same_day_ach":
      return "Transfers initiated after 4 PM ET will be sent the next business day";
    case "ach":
      return `Arrives in ${t === "money_out" ? "1–3" : "5–6"} business days`;
    case "wire":
      return "Wires initiated after 5 PM ET will be sent the next business day";
  }
}, Kr = (e) => {
  const t = e.find(
    (n) => n.merchant_bank_account_type === "checking"
  );
  if (!t)
    throw new Error("User does not have a checking account");
  return t;
}, qr = (e, t = { prioritizePending: !0 }) => e.sort((n, r) => {
  if (t.prioritizePending) {
    if (n.state === "pending" && r.state !== "pending")
      return -1;
    if (r.state === "pending" && n.state !== "pending")
      return 1;
  }
  return n.effective_at > r.effective_at ? -1 : r.effective_at > n.effective_at || n.id < r.id ? 1 : -1;
}), ln = (e) => (e.nick_name ? `${e.nick_name} · ` : "") + `${e.bank_name} •••• ${e.account_number_last_four}`, Xr = (e) => "name" in e ? e.name : e.account_nick_name ?? e.account_name, Hr = (e) => "name" in e ? ln(e) : `${e.account_nick_name ? `${e.account_name} ` : ""}•••• ${e.account_number_last_four}`, Jr = (e, t, n) => {
  switch (t) {
    case "rtp":
      return Math.round(e * n.instant_bank_transfer_rate * 100) / 100;
    case "same_day_ach":
      return Math.round(e * n.same_day_bank_transfer_rate * 100) / 100;
    case "wire":
      return n.wire_fee.amount;
    case "ach":
      return;
  }
}, pn = (e, t) => {
  switch (e) {
    case "single_ach_money_out_originated_limit":
      return `Exceeds limit of ${w(
        t.single_ach_money_out_originated_limit
      )} per bank transfer out`;
    case "single_wire_money_out_limit":
      return `Exceeds limit of ${w(
        t.single_wire_money_out_limit
      )} per wire out`;
    case "single_ach_money_in_originated_limit":
      return `Exceeds limit of ${w(
        t.single_ach_money_in_originated_limit
      )} per transfer`;
    case "daily_money_in_left":
      return `Exceeds remaining daily limit of ${w(
        t.daily_money_in_left
      )}`;
    case "daily_money_out_left":
      return `Exceeds remaining daily limit of ${w(
        t.daily_money_out_left
      )} for transfers out`;
    case "weekly_ach_money_in_originated_left":
      return `Exceeds remaining weekly limit of ${w(
        t.weekly_ach_money_in_originated_left
      )}`;
    case "minimum_transfer_amount":
      return `Minimum amount is ${w(
        t.minimum_transfer_amount
      )}`;
    default:
      throw new Error("Unexpected exceeded transfer limit: " + e);
  }
}, Zr = ({
  amount: e,
  limits: t,
  direction: n,
  isWire: r,
  availableBalance: a,
  fee: s
}) => {
  if (n === "money_out") {
    if (e > a)
      return "Amount exceeds available balance";
    if (s !== void 0 && e + s > a)
      return "Amount and fee exceeds available balance";
  }
  const o = Object.keys(t).sort(
    (d, l) => t[d] - t[l]
  ), i = {
    minimum_transfer_amount: !0,
    single_ach_money_in_originated_limit: n === "money_in" && !r,
    single_ach_money_out_originated_limit: n === "money_out" && !r,
    single_wire_money_out_limit: n === "money_out" && r,
    weekly_ach_money_in_originated_left: n === "money_in" && !r,
    weekly_ach_money_in_originated_limit: !1,
    daily_money_in_left: n === "money_in",
    daily_money_in_limit: !1,
    daily_money_out_left: n === "money_out",
    daily_money_out_limit: !1
  }, u = o.filter(
    (d) => i[d]
  ).find((d) => d === "minimum_transfer_amount" ? e < t[d] : e > t[d]);
  return u ? pn(u, t) : !0;
}, Qr = (e, t) => {
  var n, r, a, s;
  switch (e.transaction_type) {
    case "card_transaction":
      return ((n = e.card_transaction_details) == null ? void 0 : n.card_acceptor_details.card_acceptor_name) ?? "Card transaction";
    case "external_bank_transfer":
      return e.external_transfer_payment_type === "wire" ? "Wire transfer" : "Bank transfer";
    case "recipient_transfer": {
      const i = e.external_transfer_payment_type === "wire" ? "Wire transfer" : "Bank transfer", c = e.recipient_transfer_details ? ` to ${e.recipient_transfer_details.recipient_nickname ?? ((r = e.recipient_transfer_details) == null ? void 0 : r.recipient_name)}` : "";
      return i + c;
    }
    case "received_transfer":
      return ((a = e.received_transfer_details) == null ? void 0 : a.transfer_type) === "partner_payout" ? `${t.name} payout` : (s = e.received_transfer_details) != null && s.originator_name ? e.received_transfer_details.originator_name : e.external_transfer_payment_type === "wire" ? "Wire transfer" : "Bank transfer";
    case "repayment":
      return "Automatic credit repayment";
    case "inner_transfer":
    case "transfer_return":
    case "transfer_reversal":
      throw new Error("not implemented yet");
    default:
      const o = e.transaction_type;
      throw new Error(`Unexpected transaction type: ${o}`);
  }
}, mn = (e) => {
  var t, n, r, a, s;
  switch (e.transaction_type) {
    case "card_transaction": {
      const i = e.card_transaction_details;
      if (!i)
        throw new Error(
          "Card transaction details are required for card transactions"
        );
      const c = (t = i.cash_credit_split) == null ? void 0 : t.cash_spent, u = (n = i.cash_credit_split) == null ? void 0 : n.credit_spent;
      return [
        ...c ? [{ label: "Cash spent", value: w(c) }] : [],
        ...u ? [{ label: "Credit spent", value: w(u) }] : [],
        {
          label: "Card used",
          value: `${Zt(
            i.card_details.card_type
          )} •••• ${i.card_details.card_last_four}`
        }
        // TODO: purchase date label/value
      ];
    }
    case "external_bank_transfer": {
      const i = e.external_account_transfer_details;
      if (!i)
        throw new Error(
          `Missing external account details in activity. Expected external account ${(r = e.destination_account) == null ? void 0 : r.account_id} transfer details.`
        );
      return [
        {
          label: e.direction === "money_out" ? "To" : "From",
          value: `${i.external_account_bank_name} •••• ${i.external_account_account_number_last_four}`
        },
        ...V(e),
        // TODO: arrive by label/value
        ...we(e)
      ];
    }
    case "received_transfer":
      return V(e);
    case "recipient_transfer": {
      const i = e.recipient_transfer_details;
      if (!i)
        throw new Error(
          `Missing recipient details in activity. Expected recipient ${(a = e.destination_account) == null ? void 0 : a.account_id} transfer details.`
        );
      return [
        {
          label: "To",
          value: `${i.recipient_bank_name} •••• ${i.recipient_account_number_last_four}`
        },
        ...V(e),
        ...we(e)
      ];
    }
    case "repayment": {
      const i = e.repayment_details;
      if (!i)
        throw new Error("Missing repayment details in activity.");
      return (s = e.repayment_details) == null || s.repayment_type, [
        ...i.repayment_rate !== void 0 && i.payout_amount !== void 0 ? [
          {
            label: "Repayment rate",
            value: `${Vt(
              i.repayment_rate,
              0
            )} of ${w(i.payout_amount)}`
          }
        ] : []
      ];
    }
    case "inner_transfer":
    case "transfer_return":
    case "transfer_reversal":
      throw new Error("not implemented yet");
    default:
      const o = e.transaction_type;
      throw new Error(`Unexpected transaction type: ${o}`);
  }
}, ea = (e) => {
  switch (e.transaction_type) {
    case "card_transaction":
      return "card";
    case "repayment":
      return "dollarCircle";
    case "external_bank_transfer":
    case "recipient_transfer":
    case "received_transfer":
      return "bank";
    case "inner_transfer":
    case "transfer_return":
    case "transfer_reversal":
      throw new Error("not implemented yet");
    default:
      const t = e.transaction_type;
      throw new Error(`Unexpected transaction type: ${t}`);
  }
}, ta = (e, t) => ({
  lineItems: [
    ...mn(e),
    {
      label: "Transaction ID",
      value: "#" + e.reference_id
    }
  ],
  action: t
}), hn = (e) => {
  var n;
  const t = (n = e.card_transaction_details) == null ? void 0 : n.dispute_state;
  if (t)
    switch (t) {
      case "user_submitted":
      case "parafin_pending":
      case "parafin_submitted":
      case "marqeta_chargeback_lost":
      case "marqeta_rejected":
        return "disputed";
      case "parafin_rejected":
      case "parafin_not_credited":
        return "dispute_lost";
      case "marqeta_chargeback_won":
      case "parafin_credited":
        return "dispute_won";
    }
  return e.state === "succeeded" ? void 0 : e.state;
}, na = (e) => {
  const t = e.amount + (e.fee_amount ?? 0);
  return {
    amount: e.direction === "money_in" ? t : -t,
    state: hn(e),
    date: e.effective_at
  };
}, V = (e) => e.memo ? [
  {
    label: "Memo",
    value: e.memo
  }
] : [], we = (e) => e.fee_amount ? [
  {
    label: `${e.external_transfer_payment_type === "rtp" ? "Instant transfer" : "Same-day transfer"} fee`,
    value: w(e.fee_amount)
  }
] : [], _n = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
  // Canadian Provinces and Territories
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  Yukon: "YT"
}, be = (e) => {
  const t = Object.entries(_n).reduce(
    (a, [s, o]) => (a[s.toLowerCase()] = o, a[o.toLowerCase()] = o, a),
    {}
  ), n = e.toLowerCase(), r = t[n];
  if (!r)
    throw new Error(`Invalid state abbreviation or name: ${e}`);
  return r;
}, ra = (e) => {
  switch (e) {
    case "card":
      return "card";
    case "flex_loan":
    case "term_loan":
      return "loan";
    case "merchant_bank":
      return "wallet";
    case "cash_advance":
      return "advance";
  }
}, fn = (e) => {
  var r, a, s, o, i, c, u, d, l, f, v, T, h;
  const t = ((r = e.business) == null ? void 0 : r.address) || e.address, n = (a = e.owner) == null ? void 0 : a.address;
  return {
    legal_business_name: ((s = e.business) == null ? void 0 : s.legalName) || e.legalBusinessName || e.businessName,
    dba_name: ((o = e.business) == null ? void 0 : o.dbaName) || e.dbaName || e.businessName,
    owner_first_name: ((i = e.owner) == null ? void 0 : i.firstName) || e.ownerFirstName,
    owner_last_name: ((c = e.owner) == null ? void 0 : c.lastName) || e.ownerLastName,
    account_managers: e.accountManagers,
    routing_number: ((u = e.bank) == null ? void 0 : u.routingNumber) || e.routingNumber,
    account_number_last_four: ((d = e.bank) == null ? void 0 : d.accountNumberLastFour) || e.accountNumberLastFour,
    email: ((l = e.owner) == null ? void 0 : l.email) || e.email,
    owner_phone: ((f = e.owner) == null ? void 0 : f.phoneNumber) || e.phoneNumber || e.ownerPhone,
    owner_dob: ((v = e.owner) == null ? void 0 : v.dateOfBirth) || e.ownerDob || null,
    business_established: ((T = e.business) == null ? void 0 : T.dateEstablished) || e.businessEstablished || null,
    incorporation_type: e.incorporationType || null,
    incorporation_state: e.incorporationState || null,
    postal_code: e.postalCode,
    bank_account_currency_code: ((h = e.bank) == null ? void 0 : h.currencyCode) || e.bankAccountCurrencyCode,
    ...!!t && {
      address: {
        address_line1: t.addressLine1,
        address_line2: t.addressLine2,
        city: t.city,
        country: t.country,
        postal_code: t.postalCode,
        state: be(t.state)
      }
    },
    ...!!n && {
      owner_address: {
        address_line1: n.addressLine1,
        address_line2: n.addressLine2,
        city: n.city,
        country: n.country,
        postal_code: n.postalCode,
        state: be(n.state)
      }
    },
    opt_in_edit_source: "partner_datashare"
  };
}, aa = (e, t) => ({
  business_external_id: e,
  ...fn(t)
}), z = "mfa_user_session_id", K = "mfa_user_session_expiration", q = "mfa_session_type", ve = "mfa_has_had_valid_session", sa = () => {
  const [e, t] = _(!0), [n, r] = _(
    p.local.get(z)
  ), [a, s] = _(
    Number(p.local.get(K)) || 0
  ), [o, i] = _(
    p.local.get(q)
  ), [c, u] = _(
    p.session.get(ve) === "true"
  ), d = a - Date.now();
  y(() => {
    const h = setTimeout(v, d);
    return document.addEventListener("visibilitychange", f), t(!1), () => {
      clearTimeout(h), document.removeEventListener("visibilitychange", f);
    };
  }, [d]);
  const l = () => Date.now() < a, f = () => {
    l() || v();
  }, v = () => {
    p.local.remove(z), p.local.remove(K), p.local.remove(q), r(void 0), s(0), i(void 0);
  };
  return {
    isLoading: e,
    mfaSessionId: n,
    mfaSessionType: o,
    hasHadValidSession: c,
    expireMfaSession: v,
    storeMfaSession: (h) => {
      const I = new Date(h.expires_at).getTime();
      p.local.set(z, h.id), p.local.set(K, I), p.local.set(q, h.session_type), p.session.set(ve, "true"), r(h.id), s(I), i(h.session_type), u(!0);
    },
    isMfaSessionValid: l
  };
}, Pe = (e) => ({
  results: (e == null ? void 0 : e.results) || [],
  previous: (e == null ? void 0 : e.previous) || "",
  next: (e == null ? void 0 : e.next) || ""
}), gn = ({ type: e, query: t }) => {
  var n, r, a, s, o, i;
  switch (e) {
    case "one":
      return (r = (n = t.data) == null ? void 0 : n.data) == null ? void 0 : r.results[0];
    case "many":
      return (s = (a = t.data) == null ? void 0 : a.data) == null ? void 0 : s.results;
    case "paginated":
      return Pe((o = t.data) == null ? void 0 : o.data);
    default:
      return (i = t.data) == null ? void 0 : i.data;
  }
};
function oa({ type: e, query: t }) {
  const n = gn({ type: e, query: t });
  return {
    ...t,
    data: n,
    isInvalid: t.isError || n === void 0
  };
}
const yn = (e, t) => {
  var n, r, a, s, o, i, c;
  switch (e) {
    case "one-defined":
    case "one-opt":
      return (a = (r = (n = t.data) == null ? void 0 : n.data) == null ? void 0 : r.results) == null ? void 0 : a[0];
    case "many":
      return (o = (s = t.data) == null ? void 0 : s.data) == null ? void 0 : o.results;
    case "paginated":
      return Pe((i = t.data) == null ? void 0 : i.data);
    default:
      return (c = t.data) == null ? void 0 : c.data;
  }
};
function ia({
  type: e,
  query: t
}) {
  const n = yn(e, t);
  if (t.error && !t.isFetching)
    throw t.error;
  return {
    ...t,
    data: n
  };
}
export {
  j as STORED_TOKEN_KEY,
  nn as ScreenSize,
  Or as WebhookDeliveryStatuses,
  $r as apiLogMethods,
  aa as buildOptInRequest,
  Jr as calculateTransferFee,
  Zt as capitalize,
  _e as dateToLocalDate,
  Gr as decodeWebhookEvent,
  Gn as displayNumber,
  jr as encodeWebhookEvent,
  Xn as firstNumberStartsWithSecondNumber,
  Sr as forScreenSize,
  Fr as formatCreatedAt,
  w as formatCurrency,
  U as formatDate,
  Kt as formatDateRange,
  Nn as formatDateString,
  bn as formatNumber,
  Vt as formatPercentage,
  vn as formatPhoneNumber,
  oa as get,
  ta as getActivityDetails,
  ea as getActivityIcon,
  mn as getActivityLineItems,
  Qr as getActivityTitle,
  Wn as getAllPaybackPeriods,
  Pr as getApiName,
  Yr as getBadgeColorVariant,
  Kr as getCheckingAccount,
  Rn as getChunk,
  dr as getChunkFromId,
  Zn as getCurrentOnboardingProduct,
  se as getDateInLocalTimezone,
  Ir as getDatesFromRange,
  zn as getDaysSince,
  Hr as getDestinationAccountSubtitle,
  Xr as getDestinationAccountTitle,
  Jn as getDeviceTypeFromUserAgent,
  sr as getDisbursementDataType,
  hr as getDurationDisplay,
  Ht as getEarliestDate,
  tn as getFee,
  we as getFeeLineItem,
  Ar as getFirstOfMonth,
  er as getFixedTermRepaymentRate,
  Wr as getFormattedName,
  An as getFormattedTime,
  On as getFutureDate,
  Br as getGenerationPrompt,
  Lr as getLastOfMonth,
  Mn as getMaxOffer,
  qn as getMaxOfferChunk,
  V as getMemoLineItem,
  zr as getMethodDetails,
  Vr as getMethodTitle,
  $n as getMinOffer,
  zt as getMonthRangeBetween,
  kr as getMonthShort,
  or as getMostRecentOutstandingCapitalProduct,
  mr as getName,
  Ln as getOrdinal,
  pr as getPaybackPeriodFromChunkId,
  Yn as getPossiblePaybackPeriods,
  lr as getProductCopy,
  ln as getRecipientDetail,
  en as getRepaymentSchedule,
  Qn as getSalesBasedRepaymentRate,
  Vn as getSalesDateOrDates,
  kn as getSessionId,
  ra as getShortNameForProduct,
  Bn as getStatementDateRanges,
  Mr as getStatusColor,
  ia as getSuspense,
  tr as getTermLoanPaymentAmount,
  Nr as getTooltipDate,
  na as getWalletActivityDefaultProps,
  Ur as getWebhookModeDisplayName,
  rr as groupSortedActivitiesByMonthYear,
  nr as groupSortedActivitiesByYear,
  Hn as handlePDF,
  Pn as hexToRGBA,
  Un as inIframe,
  fr as incorporationTypeDisplays,
  ar as isCapitalProduct,
  Dn as isDateWithinLastXDays,
  Cn as isDateWithinPastYearToDate,
  Tn as isDateWithinYears,
  ir as isMatchingSlug,
  _r as isRutterConnectionSuccessful,
  xn as localDateToDate,
  Fn as logAxiosErrorToSentry,
  In as modifyDate,
  Kn as noisy,
  En as obfuscateEmail,
  Sn as obfuscatePhoneNumber,
  jn as pluralizeString,
  Qt as roundRateToInteger,
  Y as screens,
  qr as sortTransactions,
  Rr as statusCodes,
  p as storage,
  cr as toSentenceCase,
  ur as toSnakeCase,
  Dr as useDebounce,
  wr as useDivWidth,
  Tr as useHandleAuth,
  sa as useHandleMfa,
  xr as useInterval,
  br as useIsResizing,
  Er as useOutsideClick,
  vr as useScreenSize,
  gr as useScrollPosition,
  yr as useSocureDeviceSession,
  Cr as useTransientState,
  Zr as validateTransferAmount
};
