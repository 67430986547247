import styled from 'styled-components'
import useTheme from '@/src/ui/theme'
import { Typography } from '@/src/ui/components/Typography'
import { useState, useContext } from 'react'
import { Location, useLocation } from 'react-router-dom'
import { Link } from '@/src/ui/components/Link'

// This Tab is used in the Sidebar so it needs to know
// whether the sidebar is being hovered or not
import { SidebarContext } from '../../components/frame/Sidebar'
import { Icon, IconType } from './Icon'

export type TabProps = {
  label: string
  icon?: IconType
  route: string
  exactRoute?: boolean
  subTabs?: TabProps[]
  indent?: number
}

export const Tab = ({
  label,
  icon,
  route,
  exactRoute,
  subTabs,
  indent,
}: TabProps) => {
  const { theme } = useTheme()
  const { hovered } = useContext(SidebarContext)
  const location = useLocation()
  const routeMatched = routeMatch(route, location, exactRoute)
  const subTabMatch = subTabs
    ? subTabs.reduce((prev, cur) => {
        return prev ? prev : routeMatch(cur.route, location, cur.exactRoute)
      }, false)
    : false
  const [highlighted, setHighlighted] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <TabAndSubtabsWrapper numItems={1 + (subTabs ? subTabs.length : 0)}>
      <TabLink
        id={`sidebar-${label}`}
        unstyled={true}
        to={route}
        indent={indent}
        $routeMatched={routeMatched}
        onMouseEnter={() => {
          setHighlighted(true)
        }}
        onMouseLeave={() => {
          setHighlighted(false)
        }}
        $chevron={subTabs !== undefined && subTabs.length > 0}
      >
        {icon && (
          <IconWrapper>
            <Icon
              type={icon}
              color={routeMatched || highlighted ? 'black100' : 'black80'}
              size="18px"
            />
          </IconWrapper>
        )}
        <TabTitle
          variant="title"
          data-testid={`link-${label}`}
          hovered={hovered}
          color={routeMatched || highlighted ? 'black' : 'mono800'}
        >
          {label}
        </TabTitle>
        {subTabs && (
          <ChevronWrapper
            data-testid={`chevron-${label}`}
            onClick={(e) => {
              e.preventDefault()
              if (!subTabMatch) {
                setIsExpanded(!isExpanded)
              }
            }}
            open={isExpanded}
          >
            <Icon
              type="downChevron"
              size="14px"
              color={routeMatched || highlighted ? 'black100' : 'black80'}
            />
          </ChevronWrapper>
        )}
      </TabLink>
      {subTabs && (
        <ChildTabsContainer open={isExpanded} numItems={subTabs.length}>
          {subTabs.map((subTab) => (
            <Tab
              key={subTab.route}
              {...subTab}
              indent={indent ? indent + 1 : 1}
            />
          ))}
        </ChildTabsContainer>
      )}
    </TabAndSubtabsWrapper>
  )
}

const routeMatch = (
  route: string,
  location: Location,
  exactRoute: boolean = false
) =>
  (!exactRoute && location.pathname.includes(route)) ||
  (exactRoute && location.pathname === route)

const IconWrapper = styled.div`
  flex-shrink: 0;
`

export const TabTitle = styled(Typography)<{ hovered: boolean }>`
  white-space: nowrap;
  width: 160px;
  flex-grow: 2;
  transition-property: opacity, color;
  transition-duration: 0.4s;
  @media (max-width: 1208px) {
    opacity: ${({ hovered }) => (hovered ? 1 : 0)};
  }
`

export const TabLink = styled(Link)<{
  $routeMatched: boolean
  indent?: number
  $chevron?: boolean
}>`
  ${({ $routeMatched, theme }) =>
    $routeMatched && `background-color: ${theme.colors.black30};`}
  padding-left: ${({ indent }) => (indent ? `${indent * 34 + 22}px` : '22px')};
  padding-right: ${({ $chevron }) => ($chevron ? '8px' : '22px')};
  height: 56px;
  display: flex;
  gap: 16px;
  align-items: center;
`

const ChevronWrapper = styled.div<{ open: boolean }>`
  height: 100%;
  padding: 0px 11px;
  justify-content: center;
  justify-self: end;
  display: flex;
  align-items: center;
  ${({ open }) => open && 'transform: rotate(180deg);'}
  transition: 0.3s;
`

const ChildTabsContainer = styled.div<{ open: boolean; numItems: number }>`
  display: flex;
  flex-direction: column;
  max-height: ${({ numItems, open }) => `${open ? numItems * 56 : 0}px`};
  height: auto;
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
`

const TabAndSubtabsWrapper = styled.div<{ numItems: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
