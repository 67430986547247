import {
  NavigateProps,
  To,
  useSearchParams,
  Navigate as BaseNavigate,
  useNavigate as useBaseNavigate,
  NavigateOptions,
  NavigateFunction,
} from 'react-router-dom'

export const getLinkWithParams = (
  to: To,
  search: URLSearchParams,
  persistParams?: boolean
) => {
  const newSearch = new URLSearchParams()
  search.forEach((value, key) => {
    if (key === 'sandbox_mode' || key === 'partner_id') {
      newSearch.append(key, value)
    }
  })
  const newTo = persistParams
    ? to
    : (() => {
        //Strips old to of all query params since it can be either String or Partial<Path>
        if (typeof to === 'string') {
          const indexOfQuestionMark = to.indexOf('?')
          return indexOfQuestionMark === -1
            ? to
            : to.substring(0, indexOfQuestionMark)
        } else {
          const { search, ...rest } = to
          return { ...rest, search: '' }
        }
      })()

  const linkTo = (() => {
    if (typeof newTo === 'string') {
      if (newSearch.size > 0) {
        return `${newTo}?${newSearch.toString()}`
      }
      return newTo
    }
    return { ...newTo, search: newSearch.toString() }
  })()

  return linkTo
}

export const Navigate = ({ to, ...props }: NavigateProps) => {
  const [search] = useSearchParams()
  const linkTo = getLinkWithParams(to, search)
  return <BaseNavigate to={linkTo} {...props} />
}

export const useNavigate = (persistParams?: boolean): NavigateFunction => {
  const navigate = useBaseNavigate()
  const [search] = useSearchParams()

  const wrappedNavigate: NavigateFunction = (
    arg1: To | number,
    arg2?: NavigateOptions
  ) => {
    if (typeof arg1 === 'number') {
      navigate(arg1)
    } else {
      const linkTo = getLinkWithParams(arg1, search, persistParams)
      navigate(linkTo, arg2)
    }
  }
  return wrappedNavigate
}
