import { useQuery as i, useMutation as c } from "@tanstack/react-query";
import o from "axios";
const B_ = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, F_ = {
  normal: "normal",
  sync: "sync"
}, E_ = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, v_ = {
  enabled: "enabled",
  disabled: "disabled"
}, C_ = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, U_ = {
  active: "active",
  inactive: "inactive"
}, N_ = {
  read: "read",
  unread: "unread"
}, D_ = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, A_ = {
  Error: "Error",
  Warning: "Warning"
}, S_ = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, G_ = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, I_ = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, T_ = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, w_ = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, Q_ = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, L_ = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, W_ = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, z_ = {
  pending_response: "pending_response",
  completed: "completed"
}, $_ = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, V_ = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, H_ = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, Y_ = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal"
}, j_ = {
  payments: "payments",
  accounting: "accounting"
}, X_ = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, J_ = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, Z_ = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, tp = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, ep = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, np = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, rp = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, sp = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, ap = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, op = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, up = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, ip = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, cp = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, yp = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, dp = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, _p = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, pp = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, lp = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added"
}, mp = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, gp = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, qp = {
  void_cash_advance: "void_cash_advance"
}, fp = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  amazon_complete: "amazon_complete",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification",
  start_application: "start_application"
}, hp = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  merchant_bank: "merchant_bank"
}, Op = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  amazon_complete: "amazon_complete",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, Kp = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, bp = {
  minimum: "minimum",
  sales_based: "sales_based"
}, Pp = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, kp = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, Mp = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment"
}, xp = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, Rp = {
  money_in: "money_in",
  money_out: "money_out"
}, Bp = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, Fp = {
  manual: "manual",
  payout: "payout"
}, Ep = {
  electrical: "electrical",
  wire: "wire"
}, vp = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, Cp = {
  credit_limit: "credit_limit"
}, Up = {
  checking: "checking",
  savings: "savings"
}, Np = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, Dp = {
  physical: "physical",
  virtual: "virtual"
}, Ap = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Sp = {
  cash: "cash",
  credit: "credit"
}, Gp = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Ip = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, Tp = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, wp = {
  checking: "checking",
  savings: "savings"
}, Qp = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Lp = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, Wp = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, zp = {
  ucc: "ucc",
  federal: "federal",
  state: "state"
}, $p = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, Vp = {
  name: "name",
  address: "address",
  watchlist: "watchlist",
  ein: "ein",
  id: "id",
  date_of_birth: "date_of_birth",
  date_of_birth_miskey: "date_of_birth_miskey",
  ssn_miskey: "ssn_miskey",
  other_warning: "other_warning"
}, Hp = {
  alloy: "alloy",
  marqeta: "marqeta",
  middesk: "middesk",
  persona: "persona",
  sandbox: "sandbox",
  unplugged: "unplugged"
}, Yp = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, jp = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, Xp = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, Jp = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, Zp = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, tl = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, el = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, nl = {
  manual: "manual",
  rutter: "rutter"
}, rl = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean"
}, sl = {
  business: "business",
  account: "account"
}, al = {
  active: "active",
  inactive: "inactive"
}, ol = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, ul = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record"
}, il = {
  apply: "apply",
  lift: "lift"
}, cl = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location",
  no_amazon_offer: "no_amazon_offer"
}, yl = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, dl = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, _l = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, pl = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, ll = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, ml = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, gl = {
  offer_summary: "offer_summary"
}, ql = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, fl = {
  US: "US",
  CA: "CA"
}, hl = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Ol = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Kl = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, bl = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Pl = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, kl = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Ml = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, xl = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Rl = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Bl = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Fl = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, El = {
  physical: "physical",
  virtual: "virtual"
}, vl = {
  physical: "physical",
  virtual: "virtual"
}, Cl = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Ul = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Nl = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, Dl = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Al = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, Sl = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Gl = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, Il = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Tl = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, wl = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, Ql = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, Ll = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, Wl = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  merchant_bank: "merchant_bank"
}, zl = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, $l = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  "": ""
}, Vl = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, Hl = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, d = (t, e) => o.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _ = (t) => ["/business_cores", ...t ? [t] : []], p = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function Yl(t, e) {
  const n = p(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const l = (t, e) => o.post(
  "/business_cores",
  t,
  e
), m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return l(a, n);
  }, ...e };
}, jl = (t) => {
  const e = m(t);
  return c(e);
}, g = (t, e) => o.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q = (t) => ["/business_annotations", ...t ? [t] : []], f = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q(t), queryFn: ({ signal: u }) => g(t, { signal: u, ...r }), ...n };
};
function Xl(t, e) {
  const n = f(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const h = (t, e) => o.post(
  "/business_annotations",
  t,
  e
), O = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return h(a, n);
  }, ...e };
}, Jl = (t) => {
  const e = O(t);
  return c(e);
}, K = (t, e) => o.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], P = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b(t), queryFn: ({ signal: u }) => K(t, { signal: u, ...r }), ...n };
};
function Zl(t, e) {
  const n = P(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const k = (t, e) => o.post(
  "/cash_advance_charge_offs",
  t,
  e
), M = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return k(a, n);
  }, ...e };
}, tm = (t) => {
  const e = M(t);
  return c(e);
}, x = (t, e) => o.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), R = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], B = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? R(t), queryFn: ({ signal: u }) => x(t, { signal: u, ...r }), ...n };
};
function em(t, e) {
  const n = B(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const F = (t, e) => o.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), E = (t) => ["/cash_advance_pauses", ...t ? [t] : []], v = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? E(t), queryFn: ({ signal: u }) => F(t, { signal: u, ...r }), ...n };
};
function nm(t, e) {
  const n = v(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const C = (t, e) => o.post(
  "/cash_advance_pauses",
  t,
  e
), U = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return C(a, n);
  }, ...e };
}, rm = (t) => {
  const e = U(t);
  return c(e);
}, N = (t, e) => o.delete(
  `/cash_advance_pauses/${t}`,
  e
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a } = s ?? {};
    return N(a, n);
  }, ...e };
}, sm = (t) => {
  const e = D(t);
  return c(e);
}, A = (t, e, n) => o.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), S = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a, data: u } = s ?? {};
    return A(a, u, n);
  }, ...e };
}, am = (t) => {
  const e = S(t);
  return c(e);
}, G = (t, e) => o.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), I = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], T = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? I(t), queryFn: ({ signal: u }) => G(t, { signal: u, ...r }), ...n };
};
function om(t, e) {
  const n = T(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const w = (t, e) => o.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q = (t) => ["/future_activities", ...t ? [t] : []], L = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q(t), queryFn: ({ signal: u }) => w(t, { signal: u, ...r }), ...n };
};
function um(t, e) {
  const n = L(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const W = (t, e) => o.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), z = (t) => ["/partners", ...t ? [t] : []], $ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? z(t), queryFn: ({ signal: u }) => W(t, { signal: u, ...r }), ...n };
};
function im(t, e) {
  const n = $(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const V = (t, e) => o.post(
  "/partners",
  t,
  e
), H = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return V(a, n);
  }, ...e };
}, cm = (t) => {
  const e = H(t);
  return c(e);
}, Y = (t) => o.get(
  "/partners/underwriting",
  t
), j = () => ["/partners/underwriting"], X = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? j(), queryFn: ({ signal: a }) => Y({ signal: a, ...n }), ...e };
};
function ym(t) {
  const e = X(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const J = (t, e, n) => o.patch(
  `/partners/${t}`,
  e,
  n
), Z = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return J(a, u, n);
  }, ...e };
}, dm = (t) => {
  const e = Z(t);
  return c(e);
}, tt = (t, e) => o.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), et = (t) => ["/partner/api_keys", ...t ? [t] : []], nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? et(t), queryFn: ({ signal: u }) => tt(t, { signal: u, ...r }), ...n };
};
function _m(t, e) {
  const n = nt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rt = (t, e) => o.post(
  "/partner/api_keys",
  t,
  e
), st = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rt(a, n);
  }, ...e };
}, pm = (t) => {
  const e = st(t);
  return c(e);
}, at = (t) => o.get(
  "/partner/organizations",
  t
), ot = () => ["/partner/organizations"], ut = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ot(), queryFn: ({ signal: a }) => at({ signal: a, ...n }), ...e };
};
function lm(t) {
  const e = ut(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const it = (t, e) => o.post(
  "/partner/organizations",
  t,
  e
), ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return it(a, n);
  }, ...e };
}, mm = (t) => {
  const e = ct(t);
  return c(e);
}, yt = (t, e) => o.post(
  "/partner/organizations/link_partner",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yt(a, n);
  }, ...e };
}, gm = (t) => {
  const e = dt(t);
  return c(e);
}, _t = (t, e) => o.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pt = (t) => ["/partner/organizations/partners", ...t ? [t] : []], lt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pt(t), queryFn: ({ signal: u }) => _t(t, { signal: u, ...r }), ...n };
};
function qm(t, e) {
  const n = lt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const mt = (t, e) => o.post(
  "/slack/authorize",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mt(a, n);
  }, ...e };
}, fm = (t) => {
  const e = gt(t);
  return c(e);
}, qt = (t) => o.get(
  "/slack/channels",
  t
), ft = () => ["/slack/channels"], ht = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ft(), queryFn: ({ signal: a }) => qt({ signal: a, ...n }), ...e };
};
function hm(t) {
  const e = ht(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ot = (t, e) => o.delete(
  `/slack/channels/${t}`,
  e
), Kt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Ot(a, n);
  }, ...e };
}, Om = (t) => {
  const e = Kt(t);
  return c(e);
}, bt = (t) => o.get(
  "/partner/users",
  t
), Pt = () => ["/partner/users"], kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Pt(), queryFn: ({ signal: a }) => bt({ signal: a, ...n }), ...e };
};
function Km(t) {
  const e = kt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Mt = (t, e) => o.post(
  "/partner/users",
  t,
  e
), xt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Mt(a, n);
  }, ...e };
}, bm = (t) => {
  const e = xt(t);
  return c(e);
}, Rt = (t, e) => o.patch(
  "/partner/users",
  t,
  e
), Bt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rt(a, n);
  }, ...e };
}, Pm = (t) => {
  const e = Bt(t);
  return c(e);
}, Ft = (t, e) => o.delete(
  "/partner/delete_users",
  { data: t, ...e }
), Et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ft(a, n);
  }, ...e };
}, km = (t) => {
  const e = Et(t);
  return c(e);
}, vt = (t, e) => o.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ct = (t) => ["/partner/public_configs", ...t ? [t] : []], Ut = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ct(t), queryFn: ({ signal: u }) => vt(t, { signal: u, ...r }), ...n };
};
function Mm(t, e) {
  const n = Ut(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Nt = (t, e) => o.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dt = (t) => ["/partner/configs", ...t ? [t] : []], At = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dt(t), queryFn: ({ signal: u }) => Nt(t, { signal: u, ...r }), ...n };
};
function xm(t, e) {
  const n = At(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const St = (t, e, n) => o.patch(
  `/partner/configs/${t}`,
  e,
  n
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return St(a, u, n);
  }, ...e };
}, Rm = (t) => {
  const e = Gt(t);
  return c(e);
}, It = (t) => o.get(
  "/partner/sync_webhooks",
  t
), Tt = () => ["/partner/sync_webhooks"], wt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Tt(), queryFn: ({ signal: a }) => It({ signal: a, ...n }), ...e };
};
function Bm(t) {
  const e = wt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Qt = (t, e) => o.post(
  "/partner/sync_webhooks",
  t,
  e
), Lt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qt(a, n);
  }, ...e };
}, Fm = (t) => {
  const e = Lt(t);
  return c(e);
}, Wt = (t, e) => o.delete(
  `/partner/sync_webhooks/${t}`,
  e
), zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Wt(a, n);
  }, ...e };
}, Em = (t) => {
  const e = zt(t);
  return c(e);
}, $t = (t, e, n) => o.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), Vt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return $t(a, u, n);
  }, ...e };
}, vm = (t) => {
  const e = Vt(t);
  return c(e);
}, Ht = (t, e) => o.post(
  "/auth/tokens",
  t,
  e
), Yt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ht(a, n);
  }, ...e };
}, Cm = (t) => {
  const e = Yt(t);
  return c(e);
}, jt = (t, e) => o.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xt = (t) => ["/statements_v2", ...t ? [t] : []], Jt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xt(t), queryFn: ({ signal: u }) => jt(t, { signal: u, ...r }), ...n };
};
function Um(t, e) {
  const n = Jt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zt = (t, e) => o.post(
  "/marketing/review",
  t,
  e
), te = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zt(a, n);
  }, ...e };
}, Nm = (t) => {
  const e = te(t);
  return c(e);
}, ee = (t) => o.get(
  "/marketing/integrations",
  t
), ne = () => ["/marketing/integrations"], re = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ne(), queryFn: ({ signal: a }) => ee({ signal: a, ...n }), ...e };
};
function Dm(t) {
  const e = re(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const se = (t, e) => o.post(
  "/marketing/integrations",
  t,
  e
), ae = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return se(a, n);
  }, ...e };
}, Am = (t) => {
  const e = ae(t);
  return c(e);
}, oe = (t, e) => o.delete(
  `/marketing/integrations/${t}`,
  e
), ue = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: a } = s ?? {};
    return oe(a, n);
  }, ...e };
}, Sm = (t) => {
  const e = ue(t);
  return c(e);
}, ie = (t) => o.get(
  "/marketing/gtm_toolkit",
  t
), ce = () => ["/marketing/gtm_toolkit"], ye = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ce(), queryFn: ({ signal: a }) => ie({ signal: a, ...n }), ...e };
};
function Gm(t) {
  const e = ye(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const de = (t) => o.get(
  "/marketing/paragon_token",
  t
), _e = () => ["/marketing/paragon_token"], pe = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _e(), queryFn: ({ signal: a }) => de({ signal: a, ...n }), ...e };
};
function Im(t) {
  const e = pe(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const le = (t, e) => o.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), me = (t) => ["/beneficial_owners", ...t ? [t] : []], ge = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? me(t), queryFn: ({ signal: u }) => le(t, { signal: u, ...r }), ...n };
};
function Tm(t, e) {
  const n = ge(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qe = (t, e) => o.post(
  "/beneficial_owners",
  t,
  e
), fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qe(a, n);
  }, ...e };
}, wm = (t) => {
  const e = fe(t);
  return c(e);
}, he = (t, e) => o.delete(
  `/beneficial_owners/${t}`,
  e
), Oe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return he(a, n);
  }, ...e };
}, Qm = (t) => {
  const e = Oe(t);
  return c(e);
}, Ke = (t, e, n) => o.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ke(a, u, n);
  }, ...e };
}, Lm = (t) => {
  const e = be(t);
  return c(e);
}, Pe = (t, e) => o.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ke = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], Me = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ke(t), queryFn: ({ signal: u }) => Pe(t, { signal: u, ...r }), ...n };
};
function Wm(t, e) {
  const n = Me(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xe = (t, e) => o.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Re = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xe(a, n);
  }, ...e };
}, zm = (t) => {
  const e = Re(t);
  return c(e);
}, Be = (t, e) => o.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fe = (t) => ["/banks", ...t ? [t] : []], Ee = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fe(t), queryFn: ({ signal: u }) => Be(t, { signal: u, ...r }), ...n };
};
function $m(t, e) {
  const n = Ee(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ve = (t, e) => o.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ce = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], Ue = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ce(t), queryFn: ({ signal: u }) => ve(t, { signal: u, ...r }), ...n };
};
function Vm(t, e) {
  const n = Ue(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ne = (t, e) => o.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), De = (t) => ["/business_table_rows/mca", ...t ? [t] : []], Ae = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? De(t), queryFn: ({ signal: u }) => Ne(t, { signal: u, ...r }), ...n };
};
function Hm(t, e) {
  const n = Ae(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Se = (t, e) => o.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ge = (t) => ["/business_table_rows/loan", ...t ? [t] : []], Ie = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ge(t), queryFn: ({ signal: u }) => Se(t, { signal: u, ...r }), ...n };
};
function Ym(t, e) {
  const n = Ie(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Te = (t, e) => o.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), we = (t) => ["/business_table_rows/card", ...t ? [t] : []], Qe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? we(t), queryFn: ({ signal: u }) => Te(t, { signal: u, ...r }), ...n };
};
function jm(t, e) {
  const n = Qe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Le = (t, e) => o.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), We = (t) => ["/business/officers", ...t ? [t] : []], ze = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? We(t), queryFn: ({ signal: u }) => Le(t, { signal: u, ...r }), ...n };
};
function Xm(t, e) {
  const n = ze(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $e = (t, e) => o.post(
  "/business/officers",
  t,
  e
), Ve = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $e(a, n);
  }, ...e };
}, Jm = (t) => {
  const e = Ve(t);
  return c(e);
}, He = (t, e) => o.delete(
  `/business/officers/${t}`,
  e
), Ye = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return He(a, n);
  }, ...e };
}, Zm = (t) => {
  const e = Ye(t);
  return c(e);
}, je = (t, e, n) => o.patch(
  `/business/officers/${t}`,
  e,
  n
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return je(a, u, n);
  }, ...e };
}, tg = (t) => {
  const e = Xe(t);
  return c(e);
}, Je = (t) => o.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Je(n), ...e };
}, eg = (t) => {
  const e = Ze(t);
  return c(e);
}, tn = (t, e) => o.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), en = (t) => ["/dataset_uploads", ...t ? [t] : []], nn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? en(t), queryFn: ({ signal: u }) => tn(t, { signal: u, ...r }), ...n };
};
function ng(t, e) {
  const n = nn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rn = (t, e) => o.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], an = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sn(t), queryFn: ({ signal: u }) => rn(t, { signal: u, ...r }), ...n };
};
function rg(t, e) {
  const n = an(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const on = (t, e) => o.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), un = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], cn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? un(t), queryFn: ({ signal: u }) => on(t, { signal: u, ...r }), ...n };
};
function sg(t, e) {
  const n = cn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yn = (t, e) => o.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dn = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], _n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dn(t), queryFn: ({ signal: u }) => yn(t, { signal: u, ...r }), ...n };
};
function ag(t, e) {
  const n = _n(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pn = (t, e) => o.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ln = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ln(t), queryFn: ({ signal: u }) => pn(t, { signal: u, ...r }), ...n };
};
function og(t, e) {
  const n = mn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gn = (t, e) => o.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), qn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gn(a, n);
  }, ...e };
}, ug = (t) => {
  const e = qn(t);
  return c(e);
}, fn = (t, e) => o.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hn = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], On = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hn(t), queryFn: ({ signal: u }) => fn(t, { signal: u, ...r }), ...n };
};
function ig(t, e) {
  const n = On(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kn = (t, e) => o.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Kn(a, n);
  }, ...e };
}, cg = (t) => {
  const e = bn(t);
  return c(e);
}, Pn = (t, e) => o.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), kn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], Mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kn(t), queryFn: ({ signal: u }) => Pn(t, { signal: u, ...r }), ...n };
};
function yg(t, e) {
  const n = Mn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xn = (t, e) => o.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Rn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xn(a, n);
  }, ...e };
}, dg = (t) => {
  const e = Rn(t);
  return c(e);
}, Bn = (t, e) => o.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), Fn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bn(a, n);
  }, ...e };
}, _g = (t) => {
  const e = Fn(t);
  return c(e);
}, En = (t, e) => o.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vn = (t) => ["/dataingestion/schema", ...t ? [t] : []], Cn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vn(t), queryFn: ({ signal: u }) => En(t, { signal: u, ...r }), ...n };
};
function pg(t, e) {
  const n = Cn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Un = (t, e) => o.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nn = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], Dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nn(t), queryFn: ({ signal: u }) => Un(t, { signal: u, ...r }), ...n };
};
function lg(t, e) {
  const n = Dn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => o.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Sn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return An(a, n);
  }, ...e };
}, mg = (t) => {
  const e = Sn(t);
  return c(e);
}, Gn = (t, e) => o.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), In = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gn(a, n);
  }, ...e };
}, gg = (t) => {
  const e = In(t);
  return c(e);
}, Tn = (t, e) => o.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wn = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], Qn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wn(t), queryFn: ({ signal: u }) => Tn(t, { signal: u, ...r }), ...n };
};
function qg(t, e) {
  const n = Qn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ln = (t, e) => o.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wn = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wn(t), queryFn: ({ signal: u }) => Ln(t, { signal: u, ...r }), ...n };
};
function fg(t, e) {
  const n = zn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $n = (t, e) => o.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vn = (t) => ["/capital_products", ...t ? [t] : []], Hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vn(t), queryFn: ({ signal: u }) => $n(t, { signal: u, ...r }), ...n };
};
function hg(t, e) {
  const n = Hn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Yn = (t, e) => o.post(
  "/capital_products",
  t,
  e
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yn(a, n);
  }, ...e };
}, Og = (t) => {
  const e = jn(t);
  return c(e);
}, Xn = (t, e, n) => o.patch(
  `/capital_products/${t}`,
  e,
  n
), Jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Xn(a, u, n);
  }, ...e };
}, Kg = (t) => {
  const e = Jn(t);
  return c(e);
}, Zn = (t, e) => o.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tr = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tr(t), queryFn: ({ signal: u }) => Zn(t, { signal: u, ...r }), ...n };
};
function bg(t, e) {
  const n = er(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nr = (t, e) => o.post(
  "/capital_product_offer_collections",
  t,
  e
), rr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return nr(a, n);
  }, ...e };
}, Pg = (t) => {
  const e = rr(t);
  return c(e);
}, sr = (t, e) => o.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ar = (t) => ["/capital_product_activities", ...t ? [t] : []], or = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ar(t), queryFn: ({ signal: u }) => sr(t, { signal: u, ...r }), ...n };
};
function kg(t, e) {
  const n = or(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ur = (t, e) => o.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ir = (t) => ["/capital_product_applications", ...t ? [t] : []], cr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ir(t), queryFn: ({ signal: u }) => ur(t, { signal: u, ...r }), ...n };
};
function Mg(t, e) {
  const n = cr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yr = (t, e) => o.post(
  "/capital_product_applications",
  t,
  e
), dr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yr(a, n);
  }, ...e };
}, xg = (t) => {
  const e = dr(t);
  return c(e);
}, _r = (t, e) => o.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pr = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], lr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pr(t), queryFn: ({ signal: u }) => _r(t, { signal: u, ...r }), ...n };
};
function Rg(t, e) {
  const n = lr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const mr = (t, e) => o.post(
  "/net_top_up",
  t,
  e
), gr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mr(a, n);
  }, ...e };
}, Bg = (t) => {
  const e = gr(t);
  return c(e);
}, qr = (t, e) => o.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fr = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], hr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fr(t), queryFn: ({ signal: u }) => qr(t, { signal: u, ...r }), ...n };
};
function Fg(t, e) {
  const n = hr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Or = (t, e) => o.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kr(t), queryFn: ({ signal: u }) => Or(t, { signal: u, ...r }), ...n };
};
function Eg(t, e) {
  const n = br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Pr = (t) => o.get(
  "/benchmark_capital_products_ownerships",
  t
), kr = () => ["/benchmark_capital_products_ownerships"], Mr = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? kr(), queryFn: ({ signal: a }) => Pr({ signal: a, ...n }), ...e };
};
function vg(t) {
  const e = Mr(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const xr = (t, e) => o.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], Br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rr(t), queryFn: ({ signal: u }) => xr(t, { signal: u, ...r }), ...n };
};
function Cg(t, e) {
  const n = Br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fr = (t, e) => o.post(
  "/offered_capital_product_discount",
  t,
  e
), Er = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fr(a, n);
  }, ...e };
}, Ug = (t) => {
  const e = Er(t);
  return c(e);
}, vr = (t, e) => o.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cr = (t) => ["/capital_product_intents", ...t ? [t] : []], Ur = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cr(t), queryFn: ({ signal: u }) => vr(t, { signal: u, ...r }), ...n };
};
function Ng(t, e) {
  const n = Ur(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Nr = (t, e) => o.post(
  "/capital_product_intents",
  t,
  e
), Dr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Nr(a, n);
  }, ...e };
}, Dg = (t) => {
  const e = Dr(t);
  return c(e);
}, Ar = (t, e, n) => o.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), Sr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ar(a, u, n);
  }, ...e };
}, Ag = (t) => {
  const e = Sr(t);
  return c(e);
}, Gr = (t, e) => o.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ir = (t) => ["/counteroffer", ...t ? [t] : []], Tr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ir(t), queryFn: ({ signal: u }) => Gr(t, { signal: u, ...r }), ...n };
};
function Sg(t, e) {
  const n = Tr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const wr = (t, e) => o.post(
  "/capital_product_super_applications",
  t,
  e
), Qr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return wr(a, n);
  }, ...e };
}, Gg = (t) => {
  const e = Qr(t);
  return c(e);
}, Lr = (t, e) => o.post(
  "/capital_product_super_application_actions",
  t,
  e
), Wr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Lr(a, n);
  }, ...e };
}, Ig = (t) => {
  const e = Wr(t);
  return c(e);
}, zr = (t, e) => o.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $r = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], Vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $r(t), queryFn: ({ signal: u }) => zr(t, { signal: u, ...r }), ...n };
};
function Tg(t, e) {
  const n = Vr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hr = (t, e) => o.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), Yr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hr(a, n);
  }, ...e };
}, wg = (t) => {
  const e = Yr(t);
  return c(e);
}, jr = (t, e) => o.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xr = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], Jr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xr(t), queryFn: ({ signal: u }) => jr(t, { signal: u, ...r }), ...n };
};
function Qg(t, e) {
  const n = Jr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zr = (t, e, n) => o.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), ts = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Zr(a, u, n);
  }, ...e };
}, Lg = (t) => {
  const e = ts(t);
  return c(e);
}, es = (t, e) => o.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ns = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], rs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ns(t), queryFn: ({ signal: u }) => es(t, { signal: u, ...r }), ...n };
};
function Wg(t, e) {
  const n = rs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ss = (t, e) => o.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), as = (t) => ["/funding/bank_account_info", ...t ? [t] : []], os = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? as(t), queryFn: ({ signal: u }) => ss(t, { signal: u, ...r }), ...n };
};
function zg(t, e) {
  const n = os(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const us = (t, e) => o.post(
  "/funding/bank_account_info",
  t,
  e
), is = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return us(a, n);
  }, ...e };
}, $g = (t) => {
  const e = is(t);
  return c(e);
}, cs = (t, e, n) => o.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), ys = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return cs(a, u, n);
  }, ...e };
}, Vg = (t) => {
  const e = ys(t);
  return c(e);
}, ds = (t, e) => o.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _s = (t) => ["/funding/accounts", ...t ? [t] : []], ps = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _s(t), queryFn: ({ signal: u }) => ds(t, { signal: u, ...r }), ...n };
};
function Hg(t, e) {
  const n = ps(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ls = (t, e) => o.post(
  "/funding/accounts",
  t,
  e
), ms = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ls(a, n);
  }, ...e };
}, Yg = (t) => {
  const e = ms(t);
  return c(e);
}, gs = (t, e) => o.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qs = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], fs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qs(t), queryFn: ({ signal: u }) => gs(t, { signal: u, ...r }), ...n };
};
function jg(t, e) {
  const n = fs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const hs = (t, e) => o.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), Os = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return hs(a, n);
  }, ...e };
}, Xg = (t) => {
  const e = Os(t);
  return c(e);
}, Ks = (t, e, n) => o.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), bs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ks(a, u, n);
  }, ...e };
}, Jg = (t) => {
  const e = bs(t);
  return c(e);
}, Ps = (t, e) => o.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ks = (t) => ["/onboarding/states", ...t ? [t] : []], Ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ks(t), queryFn: ({ signal: u }) => Ps(t, { signal: u, ...r }), ...n };
};
function Zg(t, e) {
  const n = Ms(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xs = (t, e) => o.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rs = (t) => ["/onboarding/info_needed", ...t ? [t] : []], Bs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rs(t), queryFn: ({ signal: u }) => xs(t, { signal: u, ...r }), ...n };
};
function tq(t, e) {
  const n = Bs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fs = (t, e) => o.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Es = (t) => ["/business_identities", ...t ? [t] : []], vs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Es(t), queryFn: ({ signal: u }) => Fs(t, { signal: u, ...r }), ...n };
};
function eq(t, e) {
  const n = vs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Cs = (t, e, n) => o.patch(
  `/business_identities/${t}`,
  e,
  n
), Us = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Cs(a, u, n);
  }, ...e };
}, nq = (t) => {
  const e = Us(t);
  return c(e);
}, Ns = (t, e) => o.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ds = (t) => ["/owners", ...t ? [t] : []], As = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ds(t), queryFn: ({ signal: u }) => Ns(t, { signal: u, ...r }), ...n };
};
function rq(t, e) {
  const n = As(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ss = (t, e, n) => o.patch(
  `/owners/${t}`,
  e,
  n
), Gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ss(a, u, n);
  }, ...e };
}, sq = (t) => {
  const e = Gs(t);
  return c(e);
}, Is = (t, e) => o.post(
  "/business",
  t,
  e
), Ts = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Is(a, n);
  }, ...e };
}, aq = (t) => {
  const e = Ts(t);
  return c(e);
}, ws = (t, e) => o.post(
  "/merchant_bank/business",
  t,
  e
), Qs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ws(a, n);
  }, ...e };
}, oq = (t) => {
  const e = Qs(t);
  return c(e);
}, Ls = (t) => o.post(
  "/amazon_redirect_url",
  void 0,
  t
), Ws = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Ls(n), ...e };
}, uq = (t) => {
  const e = Ws(t);
  return c(e);
}, zs = (t, e) => o.post(
  "/spd",
  t,
  e
), $s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return zs(a, n);
  }, ...e };
}, iq = (t) => {
  const e = $s(t);
  return c(e);
}, Vs = (t, e) => o.post(
  "/capital_product_business",
  t,
  e
), Hs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vs(a, n);
  }, ...e };
}, cq = (t) => {
  const e = Hs(t);
  return c(e);
}, Ys = (t, e) => o.patch(
  "/flex_loan_age",
  t,
  e
), js = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ys(a, n);
  }, ...e };
}, yq = (t) => {
  const e = js(t);
  return c(e);
}, Xs = (t, e) => o.patch(
  "/flex_loan_ownership",
  t,
  e
), Js = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xs(a, n);
  }, ...e };
}, dq = (t) => {
  const e = Js(t);
  return c(e);
}, Zs = (t, e) => o.patch(
  "/flex_loan_fund",
  t,
  e
), ta = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zs(a, n);
  }, ...e };
}, _q = (t) => {
  const e = ta(t);
  return c(e);
}, ea = (t, e) => o.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), na = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: a } = s ?? {};
    return ea(a, n);
  }, ...e };
}, pq = (t) => {
  const e = na(t);
  return c(e);
}, ra = (t, e) => o.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sa = (t) => ["/cards", ...t ? [t] : []], aa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sa(t), queryFn: ({ signal: u }) => ra(t, { signal: u, ...r }), ...n };
};
function lq(t, e) {
  const n = aa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oa = (t, e) => o.post(
  "/cards",
  t,
  e
), ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oa(a, n);
  }, ...e };
}, mq = (t) => {
  const e = ua(t);
  return c(e);
}, ia = (t, e) => o.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ca = (t) => ["/businesses/products", ...t ? [t] : []], ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ca(t), queryFn: ({ signal: u }) => ia(t, { signal: u, ...r }), ...n };
};
function gq(t, e) {
  const n = ya(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const da = (t, e) => o.post(
  "/business/auth_link",
  t,
  e
), _a = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return da(a, n);
  }, ...e };
}, qq = (t) => {
  const e = _a(t);
  return c(e);
}, pa = (t, e) => o.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), la = (t) => ["/document_upload/group", ...t ? [t] : []], ma = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? la(t), queryFn: ({ signal: u }) => pa(t, { signal: u, ...r }), ...n };
};
function fq(t, e) {
  const n = ma(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ga = (t, e) => o.post(
  "/document_upload/group",
  t,
  e
), qa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ga(a, n);
  }, ...e };
}, hq = (t) => {
  const e = qa(t);
  return c(e);
}, fa = (t, e) => o.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ha = (t) => ["/document_upload/list", ...t ? [t] : []], Oa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ha(t), queryFn: ({ signal: u }) => fa(t, { signal: u, ...r }), ...n };
};
function Oq(t, e) {
  const n = Oa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ka = (t, e) => o.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ba = (t) => ["/restriction", ...t ? [t] : []], Pa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ba(t), queryFn: ({ signal: u }) => Ka(t, { signal: u, ...r }), ...n };
};
function Kq(t, e) {
  const n = Pa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ka = (t, e) => o.post(
  "/restriction",
  t,
  e
), Ma = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ka(a, n);
  }, ...e };
}, bq = (t) => {
  const e = Ma(t);
  return c(e);
}, xa = (t, e) => o.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ra = (t) => ["/restrictions/details", ...t ? [t] : []], Ba = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ra(t), queryFn: ({ signal: u }) => xa(t, { signal: u, ...r }), ...n };
};
function Pq(t, e) {
  const n = Ba(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t, e) => o.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ea = (t) => ["/liens", ...t ? [t] : []], va = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ea(t), queryFn: ({ signal: u }) => Fa(t, { signal: u, ...r }), ...n };
};
function kq(t, e) {
  const n = va(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ca = (t, e, n) => o.patch(
  `/liens/${t}`,
  e,
  n
), Ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ca(a, u, n);
  }, ...e };
}, Mq = (t) => {
  const e = Ua(t);
  return c(e);
}, Na = (t, e) => o.post(
  "/opt_ins/trigger",
  t,
  e
), Da = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Na(a, n);
  }, ...e };
}, xq = (t) => {
  const e = Da(t);
  return c(e);
}, Aa = (t, e) => o.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sa = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], Ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sa(t), queryFn: ({ signal: u }) => Aa(t, { signal: u, ...r }), ...n };
};
function Rq(t, e) {
  const n = Ga(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ia = (t, e) => o.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ta = (t) => ["/parafinder/businesses", ...t ? [t] : []], wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ta(t), queryFn: ({ signal: u }) => Ia(t, { signal: u, ...r }), ...n };
};
function Bq(t, e) {
  const n = wa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Qa = (t) => o.get(
  "/parafinder/business_types",
  t
), La = () => ["/parafinder/business_types"], Wa = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? La(), queryFn: ({ signal: a }) => Qa({ signal: a, ...n }), ...e };
};
function Fq(t) {
  const e = Wa(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const za = (t, e) => o.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $a = (t) => ["/parafinder/summary", ...t ? [t] : []], Va = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $a(t), queryFn: ({ signal: u }) => za(t, { signal: u, ...r }), ...n };
};
function Eq(t, e) {
  const n = Va(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ha = (t, e) => o.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ya = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], ja = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ya(t), queryFn: ({ signal: u }) => Ha(t, { signal: u, ...r }), ...n };
};
function vq(t, e) {
  const n = ja(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Xa = (t, e) => o.get(
  `/merchant_bank/accounts/${t}`,
  e
), Ja = (t) => [`/merchant_bank/accounts/${t}`], Za = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ja(t), queryFn: ({ signal: u }) => Xa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Cq(t, e) {
  const n = Za(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const to = (t, e) => o.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), eo = (t) => [`/merchant_bank/accounts/${t}/balances`], no = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eo(t), queryFn: ({ signal: u }) => to(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Uq(t, e) {
  const n = no(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ro = (t, e) => o.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), so = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? so(t), queryFn: ({ signal: u }) => ro(t, { signal: u, ...r }), ...n };
};
function Nq(t, e) {
  const n = ao(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oo = (t, e) => o.post(
  "/merchant_bank/accounts",
  t,
  e
), uo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oo(a, n);
  }, ...e };
}, Dq = (t) => {
  const e = uo(t);
  return c(e);
}, io = (t, e) => o.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), co = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return io(a, n);
  }, ...e };
}, Aq = (t) => {
  const e = co(t);
  return c(e);
}, yo = (t, e, n) => o.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), _o = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], po = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? _o(t, e), queryFn: ({ signal: y }) => yo(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Sq(t, e, n) {
  const r = po(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const lo = (t, e) => o.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mo = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], go = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mo(t), queryFn: ({ signal: u }) => lo(t, { signal: u, ...r }), ...n };
};
function Gq(t, e) {
  const n = go(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qo = (t) => o.get(
  "/merchant_bank/transfer_fees",
  t
), fo = () => ["/merchant_bank/transfer_fees"], ho = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? fo(), queryFn: ({ signal: a }) => qo({ signal: a, ...n }), ...e };
};
function Iq(t) {
  const e = ho(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Oo = (t, e) => o.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ko = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], bo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ko(t), queryFn: ({ signal: u }) => Oo(t, { signal: u, ...r }), ...n };
};
function Tq(t, e) {
  const n = bo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Po = (t) => o.get(
  "/merchant_bank/interest_details",
  t
), ko = () => ["/merchant_bank/interest_details"], Mo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ko(), queryFn: ({ signal: a }) => Po({ signal: a, ...n }), ...e };
};
function wq(t) {
  const e = Mo(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const xo = (t, e) => o.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ro = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], Bo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ro(t), queryFn: ({ signal: u }) => xo(t, { signal: u, ...r }), ...n };
};
function Qq(t, e) {
  const n = Bo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fo = (t, e) => o.post(
  "/merchant_bank/recipients",
  t,
  e
), Eo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fo(a, n);
  }, ...e };
}, Lq = (t) => {
  const e = Eo(t);
  return c(e);
}, vo = (t, e) => o.get(
  `/merchant_bank/recipients/${t}`,
  e
), Co = (t) => [`/merchant_bank/recipients/${t}`], Uo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Co(t), queryFn: ({ signal: u }) => vo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Wq(t, e) {
  const n = Uo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const No = (t, e) => o.delete(
  `/merchant_bank/recipients/${t}`,
  e
), Do = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return No(a, n);
  }, ...e };
}, zq = (t) => {
  const e = Do(t);
  return c(e);
}, Ao = (t, e, n) => o.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), So = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ao(a, u, n);
  }, ...e };
}, $q = (t) => {
  const e = So(t);
  return c(e);
}, Go = (t, e, n) => o.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), Io = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Go(a, u, n);
  }, ...e };
}, Vq = (t) => {
  const e = Io(t);
  return c(e);
}, To = (t, e) => o.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), wo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return To(a, n);
  }, ...e };
}, Hq = (t) => {
  const e = wo(t);
  return c(e);
}, Qo = (t, e, n) => o.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), Lo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Qo(a, u, n);
  }, ...e };
}, Yq = (t) => {
  const e = Lo(t);
  return c(e);
}, Wo = (t, e) => o.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zo = (t) => ["/merchant_bank/cards", ...t ? [t] : []], $o = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zo(t), queryFn: ({ signal: u }) => Wo(t, { signal: u, ...r }), ...n };
};
function jq(t, e) {
  const n = $o(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vo = (t, e) => o.post(
  "/merchant_bank/cards",
  t,
  e
), Ho = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vo(a, n);
  }, ...e };
}, Xq = (t) => {
  const e = Ho(t);
  return c(e);
}, Yo = (t, e, n) => o.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), jo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Yo(a, u, n);
  }, ...e };
}, Jq = (t) => {
  const e = jo(t);
  return c(e);
}, Xo = (t, e) => o.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jo = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], Zo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jo(t), queryFn: ({ signal: u }) => Xo(t, { signal: u, ...r }), ...n };
};
function Zq(t, e) {
  const n = Zo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const tu = (t, e) => o.post(
  "/merchant_bank/card/activate",
  t,
  e
), eu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return tu(a, n);
  }, ...e };
}, tf = (t) => {
  const e = eu(t);
  return c(e);
}, nu = (t, e) => o.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ru = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], su = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ru(t), queryFn: ({ signal: u }) => nu(t, { signal: u, ...r }), ...n };
};
function ef(t, e) {
  const n = su(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const au = (t, e) => o.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), ou = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return au(a, n);
  }, ...e };
}, nf = (t) => {
  const e = ou(t);
  return c(e);
}, uu = (t, e) => o.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), iu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], cu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? iu(t), queryFn: ({ signal: u }) => uu(t, { signal: u, ...r }), ...n };
};
function rf(t, e) {
  const n = cu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yu = (t, e) => o.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), du = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yu(a, n);
  }, ...e };
}, sf = (t) => {
  const e = du(t);
  return c(e);
}, _u = (t, e) => o.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _u(a, n);
  }, ...e };
}, af = (t) => {
  const e = pu(t);
  return c(e);
}, lu = (t, e) => o.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], gu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mu(t), queryFn: ({ signal: u }) => lu(t, { signal: u, ...r }), ...n };
};
function of(t, e) {
  const n = gu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qu = (t, e) => o.post(
  "/merchant_bank/card/disputes",
  t,
  e
), fu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qu(a, n);
  }, ...e };
}, uf = (t) => {
  const e = fu(t);
  return c(e);
}, hu = (t, e) => o.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), Ou = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return hu(a, n);
  }, ...e };
}, cf = (t) => {
  const e = Ou(t);
  return c(e);
}, Ku = (t, e) => o.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ku(a, n);
  }, ...e };
}, yf = (t) => {
  const e = bu(t);
  return c(e);
}, Pu = (t, e) => o.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ku = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], Mu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ku(t), queryFn: ({ signal: u }) => Pu(t, { signal: u, ...r }), ...n };
};
function df(t, e) {
  const n = Mu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), Ru = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], Bu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ru(t), queryFn: ({ signal: u }) => xu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _f(t, e) {
  const n = Bu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fu = (t, e) => o.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eu = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], vu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eu(t), queryFn: ({ signal: u }) => Fu(t, { signal: u, ...r }), ...n };
};
function pf(t, e) {
  const n = vu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Cu = (t, e) => o.post(
  "/merchant_bank/external_accounts",
  t,
  e
), Uu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cu(a, n);
  }, ...e };
}, lf = (t) => {
  const e = Uu(t);
  return c(e);
}, Nu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), Du = (t) => [`/merchant_bank/external_accounts/${t}`], Au = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Du(t), queryFn: ({ signal: u }) => Nu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function mf(t, e) {
  const n = Au(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Su = (t, e) => o.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), Gu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Su(a, n);
  }, ...e };
}, gf = (t) => {
  const e = Gu(t);
  return c(e);
}, Iu = (t, e, n) => o.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), Tu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Iu(a, u, n);
  }, ...e };
}, qf = (t) => {
  const e = Tu(t);
  return c(e);
}, wu = (t, e) => o.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), Qu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return wu(a, n);
  }, ...e };
}, ff = (t) => {
  const e = Qu(t);
  return c(e);
}, Lu = (t, e, n) => o.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), Wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Lu(a, u, n);
  }, ...e };
}, hf = (t) => {
  const e = Wu(t);
  return c(e);
}, zu = (t, e) => o.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $u = (t) => ["/debts", ...t ? [t] : []], Vu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $u(t), queryFn: ({ signal: u }) => zu(t, { signal: u, ...r }), ...n };
};
function Of(t, e) {
  const n = Vu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hu = (t, e) => o.post(
  "/debts",
  t,
  e
), Yu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hu(a, n);
  }, ...e };
}, Kf = (t) => {
  const e = Yu(t);
  return c(e);
}, ju = (t, e) => o.post(
  "/debts/check_complete",
  t,
  e
), Xu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ju(a, n);
  }, ...e };
}, bf = (t) => {
  const e = Xu(t);
  return c(e);
}, Ju = (t, e) => o.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zu = (t) => ["/debt_checks", ...t ? [t] : []], ti = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zu(t), queryFn: ({ signal: u }) => Ju(t, { signal: u, ...r }), ...n };
};
function Pf(t, e) {
  const n = ti(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ei = (t, e) => o.post(
  "/card/close_account",
  t,
  e
), ni = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ei(a, n);
  }, ...e };
}, kf = (t) => {
  const e = ni(t);
  return c(e);
}, ri = (t, e) => o.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), si = (t) => ["/financial_reviews", ...t ? [t] : []], ai = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? si(t), queryFn: ({ signal: u }) => ri(t, { signal: u, ...r }), ...n };
};
function Mf(t, e) {
  const n = ai(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oi = (t, e) => o.post(
  "/financial_reviews",
  t,
  e
), ui = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oi(a, n);
  }, ...e };
}, xf = (t) => {
  const e = ui(t);
  return c(e);
}, ii = (t, e) => o.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ci = (t) => ["/accounting_info", ...t ? [t] : []], yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ci(t), queryFn: ({ signal: u }) => ii(t, { signal: u, ...r }), ...n };
};
function Rf(t, e) {
  const n = yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const di = (t, e) => o.post(
  "/accounting_info",
  t,
  e
), _i = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return di(a, n);
  }, ...e };
}, Bf = (t) => {
  const e = _i(t);
  return c(e);
}, pi = (t, e) => o.post(
  "/tax_records",
  t,
  e
), li = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return pi(a, n);
  }, ...e };
}, Ff = (t) => {
  const e = li(t);
  return c(e);
}, mi = (t, e) => o.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gi = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], qi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gi(t), queryFn: ({ signal: u }) => mi(t, { signal: u, ...r }), ...n };
};
function Ef(t, e) {
  const n = qi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const fi = (t, e) => o.post(
  "/officer_subsidiary_records",
  t,
  e
), hi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fi(a, n);
  }, ...e };
}, vf = (t) => {
  const e = hi(t);
  return c(e);
}, Oi = (t) => o.get(
  "/analytics/cube_token",
  t
), Ki = () => ["/analytics/cube_token"], bi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ki(), queryFn: ({ signal: a }) => Oi({ signal: a, ...n }), ...e };
};
function Cf(t) {
  const e = bi(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Pi = (t, e) => o.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ki = (t) => ["/logs/api_request", ...t ? [t] : []], Mi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ki(t), queryFn: ({ signal: u }) => Pi(t, { signal: u, ...r }), ...n };
};
function Uf(t, e) {
  const n = Mi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xi = (t) => o.get(
  "/marketing/offer_csvs",
  t
), Ri = () => ["/marketing/offer_csvs"], Bi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ri(), queryFn: ({ signal: a }) => xi({ signal: a, ...n }), ...e };
};
function Nf(t) {
  const e = Bi(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Fi = (t) => o.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), Ei = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Fi(n), ...e };
}, Df = (t) => {
  const e = Ei(t);
  return c(e);
}, vi = (t, e) => o.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ci = (t) => ["/businesses/notifications", ...t ? [t] : []], Ui = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ci(t), queryFn: ({ signal: u }) => vi(t, { signal: u, ...r }), ...n };
};
function Af(t, e) {
  const n = Ui(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ni = (t, e) => o.post(
  "/businesses/notifications",
  t,
  e
), Di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ni(a, n);
  }, ...e };
}, Sf = (t) => {
  const e = Di(t);
  return c(e);
}, Ai = (t, e) => o.post(
  "/terms_of_service_mark_shown",
  t,
  e
), Si = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ai(a, n);
  }, ...e };
}, Gf = (t) => {
  const e = Si(t);
  return c(e);
}, Gi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ii = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], Ti = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ii(t, e), queryFn: ({ signal: y }) => Gi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function If(t, e, n) {
  const r = Ti(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const wi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Qi = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], Li = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qi(t), queryFn: ({ signal: u }) => wi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Tf(t, e) {
  const n = Li(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), zi = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], $i = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zi(t), queryFn: ({ signal: u }) => Wi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function wf(t, e) {
  const n = $i(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Hi = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], Yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hi(t), queryFn: ({ signal: u }) => Vi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Qf(t, e) {
  const n = Yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ji = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Xi = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Ji = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Xi(t, e), queryFn: ({ signal: y }) => ji(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Lf(t, e, n) {
  const r = Ji(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Zi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), tc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], ec = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? tc(t, e), queryFn: ({ signal: y }) => Zi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Wf(t, e, n) {
  const r = ec(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const nc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), rc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], sc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? rc(t, e), queryFn: ({ signal: y }) => nc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function zf(t, e, n) {
  const r = sc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const ac = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), oc = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oc(t), queryFn: ({ signal: u }) => ac(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function $f(t, e) {
  const n = uc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ic = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], yc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cc(t), queryFn: ({ signal: u }) => ic(t, { signal: u, ...r }), ...n };
};
function Vf(t, e) {
  const n = yc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const dc = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _c = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _c(t), queryFn: ({ signal: u }) => dc(t, { signal: u, ...r }), ...n };
};
function Hf(t, e) {
  const n = pc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], gc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), ...n };
};
function Yf(t, e) {
  const n = gc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], hc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fc(t), queryFn: ({ signal: u }) => qc(t, { signal: u, ...r }), ...n };
};
function jf(t, e) {
  const n = hc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], bc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kc(t), queryFn: ({ signal: u }) => Oc(t, { signal: u, ...r }), ...n };
};
function Xf(t, e) {
  const n = bc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Pc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), kc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], Mc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? kc(t), queryFn: ({ signal: u }) => Pc(t, { signal: u, ...r }), ...n };
};
function Jf(t, e) {
  const n = Mc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rc = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], Bc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rc(t), queryFn: ({ signal: u }) => xc(t, { signal: u, ...r }), ...n };
};
function Zf(t, e) {
  const n = Bc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ec = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ec(t), queryFn: ({ signal: u }) => Fc(t, { signal: u, ...r }), ...n };
};
function th(t, e) {
  const n = vc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Cc = (t) => o.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), Uc = () => ["/partner_dashboard/platform_tab/partner_support_info"], Nc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Uc(), queryFn: ({ signal: a }) => Cc({ signal: a, ...n }), ...e };
};
function eh(t) {
  const e = Nc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Dc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), Ac = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], Sc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ac(t), queryFn: ({ signal: u }) => Dc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function nh(t, e) {
  const n = Sc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Gc = (t) => o.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), Ic = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], Tc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ic(), queryFn: ({ signal: a }) => Gc({ signal: a, ...n }), ...e };
};
function rh(t) {
  const e = Tc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const wc = (t, e) => o.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qc = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], Lc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qc(t), queryFn: ({ signal: u }) => wc(t, { signal: u, ...r }), ...n };
};
function sh(t, e) {
  const n = Lc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wc = (t) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  void 0,
  t
), zc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Wc(n), ...e };
}, ah = (t) => {
  const e = zc(t);
  return c(e);
}, $c = (t, e) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), Vc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $c(a, n);
  }, ...e };
}, oh = (t) => {
  const e = Vc(t);
  return c(e);
}, Hc = (t, e) => o.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), Yc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hc(a, n);
  }, ...e };
}, uh = (t) => {
  const e = Yc(t);
  return c(e);
}, jc = (t) => o.get(
  "/partner_dashboard/crm/integration",
  t
), Xc = () => ["/partner_dashboard/crm/integration"], Jc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Xc(), queryFn: ({ signal: a }) => jc({ signal: a, ...n }), ...e };
};
function ih(t) {
  const e = Jc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Zc = (t) => o.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), ty = () => ["/partner_dashboard/valid_object_id_prefixes"], ey = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ty(), queryFn: ({ signal: a }) => Zc({ signal: a, ...n }), ...e };
};
function ch(t) {
  const e = ey(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ny = (t, e) => o.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ry = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], sy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ry(t), queryFn: ({ signal: u }) => ny(t, { signal: u, ...r }), ...n };
};
function yh(t, e) {
  const n = sy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ay = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), oy = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], uy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oy(t), queryFn: ({ signal: u }) => ay(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function dh(t, e) {
  const n = uy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const iy = (t, e, n) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), cy = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], yy = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? cy(t, e), queryFn: ({ signal: y }) => iy(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function _h(t, e, n) {
  const r = yy(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const dy = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), _y = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], py = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _y(t), queryFn: ({ signal: u }) => dy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ph(t, e) {
  const n = py(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ly = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), my = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ly(a, n);
  }, ...e };
}, lh = (t) => {
  const e = my(t);
  return c(e);
}, gy = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), qy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gy(a, n);
  }, ...e };
}, mh = (t) => {
  const e = qy(t);
  return c(e);
}, fy = (t, e) => o.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hy = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], Oy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hy(t), queryFn: ({ signal: u }) => fy(t, { signal: u, ...r }), ...n };
};
function gh(t, e) {
  const n = Oy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ky = (t, e, n) => o.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), by = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], Py = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? by(t, e), queryFn: ({ signal: y }) => Ky(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function qh(t, e, n) {
  const r = Py(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const ky = (t, e) => o.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), My = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ky(a, n);
  }, ...e };
}, fh = (t) => {
  const e = My(t);
  return c(e);
}, xy = (t, e) => o.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), Ry = (t) => [`/partner_dashboard/crm/event_info/${t}`], By = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ry(t), queryFn: ({ signal: u }) => xy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function hh(t, e) {
  const n = By(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fy = (t) => o.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), Ey = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Fy(n), ...e };
}, Oh = (t) => {
  const e = Ey(t);
  return c(e);
}, vy = (t) => o.get(
  "/partner_dashboard/crm/sync",
  t
), Cy = () => ["/partner_dashboard/crm/sync"], Uy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Cy(), queryFn: ({ signal: a }) => vy({ signal: a, ...n }), ...e };
};
function Kh(t) {
  const e = Uy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ny = (t) => o.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), Dy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Ny(n), ...e };
}, bh = (t) => {
  const e = Dy(t);
  return c(e);
}, Ay = (t, e) => o.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sy = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], Gy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sy(t), queryFn: ({ signal: u }) => Ay(t, { signal: u, ...r }), ...n };
};
function Ph(t, e) {
  const n = Gy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Iy = (t, e) => o.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), Ty = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Iy(a, n);
  }, ...e };
}, kh = (t) => {
  const e = Ty(t);
  return c(e);
}, wy = (t, e) => o.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), Qy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return wy(a, n);
  }, ...e };
}, Mh = (t) => {
  const e = Qy(t);
  return c(e);
}, Ly = (t, e, n) => o.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), Wy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ly(a, u, n);
  }, ...e };
}, xh = (t) => {
  const e = Wy(t);
  return c(e);
}, zy = (t, e) => o.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), $y = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], Vy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $y(t), queryFn: ({ signal: u }) => zy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Rh(t, e) {
  const n = Vy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hy = (t, e) => o.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yy = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], jy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yy(t), queryFn: ({ signal: u }) => Hy(t, { signal: u, ...r }), ...n };
};
function Bh(t, e) {
  const n = jy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Xy = (t, e) => o.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), Jy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xy(a, n);
  }, ...e };
}, Fh = (t) => {
  const e = Jy(t);
  return c(e);
}, Zy = (t, e) => o.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), td = (t) => ["/rutter_connections", ...t ? [t] : []], ed = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? td(t), queryFn: ({ signal: u }) => Zy(t, { signal: u, ...r }), ...n };
};
function Eh(t, e) {
  const n = ed(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nd = (t, e) => o.post(
  "/rutter_connections",
  t,
  e
), rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return nd(a, n);
  }, ...e };
}, vh = (t) => {
  const e = rd(t);
  return c(e);
}, sd = (t, e) => o.post(
  "/mark_accounting_integration_seen",
  t,
  e
), ad = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return sd(a, n);
  }, ...e };
}, Ch = (t) => {
  const e = ad(t);
  return c(e);
}, od = (t) => o.get(
  "/sardine/session_id",
  t
), ud = () => ["/sardine/session_id"], id = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ud(), queryFn: ({ signal: a }) => od({ signal: a, ...n }), ...e };
};
function Uh(t) {
  const e = id(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const cd = (t, e) => o.post(
  "/sardine/session_id",
  t,
  e
), yd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cd(a, n);
  }, ...e };
}, Nh = (t) => {
  const e = yd(t);
  return c(e);
}, dd = (t) => o.get(
  "/partner/widget_configs",
  t
), _d = () => ["/partner/widget_configs"], pd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _d(), queryFn: ({ signal: a }) => dd({ signal: a, ...n }), ...e };
};
function Dh(t) {
  const e = pd(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ld = (t, e) => o.patch(
  "/partner/widget_configs",
  t,
  e
), md = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ld(a, n);
  }, ...e };
}, Ah = (t) => {
  const e = md(t);
  return c(e);
}, gd = (t, e) => o.get(
  `/merchant_bank/applications/${t}`,
  e
), qd = (t) => [`/merchant_bank/applications/${t}`], fd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qd(t), queryFn: ({ signal: u }) => gd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Sh(t, e) {
  const n = fd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const hd = (t, e) => o.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Od = (t) => ["/merchant_bank/applications", ...t ? [t] : []], Kd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Od(t), queryFn: ({ signal: u }) => hd(t, { signal: u, ...r }), ...n };
};
function Gh(t, e) {
  const n = Kd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const bd = (t, e) => o.post(
  "/merchant_bank/applications",
  t,
  e
), Pd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return bd(a, n);
  }, ...e };
}, Ih = (t) => {
  const e = Pd(t);
  return c(e);
}, kd = (t, e) => o.post(
  "/webhooks/plaid/general",
  t,
  e
), Md = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return kd(a, n);
  }, ...e };
}, Th = (t) => {
  const e = Md(t);
  return c(e);
}, xd = (t, e) => o.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rd = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Bd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rd(t), queryFn: ({ signal: u }) => xd(t, { signal: u, ...r }), ...n };
};
function wh(t, e) {
  const n = Bd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fd = (t) => o.get(
  "/naics",
  t
), Ed = () => ["/naics"], vd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ed(), queryFn: ({ signal: a }) => Fd({ signal: a, ...n }), ...e };
};
function Qh(t) {
  const e = vd(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Cd = (t, e, n, r) => o.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), Ud = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return Cd(a, u, y, n);
  }, ...e };
}, Lh = (t) => {
  const e = Ud(t);
  return c(e);
}, Nd = (t, e, n, r) => o.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), Dd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return Nd(a, u, y, n);
  }, ...e };
}, Wh = (t) => {
  const e = Dd(t);
  return c(e);
}, Ad = (t, e) => o.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sd = (t) => ["/funding/pad_agreement", ...t ? [t] : []], Gd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sd(t), queryFn: ({ signal: u }) => Ad(t, { signal: u, ...r }), ...n };
};
function zh(t, e) {
  const n = Gd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Id = (t, e) => o.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), Td = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Id(a, n);
  }, ...e };
}, $h = (t) => {
  const e = Td(t);
  return c(e);
}, wd = (t, e) => o.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qd = (t) => ["/templates", ...t ? [t] : []], Ld = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qd(t), queryFn: ({ signal: u }) => wd(t, { signal: u, ...r }), ...n };
};
function Vh(t, e) {
  const n = Ld(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wd = (t, e) => o.post(
  "/templates",
  t,
  e
), zd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wd(a, n);
  }, ...e };
}, Hh = (t) => {
  const e = zd(t);
  return c(e);
}, $d = (t, e) => o.patch(
  "/templates",
  t,
  e
), Vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $d(a, n);
  }, ...e };
}, Yh = (t) => {
  const e = Vd(t);
  return c(e);
}, Hd = (t, e) => o.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yd = (t) => ["/template_mock", ...t ? [t] : []], jd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yd(t), queryFn: ({ signal: u }) => Hd(t, { signal: u, ...r }), ...n };
};
function jh(t, e) {
  const n = jd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Xd = (t, e) => o.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jd = (t) => ["/template_snapshots", ...t ? [t] : []], Zd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jd(t), queryFn: ({ signal: u }) => Xd(t, { signal: u, ...r }), ...n };
};
function Xh(t, e) {
  const n = Zd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const t_ = (t, e) => o.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), e_ = (t) => ["/template_snapshot_urls", ...t ? [t] : []], n_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? e_(t), queryFn: ({ signal: u }) => t_(t, { signal: u, ...r }), ...n };
};
function Jh(t, e) {
  const n = n_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const r_ = (t) => o.get(
  "/template_types",
  t
), s_ = () => ["/template_types"], a_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? s_(), queryFn: ({ signal: a }) => r_({ signal: a, ...n }), ...e };
};
function Zh(t) {
  const e = a_(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const o_ = (t, e) => o.post(
  "/template_types",
  t,
  e
), u_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return o_(a, n);
  }, ...e };
}, tO = (t) => {
  const e = u_(t);
  return c(e);
}, i_ = (t, e) => o.post(
  "/manual_repayment",
  t,
  e
), c_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return i_(a, n);
  }, ...e };
}, eO = (t) => {
  const e = c_(t);
  return c(e);
}, y_ = (t, e) => o.post(
  "/forgive_capital_fee",
  t,
  e
), d_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return y_(a, n);
  }, ...e };
}, nO = (t) => {
  const e = d_(t);
  return c(e);
}, __ = (t, e) => o.post(
  "/mfa/verify_account_details",
  t,
  e
), p_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return __(a, n);
  }, ...e };
}, rO = (t) => {
  const e = p_(t);
  return c(e);
}, l_ = (t) => o.patch(
  "/mfa/extend_session",
  void 0,
  t
), m_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => l_(n), ...e };
}, sO = (t) => {
  const e = m_(t);
  return c(e);
}, g_ = (t, e) => o.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q_ = (t) => ["/merchant_bank/offers", ...t ? [t] : []], f_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q_(t), queryFn: ({ signal: u }) => g_(t, { signal: u, ...r }), ...n };
};
function aO(t, e) {
  const n = f_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const h_ = (t, e) => o.post(
  "/merchant_bank/offers",
  t,
  e
), O_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return h_(a, n);
  }, ...e };
}, oO = (t) => {
  const e = O_(t);
  return c(e);
}, K_ = (t, e) => o.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b_ = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], P_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b_(t), queryFn: ({ signal: u }) => K_(t, { signal: u, ...r }), ...n };
};
function uO(t, e) {
  const n = P_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const k_ = (t, e) => o.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), M_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return k_(a, n);
  }, ...e };
}, iO = (t) => {
  const e = M_(t);
  return c(e);
}, cO = (t, e) => i({
  queryKey: ["plaid-link-token", t],
  queryFn: () => o.get("/funding/plaid_link_token", { ...e == null ? void 0 : e.axios, params: t })
}), yO = (t, e) => i({
  queryKey: ["cardholder", t.business_id],
  queryFn: () => o.get("/cardholders", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), dO = (t, e) => o.get("/statements", {
  ...e == null ? void 0 : e.axios,
  params: t
}), _O = (t, e) => i({
  queryKey: ["card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: r.amount <= 0 || r.transaction_type === "chargeback" ? "succeeded" : "refunded",
          activity_type: r.transaction_type === "chargeback" ? "dispute_credit" : "transaction",
          ...r.transaction_type === "chargeback" && {
            related_card_transaction: n.data.results.find(
              ({ id: s }) => s === r.related_card_transaction_id
            )
          }
        }))
      }
    };
  }
}), pO = (t, e) => i({
  queryKey: ["pending-card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/pending_transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: "pending",
          activity_type: "transaction"
        }))
      }
    };
  }
}), lO = (t, e) => i({
  queryKey: ["card-repayments"],
  queryFn: async () => {
    const n = await o.get("/repayments", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.filter((r) => r.state === "complete" || r.state === "pending").map((r) => ({
          ...r,
          timestamp: r.date,
          activity_type: "repayment",
          state: r.is_settled ? r.state : "pending"
        }))
      }
    };
  }
}), mO = (t, e) => i({
  queryKey: ["card-balance", t.business_id],
  queryFn: () => o.get("/card/balances", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), gO = (t, e) => i({
  queryKey: [`card-access-token-${t.card_id}`],
  queryFn: () => o.get("/card/access_token", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), qO = (t, e) => i({
  queryKey: ["show-top-up-offer", t.business_id],
  queryFn: () => o.get("/businesses/show_top_up_offer", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), fO = (t, e) => i({
  queryKey: ["whitelisted-for-early-payment", t.cash_advance_id],
  queryFn: () => o.get("/early_manual_repayment_whitelist", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), hO = (t, e) => i({
  queryKey: ["opt-out-eligibility", t.business_external_id],
  queryFn: () => o.get("/opt_out_eligibility", { ...e == null ? void 0 : e.axios, params: t })
}), OO = (t, e) => {
  var r;
  const n = (r = e == null ? void 0 : e.query) == null ? void 0 : r.enabled;
  return i({
    queryKey: ["card-lifecycle-state", t.business_id],
    queryFn: () => o.get("/card/lifecycle_states", {
      ...e == null ? void 0 : e.axios,
      params: t
    }),
    enabled: n
  });
}, KO = (t, e) => i({
  queryKey: ["opt-ins", t.business_id],
  queryFn: () => o.get("/opt_ins", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), bO = (t) => c({
  mutationFn: (e) => o.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), PO = (t) => c({
  mutationFn: ({
    id: e,
    ...n
  }) => o.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), kO = (t) => c({
  mutationFn: (e) => o.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), MO = (t) => c({
  mutationFn: (e) => o.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), xO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), RO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), BO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), FO = (t) => c({
  mutationFn: (e) => o.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), EO = (t) => c({
  mutationFn: (e) => o.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), vO = (t) => c({
  mutationFn: (e) => o.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), CO = (t) => c({
  mutationFn: (e) => o.patch(`/cardholders/${e.id}`, e, t == null ? void 0 : t.axios)
}), UO = (t) => c({
  mutationFn: (e) => o.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), NO = (t) => c({
  mutationFn: (e) => o.post("/card/activate", e, t == null ? void 0 : t.axios)
}), DO = (t) => c({
  mutationFn: async (e) => (await o.post("/card/apple_pay", e, t == null ? void 0 : t.axios)).data
}), AO = (t) => c({
  mutationFn: (e) => o.post("/card/google_pay", e, t == null ? void 0 : t.axios)
}), SO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/initiate_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), GO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/fail_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), IO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), TO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), wO = (t) => c({
  mutationFn: (e) => o.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), QO = (t) => c({
  mutationFn: (e) => o.post("/tickets", e, t == null ? void 0 : t.axios)
}), LO = (t) => c({
  mutationFn: (e) => o.post("/opt_out", e, t == null ? void 0 : t.axios)
}), WO = (t) => c({
  mutationFn: (e) => o.post("/opt_ins", e, t == null ? void 0 : t.axios)
}), zO = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/wallets/balance",
    method: "post",
    name: "Add Business Wallet Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/wallet_applications/{id}",
    method: "get",
    name: "Get Wallet Application"
  },
  {
    path: "/v1/wallet_applications",
    method: "get",
    name: "List Wallet Applications"
  },
  {
    path: "/v1/wallet_accounts/{id}",
    method: "get",
    name: "Get Wallet Account"
  },
  {
    path: "/v1/wallet_accounts",
    method: "get",
    name: "List Wallet Accounts"
  },
  {
    path: "/v1/wallet_balances/{id}",
    method: "get",
    name: "Get Wallet Balance"
  },
  {
    path: "/v1/wallet_balances",
    method: "get",
    name: "List Wallet Balances"
  },
  {
    path: "/v1/wallet_transactions",
    method: "get",
    name: "List Wallet Transactions"
  },
  {
    path: "/v1/sandbox/wallet_transactions",
    method: "post",
    name: "Create Wallet Transaction"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  }
];
export {
  Hl as AccountOwner,
  Vl as AddressType,
  $l as AdverseActionReason,
  zl as BankAccountInfoDataShare,
  Wl as BusinessProductType,
  Ll as CapitalProductActivityType,
  Ql as CapitalProductApplicationStatus,
  wl as CapitalProductApplicationStatus1,
  Tl as CapitalProductApplicationTransitionReason,
  Il as CapitalProductApplicationType,
  Gl as CapitalProductHealthStatus,
  Sl as CapitalProductInternalRepaymentPlanType,
  Al as CapitalProductOfferLabel,
  Dl as CapitalProductState,
  Nl as CapitalProductSuperApplicationActionType,
  Ul as CapitalProductType,
  Cl as CardBusinessTableRowStatus,
  vl as CardDeserializerCardType,
  Rl as CardSerializer1FulfillmentStatus,
  xl as CardSerializer1State,
  El as CardSerializerCardType,
  Fl as CardSerializerFulfillmentStatus,
  Bl as CardSerializerState,
  Ml as CardShippingMethod,
  kl as CardTransitionChannel,
  Pl as CardTransitionReasonCode,
  bl as CardTransitionState,
  Kl as CardTransitionType,
  Ol as CashAdvanceChargeOffSerializerChargeOffType,
  hl as ChargeOffInputSerializerChargeOffType,
  fl as CountryCode,
  ql as CrmDeliveryAttemptState,
  gl as CrmEventType,
  ml as CrmVendor,
  ll as Currency,
  pl as DataRequirement,
  _l as DatasetType,
  dl as DatasetUploadStatus,
  yl as DatashareMethod,
  cl as DeniedReason,
  il as Direction,
  ul as DocumentUploadPurpose,
  ol as DoordashAdminUserMxRole,
  al as EmailSubscriptionState,
  sl as EntityType,
  rl as FieldType,
  nl as FinancialRecordDocType,
  el as FutureActivityType,
  tl as IdentityDataShare,
  Zp as IncorporationJurisdiction,
  Jp as IncorporationType,
  Xp as InfoNeededStatus,
  jp as IngestionStatus,
  Yp as InnerTransferType,
  Hp as KycProvider,
  Vp as KycReviewReason,
  $p as LienStatus,
  zp as LienType,
  Wp as LoanBusinessTableRowStatus,
  Lp as ManualReviewReason,
  Qp as McaBusinessTableRowStatus,
  wp as MerchantBankAccountType,
  Tp as MerchantBankApplicationState,
  Ip as MerchantBankCardDisputeReason,
  Gp as MerchantBankCardDisputeState,
  Sp as MerchantBankCardPaymentPreference,
  Ap as MerchantBankCardReplacementReason,
  Dp as MerchantBankCardType,
  Np as MerchantBankExternalAccountState,
  Up as MerchantBankExternalBankAccountType,
  Cp as MerchantBankOfferProductType,
  vp as MerchantBankReceivedTransferType,
  Ep as MerchantBankRecipientPaymentType,
  Fp as MerchantBankRepaymentType,
  Bp as MerchantBankTransactionDestinationAccountType,
  Rp as MerchantBankTransactionPaymentDirection,
  xp as MerchantBankTransactionState,
  Mp as MerchantBankTransactionType,
  kp as MerchantBankTransferPaymentType,
  Pp as MicroDepositVerificationState,
  bp as MinimumRepaymentAmountDueReason,
  Kp as NotificationType,
  Op as OnboardingAction,
  hp as OnboardingProductType,
  fp as OnboardingState,
  qp as OneOffActionType,
  gp as OptInState,
  mp as ParafinProduct,
  lp as PartnerEventType,
  pp as PersonaInquiryType,
  _p as PhoneNumberCountryCode,
  dp as PhoneNumberType,
  yp as PlaidInvestigationDeniedReason,
  cp as PlaidLinkageWithInvestigationSerializerState,
  ip as RepaymentDetailsRepaymentPlanType,
  up as RepaymentDisbursementType,
  op as RepaymentPauseSerializerPauseType,
  ap as RepaymentSchedule,
  sp as ReplacementDetailsReplacementReason,
  rp as RestrictionExternalReason,
  np as RestrictionReason,
  ep as RestrictionSource,
  tp as RestrictionType,
  Z_ as ReturnCode,
  J_ as ReversalReason,
  X_ as RutterConnectionState,
  j_ as RutterIntegrationType,
  Y_ as RutterPlatform,
  H_ as S3PartnerDashboardCsvStatus,
  V_ as S3PartnerDashboardCsvTableType,
  $_ as SalesDataShare,
  z_ as SardineCustomerScoreState,
  W_ as SardineFlowName,
  L_ as SardineLevel,
  Q_ as SessionType,
  w_ as State,
  T_ as StripeSaleRecordIngestionType,
  I_ as TermsOfServiceLinkType,
  G_ as UnderwritingDesiredOutputState,
  S_ as UserRole,
  A_ as ValidationStatus,
  D_ as VerificationStatus,
  N_ as WebNotificationState,
  U_ as WebSubscriptionState,
  C_ as WebhookDeliveryAttemptState,
  v_ as WebhookEndpointStatus,
  E_ as WebhookEventDeliveryState,
  F_ as WebhookEventMode,
  B_ as WebhookEventType,
  Fy as attemptSync,
  Wc as batchCreatePdPartnerEventMarkAllNotificationsRead,
  gy as batchResendWebhookAttempt,
  Pr as benchmarkGetCapitalProductOwnership,
  ic as countPdBusinessTableRows,
  Oc as countPdCapitalProductApplicationRow,
  xc as countPdCapitalProductOfferRow,
  lc as countPdCapitalProductRow,
  us as createBankAccountInfo,
  Nr as createCapitalProductIntents,
  Lr as createCapitalProductSuperApplicationActions,
  wr as createCapitalProductSuperApplications,
  Yn as createCapitalProducts,
  Fr as createOfferedCapitalProductFeeDiscount,
  it as createOrganization,
  rt as createPartnerApiKey,
  $c as createPdPartnerEventMarkNotificationRead,
  Iy as createPdWebhookEndpoint,
  hs as createPlaidInvestigation,
  Fi as createS3OfferCsv,
  Xy as createS3PartnerDashboardTableCsv,
  he as deleteBeneficialOwnersId,
  He as deleteBusinessOfficersId,
  N as deleteCashAdvancePausesP1,
  oe as deleteMarketingIntegrationsVendor,
  Su as deleteMerchantBankExternalAccountsId,
  No as deleteMerchantBankRecipientsId,
  Ft as deletePartnerDeleteUsers,
  Wt as deletePartnerSyncWebhooksId,
  wy as deletePdWebhookEndpoint,
  Ot as deleteSlackChannelsId,
  ii as getAccountingInfo,
  xd as getAmazonFundingAccountLock,
  Oi as getAnalyticsCubeToken,
  Ey as getAttemptSyncMutationOptions,
  ss as getBankAccountInfos,
  Be as getBanks,
  zc as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  qy as getBatchResendWebhookAttemptMutationOptions,
  kr as getBenchmarkGetCapitalProductOwnershipQueryKey,
  Mr as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  le as getBeneficialOwners,
  g as getBusinessAnnotations,
  d as getBusinessCores,
  Fs as getBusinessIdentities,
  Le as getBusinessOfficers,
  Te as getBusinessTableRowsCard,
  Se as getBusinessTableRowsLoan,
  Ne as getBusinessTableRowsMca,
  ve as getBusinessTableRowsProductAgnostic,
  Aa as getBusinessesDailySalesRecords,
  vi as getBusinessesNotifications,
  ia as getBusinessesProducts,
  sr as getCapitalProductActivities,
  _r as getCapitalProductApplicationSnapshots,
  ur as getCapitalProductApplications,
  vr as getCapitalProductIntents,
  qr as getCapitalProductNetTopUpItemization,
  Zn as getCapitalProductOfferCollection,
  Ln as getCapitalProductProspectiveTermsOfService,
  xr as getCapitalProductRepaymentSchedule,
  Or as getCapitalProductStateDisclosure,
  $n as getCapitalProducts,
  ra as getCards,
  K as getCashAdvanceChargeOffs,
  F as getCashAdvancePauses,
  cc as getCountPdBusinessTableRowsQueryKey,
  yc as getCountPdBusinessTableRowsQueryOptions,
  Kc as getCountPdCapitalProductApplicationRowQueryKey,
  bc as getCountPdCapitalProductApplicationRowQueryOptions,
  Rc as getCountPdCapitalProductOfferRowQueryKey,
  Bc as getCountPdCapitalProductOfferRowQueryOptions,
  mc as getCountPdCapitalProductRowQueryKey,
  gc as getCountPdCapitalProductRowQueryOptions,
  Gr as getCounteroffer,
  is as getCreateBankAccountInfoMutationOptions,
  Dr as getCreateCapitalProductIntentsMutationOptions,
  Wr as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  Qr as getCreateCapitalProductSuperApplicationsMutationOptions,
  jn as getCreateCapitalProductsMutationOptions,
  Er as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  ct as getCreateOrganizationMutationOptions,
  st as getCreatePartnerApiKeyMutationOptions,
  Vc as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  Ty as getCreatePdWebhookEndpointMutationOptions,
  Os as getCreatePlaidInvestigationMutationOptions,
  Ei as getCreateS3OfferCsvMutationOptions,
  Jy as getCreateS3PartnerDashboardTableCsvMutationOptions,
  Pn as getDataingestionS3DatasetIngestionConfigs,
  pn as getDataingestionS3DatasetIntegrations,
  yn as getDataingestionS3DatasetUploadValidationResults,
  on as getDataingestionS3DatasetUploads,
  fn as getDataingestionS3OrgDatasetIntegrations,
  Un as getDataingestionStripeIngestions,
  Ju as getDebtChecks,
  zu as getDebts,
  Oe as getDeleteBeneficialOwnersIdMutationOptions,
  Ye as getDeleteBusinessOfficersIdMutationOptions,
  D as getDeleteCashAdvancePausesP1MutationOptions,
  ue as getDeleteMarketingIntegrationsVendorMutationOptions,
  Gu as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  Do as getDeleteMerchantBankRecipientsIdMutationOptions,
  Et as getDeletePartnerDeleteUsersMutationOptions,
  zt as getDeletePartnerSyncWebhooksIdMutationOptions,
  Qy as getDeletePdWebhookEndpointMutationOptions,
  Kt as getDeleteSlackChannelsIdMutationOptions,
  pa as getDocumentUploadGroup,
  fa as getDocumentUploadList,
  ri as getFinancialReviews,
  zr as getFlexLoanMinimumRepaymentSchedule,
  ds as getFundingAccounts,
  w as getFutureActivities,
  ci as getGetAccountingInfoQueryKey,
  yi as getGetAccountingInfoQueryOptions,
  Rd as getGetAmazonFundingAccountLockQueryKey,
  Bd as getGetAmazonFundingAccountLockQueryOptions,
  Ki as getGetAnalyticsCubeTokenQueryKey,
  bi as getGetAnalyticsCubeTokenQueryOptions,
  as as getGetBankAccountInfosQueryKey,
  os as getGetBankAccountInfosQueryOptions,
  Fe as getGetBanksQueryKey,
  Ee as getGetBanksQueryOptions,
  me as getGetBeneficialOwnersQueryKey,
  ge as getGetBeneficialOwnersQueryOptions,
  q as getGetBusinessAnnotationsQueryKey,
  f as getGetBusinessAnnotationsQueryOptions,
  _ as getGetBusinessCoresQueryKey,
  p as getGetBusinessCoresQueryOptions,
  Es as getGetBusinessIdentitiesQueryKey,
  vs as getGetBusinessIdentitiesQueryOptions,
  We as getGetBusinessOfficersQueryKey,
  ze as getGetBusinessOfficersQueryOptions,
  we as getGetBusinessTableRowsCardQueryKey,
  Qe as getGetBusinessTableRowsCardQueryOptions,
  Ge as getGetBusinessTableRowsLoanQueryKey,
  Ie as getGetBusinessTableRowsLoanQueryOptions,
  De as getGetBusinessTableRowsMcaQueryKey,
  Ae as getGetBusinessTableRowsMcaQueryOptions,
  Ce as getGetBusinessTableRowsProductAgnosticQueryKey,
  Ue as getGetBusinessTableRowsProductAgnosticQueryOptions,
  Sa as getGetBusinessesDailySalesRecordsQueryKey,
  Ga as getGetBusinessesDailySalesRecordsQueryOptions,
  Ci as getGetBusinessesNotificationsQueryKey,
  Ui as getGetBusinessesNotificationsQueryOptions,
  ca as getGetBusinessesProductsQueryKey,
  ya as getGetBusinessesProductsQueryOptions,
  ar as getGetCapitalProductActivitiesQueryKey,
  or as getGetCapitalProductActivitiesQueryOptions,
  pr as getGetCapitalProductApplicationSnapshotsQueryKey,
  lr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  ir as getGetCapitalProductApplicationsQueryKey,
  cr as getGetCapitalProductApplicationsQueryOptions,
  Cr as getGetCapitalProductIntentsQueryKey,
  Ur as getGetCapitalProductIntentsQueryOptions,
  fr as getGetCapitalProductNetTopUpItemizationQueryKey,
  hr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  tr as getGetCapitalProductOfferCollectionQueryKey,
  er as getGetCapitalProductOfferCollectionQueryOptions,
  Wn as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  zn as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Rr as getGetCapitalProductRepaymentScheduleQueryKey,
  Br as getGetCapitalProductRepaymentScheduleQueryOptions,
  Kr as getGetCapitalProductStateDisclosureQueryKey,
  br as getGetCapitalProductStateDisclosureQueryOptions,
  Vn as getGetCapitalProductsQueryKey,
  Hn as getGetCapitalProductsQueryOptions,
  sa as getGetCardsQueryKey,
  aa as getGetCardsQueryOptions,
  b as getGetCashAdvanceChargeOffsQueryKey,
  P as getGetCashAdvanceChargeOffsQueryOptions,
  E as getGetCashAdvancePausesQueryKey,
  v as getGetCashAdvancePausesQueryOptions,
  Ir as getGetCounterofferQueryKey,
  Tr as getGetCounterofferQueryOptions,
  kn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  Mn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  ln as getGetDataingestionS3DatasetIntegrationsQueryKey,
  mn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  dn as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  _n as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  un as getGetDataingestionS3DatasetUploadsQueryKey,
  cn as getGetDataingestionS3DatasetUploadsQueryOptions,
  hn as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  On as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  Nn as getGetDataingestionStripeIngestionsQueryKey,
  Dn as getGetDataingestionStripeIngestionsQueryOptions,
  Zu as getGetDebtChecksQueryKey,
  ti as getGetDebtChecksQueryOptions,
  $u as getGetDebtsQueryKey,
  Vu as getGetDebtsQueryOptions,
  la as getGetDocumentUploadGroupQueryKey,
  ma as getGetDocumentUploadGroupQueryOptions,
  ha as getGetDocumentUploadListQueryKey,
  Oa as getGetDocumentUploadListQueryOptions,
  si as getGetFinancialReviewsQueryKey,
  ai as getGetFinancialReviewsQueryOptions,
  $r as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  Vr as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  _s as getGetFundingAccountsQueryKey,
  ps as getGetFundingAccountsQueryOptions,
  Q as getGetFutureActivitiesQueryKey,
  L as getGetFutureActivitiesQueryOptions,
  Ea as getGetLiensQueryKey,
  va as getGetLiensQueryOptions,
  ce as getGetMarketingGtmToolkitQueryKey,
  ye as getGetMarketingGtmToolkitQueryOptions,
  ne as getGetMarketingIntegrationsQueryKey,
  re as getGetMarketingIntegrationsQueryOptions,
  _e as getGetMarketingParagonTokenQueryKey,
  pe as getGetMarketingParagonTokenQueryOptions,
  eo as getGetMerchantBankAccountsIdBalancesQueryKey,
  no as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Ja as getGetMerchantBankAccountsIdQueryKey,
  Za as getGetMerchantBankAccountsIdQueryOptions,
  so as getGetMerchantBankAccountsQueryKey,
  ao as getGetMerchantBankAccountsQueryOptions,
  qd as getGetMerchantBankApplicationsIdQueryKey,
  fd as getGetMerchantBankApplicationsIdQueryOptions,
  Od as getGetMerchantBankApplicationsQueryKey,
  Kd as getGetMerchantBankApplicationsQueryOptions,
  ru as getGetMerchantBankCardAccessTokenQueryKey,
  su as getGetMerchantBankCardAccessTokenQueryOptions,
  iu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  cu as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Jo as getGetMerchantBankCardCardholdersQueryKey,
  Zo as getGetMerchantBankCardCardholdersQueryOptions,
  mu as getGetMerchantBankCardDisputesQueryKey,
  gu as getGetMerchantBankCardDisputesQueryOptions,
  zo as getGetMerchantBankCardsQueryKey,
  $o as getGetMerchantBankCardsQueryOptions,
  Ru as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  Bu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  Du as getGetMerchantBankExternalAccountsIdQueryKey,
  Au as getGetMerchantBankExternalAccountsIdQueryOptions,
  ku as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  Mu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  Eu as getGetMerchantBankExternalAccountsQueryKey,
  vu as getGetMerchantBankExternalAccountsQueryOptions,
  ko as getGetMerchantBankInterestDetailsQueryKey,
  Mo as getGetMerchantBankInterestDetailsQueryOptions,
  q_ as getGetMerchantBankOffersQueryKey,
  f_ as getGetMerchantBankOffersQueryOptions,
  Co as getGetMerchantBankRecipientsIdQueryKey,
  Uo as getGetMerchantBankRecipientsIdQueryOptions,
  Ro as getGetMerchantBankRecipientsQueryKey,
  Bo as getGetMerchantBankRecipientsQueryOptions,
  _o as getGetMerchantBankTransactionsIdQueryKey,
  po as getGetMerchantBankTransactionsIdQueryOptions,
  mo as getGetMerchantBankTransactionsQueryKey,
  go as getGetMerchantBankTransactionsQueryOptions,
  fo as getGetMerchantBankTransferFeesQueryKey,
  ho as getGetMerchantBankTransferFeesQueryOptions,
  Ko as getGetMerchantBankTransferLimitsQueryKey,
  bo as getGetMerchantBankTransferLimitsQueryOptions,
  Ed as getGetNaicsQueryKey,
  vd as getGetNaicsQueryOptions,
  gi as getGetOfficerSubsidiaryRecordsQueryKey,
  qi as getGetOfficerSubsidiaryRecordsQueryOptions,
  wn as getGetOnboardingBusinessRequirementsQueryKey,
  Qn as getGetOnboardingBusinessRequirementsQueryOptions,
  Rs as getGetOnboardingInfoNeededQueryKey,
  Bs as getGetOnboardingInfoNeededQueryOptions,
  ks as getGetOnboardingStatesQueryKey,
  Ms as getGetOnboardingStatesQueryOptions,
  pt as getGetOrganizationQueryKey,
  lt as getGetOrganizationQueryOptions,
  ke as getGetOwnerIsBeneficialOwnerQueryKey,
  Me as getGetOwnerIsBeneficialOwnerQueryOptions,
  Ds as getGetOwnersQueryKey,
  As as getGetOwnersQueryOptions,
  Sd as getGetPADAgreementQueryKey,
  Gd as getGetPADAgreementQueryOptions,
  La as getGetParafinderBusinessTypesQueryKey,
  Wa as getGetParafinderBusinessTypesQueryOptions,
  Ya as getGetParafinderCapitalInfoQueryKey,
  ja as getGetParafinderCapitalInfoQueryOptions,
  Ta as getGetParafinderQueryKey,
  wa as getGetParafinderQueryOptions,
  $a as getGetParafinderSummaryQueryKey,
  Va as getGetParafinderSummaryQueryOptions,
  et as getGetPartnerApiKeyQueryKey,
  nt as getGetPartnerApiKeyQueryOptions,
  Dt as getGetPartnerConfigsQueryKey,
  At as getGetPartnerConfigsQueryOptions,
  Ct as getGetPartnerPublicConfigsQueryKey,
  Ut as getGetPartnerPublicConfigsQueryOptions,
  Tt as getGetPartnerSyncWebhooksQueryKey,
  wt as getGetPartnerSyncWebhooksQueryOptions,
  Pt as getGetPartnerUsersQueryKey,
  kt as getGetPartnerUsersQueryOptions,
  _d as getGetPartnerWidgetConfigsQueryKey,
  pd as getGetPartnerWidgetConfigsQueryOptions,
  R as getGetPartnersDoordashAdminUsersQueryKey,
  B as getGetPartnersDoordashAdminUsersQueryOptions,
  z as getGetPartnersQueryKey,
  $ as getGetPartnersQueryOptions,
  j as getGetPartnersUnderwritingQueryKey,
  X as getGetPartnersUnderwritingQueryOptions,
  Ac as getGetPdOfferUrlQueryKey,
  Sc as getGetPdOfferUrlQueryOptions,
  ty as getGetPdUtilityInfoQueryKey,
  ey as getGetPdUtilityInfoQueryOptions,
  qs as getGetPlaidInvestigationsQueryKey,
  fs as getGetPlaidInvestigationsQueryOptions,
  I as getGetRepaymentPlanParametersQueryKey,
  T as getGetRepaymentPlanParametersQueryOptions,
  ba as getGetRestrictionQueryKey,
  Pa as getGetRestrictionQueryOptions,
  Ra as getGetRestrictionsDetailsQueryKey,
  Ba as getGetRestrictionsDetailsQueryOptions,
  Xr as getGetRunOfacScreeningResultsQueryKey,
  Jr as getGetRunOfacScreeningResultsQueryOptions,
  td as getGetRutterConnectionsQueryKey,
  ed as getGetRutterConnectionsQueryOptions,
  $y as getGetS3PartnerDashboardTableCsvQueryKey,
  Vy as getGetS3PartnerDashboardTableCsvQueryOptions,
  ud as getGetSardineSessionIdQueryKey,
  id as getGetSardineSessionIdQueryOptions,
  ft as getGetSlackChannelsQueryKey,
  ht as getGetSlackChannelsQueryOptions,
  Xt as getGetStatementsV2QueryKey,
  Jt as getGetStatementsV2QueryOptions,
  Dy as getGetSyncAndMaybeMarkFailedMutationOptions,
  Cy as getGetSyncQueryKey,
  Uy as getGetSyncQueryOptions,
  Yd as getGetTemplateMockQueryKey,
  jd as getGetTemplateMockQueryOptions,
  e_ as getGetTemplateSnapshotQueryKey,
  n_ as getGetTemplateSnapshotQueryOptions,
  Jd as getGetTemplateSnapshotsQueryKey,
  Zd as getGetTemplateSnapshotsQueryOptions,
  s_ as getGetTemplateTypesQueryKey,
  a_ as getGetTemplateTypesQueryOptions,
  Qd as getGetTemplatesQueryKey,
  Ld as getGetTemplatesQueryOptions,
  ns as getGetTermLoanProspectiveRepaymentsQueryKey,
  rs as getGetTermLoanProspectiveRepaymentsQueryOptions,
  Fa as getLiens,
  dt as getLinkPartnerMutationOptions,
  ki as getListApiRequestsQueryKey,
  Mi as getListApiRequestsQueryOptions,
  en as getListDatasetUploadsQueryKey,
  nn as getListDatasetUploadsQueryOptions,
  sn as getListManualDatasetUploadValidationResultsQueryKey,
  an as getListManualDatasetUploadValidationResultsQueryOptions,
  ot as getListOrganizationsQueryKey,
  ut as getListOrganizationsQueryOptions,
  Ii as getListPdBusinessDetailsApplicationsQueryKey,
  Ti as getListPdBusinessDetailsApplicationsQueryOptions,
  Qi as getListPdBusinessDetailsBankInfoQueryKey,
  Li as getListPdBusinessDetailsBankInfoQueryOptions,
  zi as getListPdBusinessDetailsBusinessInfoQueryKey,
  $i as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Xi as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Ji as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  tc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  ec as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Hi as getListPdBusinessDetailsFundedProductsQueryKey,
  Yi as getListPdBusinessDetailsFundedProductsQueryOptions,
  rc as getListPdBusinessDetailsOffersQueryKey,
  sc as getListPdBusinessDetailsOffersQueryOptions,
  oc as getListPdBusinessDetailsOwnerInfoQueryKey,
  uc as getListPdBusinessDetailsOwnerInfoQueryOptions,
  _c as getListPdBusinessTableRowsQueryKey,
  pc as getListPdBusinessTableRowsQueryOptions,
  kc as getListPdCapitalProductApplicationRowsQueryKey,
  Mc as getListPdCapitalProductApplicationRowsQueryOptions,
  Ec as getListPdCapitalProductOfferRowsQueryKey,
  vc as getListPdCapitalProductOfferRowsQueryOptions,
  fc as getListPdCapitalProductRowsQueryKey,
  hc as getListPdCapitalProductRowsQueryOptions,
  by as getListPdCrmDeliveryAttemptsQueryKey,
  Py as getListPdCrmDeliveryAttemptsQueryOptions,
  Ry as getListPdCrmEventInfoQueryKey,
  By as getListPdCrmEventInfoQueryOptions,
  hy as getListPdCrmEventsQueryKey,
  Oy as getListPdCrmEventsQueryOptions,
  Xc as getListPdCrmIntegrationsQueryKey,
  Jc as getListPdCrmIntegrationsQueryOptions,
  Ic as getListPdPartnerEventListUserSubscriptionsQueryKey,
  Tc as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  Qc as getListPdPartnerEventListWebNotificationsQueryKey,
  Lc as getListPdPartnerEventListWebNotificationsQueryOptions,
  Uc as getListPdSupportInfoQueryKey,
  Nc as getListPdSupportInfoQueryOptions,
  Sy as getListPdWebhookEndpointsQueryKey,
  Gy as getListPdWebhookEndpointsQueryOptions,
  cy as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  yy as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  oy as getListPdWebhookLogsEventInfoQueryKey,
  uy as getListPdWebhookLogsEventInfoQueryOptions,
  ry as getListPdWebhookLogsEventsQueryKey,
  sy as getListPdWebhookLogsEventsQueryOptions,
  Ri as getListS3OfferCsvsQueryKey,
  Bi as getListS3OfferCsvsQueryOptions,
  Yy as getListS3PartnerDashboardTableCsvsQueryKey,
  jy as getListS3PartnerDashboardTableCsvsQueryOptions,
  b_ as getListSardineDetailsQueryKey,
  P_ as getListSardineDetailsQueryOptions,
  vn as getListVersionedSchemasQueryKey,
  Cn as getListVersionedSchemasQueryOptions,
  Td as getMarkPADAgreementSignedMutationOptions,
  ie as getMarketingGtmToolkit,
  ee as getMarketingIntegrations,
  de as getMarketingParagonToken,
  ro as getMerchantBankAccounts,
  Xa as getMerchantBankAccountsId,
  to as getMerchantBankAccountsIdBalances,
  hd as getMerchantBankApplications,
  gd as getMerchantBankApplicationsId,
  nu as getMerchantBankCardAccessToken,
  uu as getMerchantBankCardCardholderAccessToken,
  Xo as getMerchantBankCardCardholders,
  lu as getMerchantBankCardDisputes,
  Wo as getMerchantBankCards,
  Fu as getMerchantBankExternalAccounts,
  Nu as getMerchantBankExternalAccountsId,
  xu as getMerchantBankExternalAccountsIdPlaidLinkToken,
  Pu as getMerchantBankExternalAccountsPlaidLinkToken,
  Po as getMerchantBankInterestDetails,
  g_ as getMerchantBankOffers,
  xo as getMerchantBankRecipients,
  vo as getMerchantBankRecipientsId,
  lo as getMerchantBankTransactions,
  yo as getMerchantBankTransactionsId,
  qo as getMerchantBankTransferFees,
  Oo as getMerchantBankTransferLimits,
  _y as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  py as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  Fd as getNaics,
  mi as getOfficerSubsidiaryRecords,
  Tn as getOnboardingBusinessRequirements,
  xs as getOnboardingInfoNeeded,
  Ps as getOnboardingStates,
  _t as getOrganization,
  Pe as getOwnerIsBeneficialOwner,
  Ns as getOwners,
  Ad as getPADAgreement,
  Ia as getParafinder,
  Qa as getParafinderBusinessTypes,
  Ha as getParafinderCapitalInfo,
  za as getParafinderSummary,
  tt as getPartnerApiKey,
  Nt as getPartnerConfigs,
  vt as getPartnerPublicConfigs,
  It as getPartnerSyncWebhooks,
  bt as getPartnerUsers,
  dd as getPartnerWidgetConfigs,
  W as getPartners,
  x as getPartnersDoordashAdminUsers,
  Y as getPartnersUnderwriting,
  be as getPatchBeneficialOwnersIdMutationOptions,
  Us as getPatchBusinessIdentitiesIdMutationOptions,
  Xe as getPatchBusinessOfficersIdMutationOptions,
  Sr as getPatchCapitalProductIntentsMutationOptions,
  Jn as getPatchCapitalProductsMutationOptions,
  S as getPatchCashAdvancePausesP1MutationOptions,
  ts as getPatchClearWatchlistHitsIdMutationOptions,
  In as getPatchDataingestionStripeIngestionsMutationOptions,
  js as getPatchFlexLoanAgeMutationOptions,
  ta as getPatchFlexLoanFundMutationOptions,
  Js as getPatchFlexLoanOwnershipMutationOptions,
  Ua as getPatchLiensIdMutationOptions,
  jo as getPatchMerchantBankCardCardholdersIdMutationOptions,
  Tu as getPatchMerchantBankExternalAccountsIdMutationOptions,
  Wu as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  M_ as getPatchMerchantBankMerchantConfigMutationOptions,
  Lo as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  So as getPatchMerchantBankRecipientsIdMutationOptions,
  m_ as getPatchMfaExtendSessionMutationOptions,
  Re as getPatchOwnerIsBeneficialOwnerMutationOptions,
  Gs as getPatchOwnersIdMutationOptions,
  Gt as getPatchPartnerConfigsIdMutationOptions,
  Vt as getPatchPartnerSyncWebhooksIdMutationOptions,
  Bt as getPatchPartnerUsersMutationOptions,
  md as getPatchPartnerWidgetConfigsMutationOptions,
  Z as getPatchPartnersIdMutationOptions,
  bs as getPatchPersonaFallbackInquiryMutationOptions,
  Vd as getPatchTemplatesMutationOptions,
  Dc as getPdOfferUrl,
  Zc as getPdUtilityInfo,
  gs as getPlaidInvestigations,
  _i as getPostAccountingInfoMutationOptions,
  Ws as getPostAmazonRedirectUrlMutationOptions,
  Yt as getPostAuthTokensMutationOptions,
  fe as getPostBeneficialOwnersMutationOptions,
  O as getPostBusinessAnnotationsMutationOptions,
  _a as getPostBusinessAuthLinkMutationOptions,
  m as getPostBusinessCoresMutationOptions,
  Ts as getPostBusinessMutationOptions,
  Ve as getPostBusinessOfficersMutationOptions,
  Di as getPostBusinessesNotificationsMutationOptions,
  dr as getPostCapitalProductApplicationMutationOptions,
  Hs as getPostCapitalProductBusinessMutationOptions,
  gr as getPostCapitalProductNetTopUpMutationOptions,
  rr as getPostCapitalProductOfferCollectionMutationOptions,
  ni as getPostCardCloseAccountMutationOptions,
  ua as getPostCardsMutationOptions,
  M as getPostCashAdvanceChargeOffsMutationOptions,
  U as getPostCashAdvancePausesMutationOptions,
  Ze as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  qn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  bn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  Fn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  Sn as getPostDataingestionStripeIngestionsMutationOptions,
  Xu as getPostDebtsCheckCompleteMutationOptions,
  Yu as getPostDebtsMutationOptions,
  qa as getPostDocumentUploadGroupMutationOptions,
  ui as getPostFinancialReviewsMutationOptions,
  d_ as getPostForgiveCapitalFeeMutationOptions,
  ms as getPostFundingAccountsMutationOptions,
  c_ as getPostManualRepaymentMutationOptions,
  ad as getPostMarkAccountingIntegrationSeenMutationOptions,
  ae as getPostMarketingIntegrationsMutationOptions,
  te as getPostMarketingReviewMutationOptions,
  uo as getPostMerchantBankAccountsMutationOptions,
  Pd as getPostMerchantBankApplicationsMutationOptions,
  Qs as getPostMerchantBankBusinessMutationOptions,
  eu as getPostMerchantBankCardActivateMutationOptions,
  ou as getPostMerchantBankCardApplePayMutationOptions,
  bu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  Ou as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  fu as getPostMerchantBankCardDisputesMutationOptions,
  pu as getPostMerchantBankCardGooglePayMutationOptions,
  du as getPostMerchantBankCardStateTransitionMutationOptions,
  Ho as getPostMerchantBankCardsMutationOptions,
  Uu as getPostMerchantBankExternalAccountsMutationOptions,
  Qu as getPostMerchantBankExternalBankTransfersMutationOptions,
  co as getPostMerchantBankInnerTransfersMutationOptions,
  O_ as getPostMerchantBankOffersMutationOptions,
  wo as getPostMerchantBankRecipientTransfersMutationOptions,
  Io as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  Eo as getPostMerchantBankRecipientsMutationOptions,
  Ud as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  Dd as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  p_ as getPostMfaVerifyAccountDetailsMutationOptions,
  hi as getPostOfficerSubsidiaryRecordsMutationOptions,
  Da as getPostOptInMutationOptions,
  Lt as getPostPartnerSyncWebhooksMutationOptions,
  xt as getPostPartnerUsersMutationOptions,
  H as getPostPartnersMutationOptions,
  Ma as getPostRestrictionMutationOptions,
  rd as getPostRutterConnectionsMutationOptions,
  yd as getPostSardineSessionIdMutationOptions,
  na as getPostSetupAmazonTopUpMutationOptions,
  gt as getPostSlackAuthorizeMutationOptions,
  $s as getPostSpdMutationOptions,
  li as getPostTaxRecordsMutationOptions,
  u_ as getPostTemplateTypesMutationOptions,
  zd as getPostTemplatesMutationOptions,
  Si as getPostTermsOfServiceMarkShownMutationOptions,
  Yr as getPostTriggerRunCelticsChecklistMutationOptions,
  Md as getPostWebhooksPlaidGeneralMutationOptions,
  Rn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  G as getRepaymentPlanParameters,
  My as getResendCrmAttemptMutationOptions,
  my as getResendWebhookAttemptMutationOptions,
  Ka as getRestriction,
  xa as getRestrictionsDetails,
  jr as getRunOfacScreeningResults,
  Zy as getRutterConnections,
  zy as getS3PartnerDashboardTableCsv,
  od as getSardineSessionId,
  qt as getSlackChannels,
  dO as getStatement,
  jt as getStatementsV2,
  vy as getSync,
  Ny as getSyncAndMaybeMarkFailed,
  Hd as getTemplateMock,
  t_ as getTemplateSnapshot,
  Xd as getTemplateSnapshots,
  r_ as getTemplateTypes,
  wd as getTemplates,
  es as getTermLoanProspectiveRepayments,
  ys as getUpdateBankAccountInfoMutationOptions,
  Yc as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  Wy as getUpdatePdWebhookEndpointMutationOptions,
  yt as linkPartner,
  Pi as listApiRequests,
  tn as listDatasetUploads,
  rn as listManualDatasetUploadValidationResults,
  at as listOrganizations,
  Gi as listPdBusinessDetailsApplications,
  wi as listPdBusinessDetailsBankInfo,
  Wi as listPdBusinessDetailsBusinessInfo,
  ji as listPdBusinessDetailsFundedProductDetails,
  Zi as listPdBusinessDetailsFundedProductPaymentProgress,
  Vi as listPdBusinessDetailsFundedProducts,
  nc as listPdBusinessDetailsOffers,
  ac as listPdBusinessDetailsOwnerInfo,
  dc as listPdBusinessTableRows,
  Pc as listPdCapitalProductApplicationRows,
  Fc as listPdCapitalProductOfferRows,
  qc as listPdCapitalProductRows,
  Ky as listPdCrmDeliveryAttempts,
  xy as listPdCrmEventInfo,
  fy as listPdCrmEvents,
  jc as listPdCrmIntegrations,
  Gc as listPdPartnerEventListUserSubscriptions,
  wc as listPdPartnerEventListWebNotifications,
  Cc as listPdSupportInfo,
  Ay as listPdWebhookEndpoints,
  iy as listPdWebhookLogsDeliveryAttempts,
  ay as listPdWebhookLogsEventInfo,
  ny as listPdWebhookLogsEvents,
  xi as listS3OfferCsvs,
  Hy as listS3PartnerDashboardTableCsvs,
  K_ as listSardineDetails,
  En as listVersionedSchemas,
  Id as markPADAgreementSigned,
  dy as multiWebhookPdWebhookLogsDeliveryAttempts,
  Ke as patchBeneficialOwnersId,
  Cs as patchBusinessIdentitiesId,
  je as patchBusinessOfficersId,
  Ar as patchCapitalProductIntents,
  Xn as patchCapitalProducts,
  A as patchCashAdvancePausesP1,
  Zr as patchClearWatchlistHitsId,
  Gn as patchDataingestionStripeIngestions,
  Ys as patchFlexLoanAge,
  Zs as patchFlexLoanFund,
  Xs as patchFlexLoanOwnership,
  Ca as patchLiensId,
  Yo as patchMerchantBankCardCardholdersId,
  Iu as patchMerchantBankExternalAccountsId,
  Lu as patchMerchantBankExternalBankTransfersId,
  k_ as patchMerchantBankMerchantConfig,
  Qo as patchMerchantBankRecipientTransfersId,
  Ao as patchMerchantBankRecipientsId,
  l_ as patchMfaExtendSession,
  xe as patchOwnerIsBeneficialOwner,
  Ss as patchOwnersId,
  St as patchPartnerConfigsId,
  $t as patchPartnerSyncWebhooksId,
  Rt as patchPartnerUsers,
  ld as patchPartnerWidgetConfigs,
  J as patchPartnersId,
  Ks as patchPersonaFallbackInquiry,
  $d as patchTemplates,
  di as postAccountingInfo,
  Ls as postAmazonRedirectUrl,
  Ht as postAuthTokens,
  qe as postBeneficialOwners,
  Is as postBusiness,
  h as postBusinessAnnotations,
  da as postBusinessAuthLink,
  l as postBusinessCores,
  $e as postBusinessOfficers,
  Ni as postBusinessesNotifications,
  yr as postCapitalProductApplication,
  Vs as postCapitalProductBusiness,
  mr as postCapitalProductNetTopUp,
  nr as postCapitalProductOfferCollection,
  ei as postCardCloseAccount,
  oa as postCards,
  k as postCashAdvanceChargeOffs,
  C as postCashAdvancePauses,
  Je as postDataingestionOneschemaGenerateJwt,
  gn as postDataingestionS3DatasetIntegrations,
  Kn as postDataingestionS3OrgDatasetIntegrations,
  Bn as postDataingestionS3RunDatasetIngestion,
  An as postDataingestionStripeIngestions,
  Hu as postDebts,
  ju as postDebtsCheckComplete,
  ga as postDocumentUploadGroup,
  oi as postFinancialReviews,
  y_ as postForgiveCapitalFee,
  ls as postFundingAccounts,
  i_ as postManualRepayment,
  sd as postMarkAccountingIntegrationSeen,
  se as postMarketingIntegrations,
  Zt as postMarketingReview,
  oo as postMerchantBankAccounts,
  bd as postMerchantBankApplications,
  ws as postMerchantBankBusiness,
  tu as postMerchantBankCardActivate,
  au as postMerchantBankCardApplePay,
  Ku as postMerchantBankCardDisputeChargebackCredit,
  hu as postMerchantBankCardDisputeStateTransition,
  qu as postMerchantBankCardDisputes,
  _u as postMerchantBankCardGooglePay,
  yu as postMerchantBankCardStateTransition,
  Vo as postMerchantBankCards,
  Cu as postMerchantBankExternalAccounts,
  wu as postMerchantBankExternalBankTransfers,
  io as postMerchantBankInnerTransfers,
  h_ as postMerchantBankOffers,
  To as postMerchantBankRecipientTransfers,
  Fo as postMerchantBankRecipients,
  Go as postMerchantBankRecipientsIdPaymentRails,
  Cd as postMfaOtpSendPersonidPhonenumbertype,
  Nd as postMfaOtpVerifyPersonidPhonenumbertype,
  __ as postMfaVerifyAccountDetails,
  fi as postOfficerSubsidiaryRecords,
  Na as postOptIn,
  Qt as postPartnerSyncWebhooks,
  Mt as postPartnerUsers,
  V as postPartners,
  ka as postRestriction,
  nd as postRutterConnections,
  cd as postSardineSessionId,
  ea as postSetupAmazonTopUp,
  mt as postSlackAuthorize,
  zs as postSpd,
  pi as postTaxRecords,
  o_ as postTemplateTypes,
  Wd as postTemplates,
  Ai as postTermsOfServiceMarkShown,
  Hr as postTriggerRunCelticsChecklist,
  kd as postWebhooksPlaidGeneral,
  zO as publicApiPaths,
  xn as putDataingestionS3DatasetIngestionConfigs,
  ky as resendCrmAttempt,
  ly as resendWebhookAttempt,
  cs as updateBankAccountInfo,
  Hc as updatePdPartnerEventUpdateUserSubscription,
  Ly as updatePdWebhookEndpoint,
  NO as useActivateCard,
  EO as useAmazonRPCPreLogin,
  DO as useAppleWallet,
  TO as useAttemptAmazonBankVerification,
  Oh as useAttemptSync,
  ah as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  mh as useBatchResendWebhookAttempt,
  vg as useBenchmarkGetCapitalProductOwnership,
  Vf as useCountPdBusinessTableRows,
  Xf as useCountPdCapitalProductApplicationRow,
  Zf as useCountPdCapitalProductOfferRow,
  Yf as useCountPdCapitalProductRow,
  $g as useCreateBankAccountInfo,
  bO as useCreateBeneficialOwner,
  Dg as useCreateCapitalProductIntents,
  Ig as useCreateCapitalProductSuperApplicationActions,
  Gg as useCreateCapitalProductSuperApplications,
  Og as useCreateCapitalProducts,
  Ug as useCreateOfferedCapitalProductFeeDiscount,
  mm as useCreateOrganization,
  pm as useCreatePartnerApiKey,
  oh as useCreatePdPartnerEventMarkNotificationRead,
  kh as useCreatePdWebhookEndpoint,
  Xg as useCreatePlaidInvestigation,
  wO as useCreatePlaidLinkage,
  Df as useCreateS3OfferCsv,
  Fh as useCreateS3PartnerDashboardTableCsv,
  FO as useCreateUnderwritingAttempt,
  RO as useCreateUnverifiedAmazonFundingAccount,
  xO as useCreateUnverifiedFundingAccount,
  IO as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  QO as useCreateZendeskTicket,
  kO as useDeleteBeneficialOwner,
  Qm as useDeleteBeneficialOwnersId,
  Zm as useDeleteBusinessOfficersId,
  sm as useDeleteCashAdvancePausesP1,
  Sm as useDeleteMarketingIntegrationsVendor,
  gf as useDeleteMerchantBankExternalAccountsId,
  zq as useDeleteMerchantBankRecipientsId,
  km as useDeletePartnerDeleteUsers,
  Em as useDeletePartnerSyncWebhooksId,
  Mh as useDeletePdWebhookEndpoint,
  Om as useDeleteSlackChannelsId,
  UO as useDisputeTransaction,
  GO as useFailMicrodeposit,
  Rf as useGetAccountingInfo,
  wh as useGetAmazonFundingAccountLock,
  Cf as useGetAnalyticsCubeToken,
  zg as useGetBankAccountInfos,
  $m as useGetBanks,
  Tm as useGetBeneficialOwners,
  Xl as useGetBusinessAnnotations,
  Yl as useGetBusinessCores,
  eq as useGetBusinessIdentities,
  Xm as useGetBusinessOfficers,
  jm as useGetBusinessTableRowsCard,
  Ym as useGetBusinessTableRowsLoan,
  Hm as useGetBusinessTableRowsMca,
  Vm as useGetBusinessTableRowsProductAgnostic,
  Rq as useGetBusinessesDailySalesRecords,
  Af as useGetBusinessesNotifications,
  gq as useGetBusinessesProducts,
  kg as useGetCapitalProductActivities,
  Rg as useGetCapitalProductApplicationSnapshots,
  Mg as useGetCapitalProductApplications,
  Ng as useGetCapitalProductIntents,
  Fg as useGetCapitalProductNetTopUpItemization,
  bg as useGetCapitalProductOfferCollection,
  fg as useGetCapitalProductProspectiveTermsOfService,
  Cg as useGetCapitalProductRepaymentSchedule,
  Eg as useGetCapitalProductStateDisclosure,
  hg as useGetCapitalProducts,
  gO as useGetCardAccessToken,
  mO as useGetCardBalance,
  OO as useGetCardLifecycleState,
  lO as useGetCardRepayments,
  _O as useGetCardTransactions,
  yO as useGetCardholder,
  lq as useGetCards,
  Zl as useGetCashAdvanceChargeOffs,
  nm as useGetCashAdvancePauses,
  Sg as useGetCounteroffer,
  yg as useGetDataingestionS3DatasetIngestionConfigs,
  og as useGetDataingestionS3DatasetIntegrations,
  ag as useGetDataingestionS3DatasetUploadValidationResults,
  sg as useGetDataingestionS3DatasetUploads,
  ig as useGetDataingestionS3OrgDatasetIntegrations,
  lg as useGetDataingestionStripeIngestions,
  Pf as useGetDebtChecks,
  Of as useGetDebts,
  fq as useGetDocumentUploadGroup,
  Oq as useGetDocumentUploadList,
  Mf as useGetFinancialReviews,
  Tg as useGetFlexLoanMinimumRepaymentSchedule,
  Hg as useGetFundingAccounts,
  um as useGetFutureActivities,
  fO as useGetIsWhitelistedForEarlyManualPayment,
  kq as useGetLiens,
  Gm as useGetMarketingGtmToolkit,
  Dm as useGetMarketingIntegrations,
  Im as useGetMarketingParagonToken,
  Nq as useGetMerchantBankAccounts,
  Cq as useGetMerchantBankAccountsId,
  Uq as useGetMerchantBankAccountsIdBalances,
  Gh as useGetMerchantBankApplications,
  Sh as useGetMerchantBankApplicationsId,
  ef as useGetMerchantBankCardAccessToken,
  rf as useGetMerchantBankCardCardholderAccessToken,
  Zq as useGetMerchantBankCardCardholders,
  of as useGetMerchantBankCardDisputes,
  jq as useGetMerchantBankCards,
  pf as useGetMerchantBankExternalAccounts,
  mf as useGetMerchantBankExternalAccountsId,
  _f as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  df as useGetMerchantBankExternalAccountsPlaidLinkToken,
  wq as useGetMerchantBankInterestDetails,
  aO as useGetMerchantBankOffers,
  Qq as useGetMerchantBankRecipients,
  Wq as useGetMerchantBankRecipientsId,
  Gq as useGetMerchantBankTransactions,
  Sq as useGetMerchantBankTransactionsId,
  Iq as useGetMerchantBankTransferFees,
  Tq as useGetMerchantBankTransferLimits,
  Qh as useGetNaics,
  Ef as useGetOfficerSubsidiaryRecords,
  qg as useGetOnboardingBusinessRequirements,
  tq as useGetOnboardingInfoNeeded,
  Zg as useGetOnboardingStates,
  KO as useGetOptIns,
  hO as useGetOptOutEligibility,
  qm as useGetOrganization,
  Wm as useGetOwnerIsBeneficialOwner,
  rq as useGetOwners,
  zh as useGetPADAgreement,
  Bq as useGetParafinder,
  Fq as useGetParafinderBusinessTypes,
  vq as useGetParafinderCapitalInfo,
  Eq as useGetParafinderSummary,
  _m as useGetPartnerApiKey,
  xm as useGetPartnerConfigs,
  Mm as useGetPartnerPublicConfigs,
  Bm as useGetPartnerSyncWebhooks,
  Km as useGetPartnerUsers,
  Dh as useGetPartnerWidgetConfigs,
  im as useGetPartners,
  em as useGetPartnersDoordashAdminUsers,
  ym as useGetPartnersUnderwriting,
  nh as useGetPdOfferUrl,
  ch as useGetPdUtilityInfo,
  pO as useGetPendingCardTransactions,
  jg as useGetPlaidInvestigations,
  cO as useGetPlaidLinkToken,
  om as useGetRepaymentPlanParameters,
  Kq as useGetRestriction,
  Pq as useGetRestrictionsDetails,
  Qg as useGetRunOfacScreeningResults,
  Eh as useGetRutterConnections,
  Rh as useGetS3PartnerDashboardTableCsv,
  Uh as useGetSardineSessionId,
  qO as useGetShowTopUpOffer,
  hm as useGetSlackChannels,
  Um as useGetStatementsV2,
  Kh as useGetSync,
  bh as useGetSyncAndMaybeMarkFailed,
  jh as useGetTemplateMock,
  Jh as useGetTemplateSnapshot,
  Xh as useGetTemplateSnapshots,
  Zh as useGetTemplateTypes,
  Vh as useGetTemplates,
  Wg as useGetTermLoanProspectiveRepayments,
  AO as useGooglePay,
  SO as useInitiateMicrodeposit,
  gm as useLinkPartner,
  Uf as useListApiRequests,
  ng as useListDatasetUploads,
  rg as useListManualDatasetUploadValidationResults,
  lm as useListOrganizations,
  If as useListPdBusinessDetailsApplications,
  Tf as useListPdBusinessDetailsBankInfo,
  wf as useListPdBusinessDetailsBusinessInfo,
  Lf as useListPdBusinessDetailsFundedProductDetails,
  Wf as useListPdBusinessDetailsFundedProductPaymentProgress,
  Qf as useListPdBusinessDetailsFundedProducts,
  zf as useListPdBusinessDetailsOffers,
  $f as useListPdBusinessDetailsOwnerInfo,
  Hf as useListPdBusinessTableRows,
  Jf as useListPdCapitalProductApplicationRows,
  th as useListPdCapitalProductOfferRows,
  jf as useListPdCapitalProductRows,
  qh as useListPdCrmDeliveryAttempts,
  hh as useListPdCrmEventInfo,
  gh as useListPdCrmEvents,
  ih as useListPdCrmIntegrations,
  rh as useListPdPartnerEventListUserSubscriptions,
  sh as useListPdPartnerEventListWebNotifications,
  eh as useListPdSupportInfo,
  Ph as useListPdWebhookEndpoints,
  _h as useListPdWebhookLogsDeliveryAttempts,
  dh as useListPdWebhookLogsEventInfo,
  yh as useListPdWebhookLogsEvents,
  Nf as useListS3OfferCsvs,
  Bh as useListS3PartnerDashboardTableCsvs,
  uO as useListSardineDetails,
  pg as useListVersionedSchemas,
  $h as useMarkPADAgreementSigned,
  ph as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  WO as useOptIn,
  LO as useOptOut,
  PO as usePatchBeneficialOwner,
  Lm as usePatchBeneficialOwnersId,
  nq as usePatchBusinessIdentitiesId,
  tg as usePatchBusinessOfficersId,
  Ag as usePatchCapitalProductIntents,
  Kg as usePatchCapitalProducts,
  am as usePatchCashAdvancePausesP1,
  Lg as usePatchClearWatchlistHitsId,
  gg as usePatchDataingestionStripeIngestions,
  yq as usePatchFlexLoanAge,
  _q as usePatchFlexLoanFund,
  dq as usePatchFlexLoanOwnership,
  Mq as usePatchLiensId,
  Jq as usePatchMerchantBankCardCardholdersId,
  qf as usePatchMerchantBankExternalAccountsId,
  hf as usePatchMerchantBankExternalBankTransfersId,
  iO as usePatchMerchantBankMerchantConfig,
  Yq as usePatchMerchantBankRecipientTransfersId,
  $q as usePatchMerchantBankRecipientsId,
  sO as usePatchMfaExtendSession,
  zm as usePatchOwnerIsBeneficialOwner,
  sq as usePatchOwnersId,
  Rm as usePatchPartnerConfigsId,
  vm as usePatchPartnerSyncWebhooksId,
  Pm as usePatchPartnerUsers,
  Ah as usePatchPartnerWidgetConfigs,
  dm as usePatchPartnersId,
  Jg as usePatchPersonaFallbackInquiry,
  Yh as usePatchTemplates,
  Bf as usePostAccountingInfo,
  uq as usePostAmazonRedirectUrl,
  Cm as usePostAuthTokens,
  wm as usePostBeneficialOwners,
  aq as usePostBusiness,
  Jl as usePostBusinessAnnotations,
  qq as usePostBusinessAuthLink,
  jl as usePostBusinessCores,
  Jm as usePostBusinessOfficers,
  Sf as usePostBusinessesNotifications,
  xg as usePostCapitalProductApplication,
  cq as usePostCapitalProductBusiness,
  Bg as usePostCapitalProductNetTopUp,
  Pg as usePostCapitalProductOfferCollection,
  kf as usePostCardCloseAccount,
  mq as usePostCards,
  tm as usePostCashAdvanceChargeOffs,
  rm as usePostCashAdvancePauses,
  eg as usePostDataingestionOneschemaGenerateJwt,
  ug as usePostDataingestionS3DatasetIntegrations,
  cg as usePostDataingestionS3OrgDatasetIntegrations,
  _g as usePostDataingestionS3RunDatasetIngestion,
  mg as usePostDataingestionStripeIngestions,
  Kf as usePostDebts,
  bf as usePostDebtsCheckComplete,
  hq as usePostDocumentUploadGroup,
  xf as usePostFinancialReviews,
  nO as usePostForgiveCapitalFee,
  Yg as usePostFundingAccounts,
  eO as usePostManualRepayment,
  Ch as usePostMarkAccountingIntegrationSeen,
  Am as usePostMarketingIntegrations,
  Nm as usePostMarketingReview,
  Dq as usePostMerchantBankAccounts,
  Ih as usePostMerchantBankApplications,
  oq as usePostMerchantBankBusiness,
  tf as usePostMerchantBankCardActivate,
  nf as usePostMerchantBankCardApplePay,
  yf as usePostMerchantBankCardDisputeChargebackCredit,
  cf as usePostMerchantBankCardDisputeStateTransition,
  uf as usePostMerchantBankCardDisputes,
  af as usePostMerchantBankCardGooglePay,
  sf as usePostMerchantBankCardStateTransition,
  Xq as usePostMerchantBankCards,
  lf as usePostMerchantBankExternalAccounts,
  ff as usePostMerchantBankExternalBankTransfers,
  Aq as usePostMerchantBankInnerTransfers,
  oO as usePostMerchantBankOffers,
  Hq as usePostMerchantBankRecipientTransfers,
  Lq as usePostMerchantBankRecipients,
  Vq as usePostMerchantBankRecipientsIdPaymentRails,
  Lh as usePostMfaOtpSendPersonidPhonenumbertype,
  Wh as usePostMfaOtpVerifyPersonidPhonenumbertype,
  rO as usePostMfaVerifyAccountDetails,
  vf as usePostOfficerSubsidiaryRecords,
  xq as usePostOptIn,
  Fm as usePostPartnerSyncWebhooks,
  bm as usePostPartnerUsers,
  cm as usePostPartners,
  bq as usePostRestriction,
  vh as usePostRutterConnections,
  Nh as usePostSardineSessionId,
  pq as usePostSetupAmazonTopUp,
  fm as usePostSlackAuthorize,
  iq as usePostSpd,
  Ff as usePostTaxRecords,
  tO as usePostTemplateTypes,
  Hh as usePostTemplates,
  Gf as usePostTermsOfServiceMarkShown,
  wg as usePostTriggerRunCelticsChecklist,
  Th as usePostWebhooksPlaidGeneral,
  dg as usePutDataingestionS3DatasetIngestionConfigs,
  fh as useResendCrmAttempt,
  lh as useResendWebhookAttempt,
  MO as useSubmitKYC,
  Vg as useUpdateBankAccountInfo,
  vO as useUpdateBoostCompletion,
  CO as useUpdateCardholder,
  uh as useUpdatePdPartnerEventUpdateUserSubscription,
  xh as useUpdatePdWebhookEndpoint,
  BO as useUploadBankStatements
};
