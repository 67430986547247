import { Organization } from '@parafin/medici-api'
import { Typography } from '../Typography'
import { Icon } from '../Icon'
import styled from 'styled-components'

type OrganizationLogoProps = {
  organization: Organization
  inline?: boolean
  withOrgTag?: boolean
  orgEnabled: boolean
}

export const OrganizationLogoWithName = ({
  organization,
  inline = true,
  withOrgTag = true,
  orgEnabled = false,
}: OrganizationLogoProps) => (
  <IconAlignment>
    <Icon type="organization" />
    {inline ? (
      <>
        <Typography inline color={orgEnabled ? 'black100' : 'black70'}>
          {organization.name}
        </Typography>
        {withOrgTag && (
          <Typography inline color="black70">
            (organization)
          </Typography>
        )}
      </>
    ) : (
      <div>
        <Typography color={'black100'}>{organization.name}</Typography>
        {withOrgTag && <Typography color="black70">(organization)</Typography>}
      </div>
    )}
  </IconAlignment>
)

const IconAlignment = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
